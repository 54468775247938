import React, { useState } from "react";
import { Modal, Button, Upload, message, Form, Select } from "antd";
import LoadingSpinner from "./Loader";
import { InboxOutlined } from "@ant-design/icons";
import { S3Client } from "@aws-sdk/client-s3";
import { createPresignedPost } from "@aws-sdk/s3-presigned-post";
import { useDispatch } from "react-redux";
import {
  addPolicyData,
  fetchPolicyList,
} from "../store/reducers/customerReducer";

const AddExistingPolicyForm = ({ visible, setVisible, customer }) => {
  const [loading, setLoading] = useState(false);
  const [messageApi, contextHolder] = message.useMessage();
  const [fileList, setFileList] = useState([]);
  const [form] = Form.useForm();
  const dispatch = useDispatch();

  const insuranceProductData = [
    "Health Insurance",
    "Motor Insurance",
    "Corporate Insurance",
    "Other Insurance",
  ];

  const handleOk = async () => {
    try {
      if (fileList.length === 0) {
        messageApi.error("Please upload a file.");
        return;
      }
      setLoading(true);

      const firebaseFormData = await form.validateFields();
      try {
        const response = await uploadtobucketaws(
          fileList[0].name,
          fileList[0].type
        );
        if (response?.url) {
          const { url, fields } = await response;
          const formData = new FormData();
          Object.entries(fields).forEach(([key, value]) => {
            formData.append(key, value);
          });
          formData.append("file", fileList[0].originFileObj);
          const uploadResponse = await fetch(url, {
            method: "POST",
            body: formData,
          });

          if (uploadResponse.ok) {
            firebaseFormData["policyUrl"] = url + fileList[0].name;
            firebaseFormData["customerId"] = customer?.customerId;
            dispatch(addPolicyData(firebaseFormData)).then((result) => {
              if (result?.payload?.success) {
                const data = { customerId: customer?.customerId };
                dispatch(fetchPolicyList(data)).then((resp) => {
                  messageApi.success("File uploaded successfully.");
                  setFileList([]);
                  setLoading(false);
                  setVisible(false);
                });
              } else {
                messageApi.warning("something went wrong.");
                setFileList([]);
                setLoading(false);
                setVisible(false);
              }
            });
          } else {
            setLoading(false);
            throw new Error("Failed to upload file to S3");
          }
        } else {
          setLoading(false);
          throw new Error("Failed to get presigned URL");
        }
      } catch (error) {
        setLoading(false);
        console.error("Error uploading file to S3:", error);
        messageApi.error("Failed to upload file to S3");
        return false;
      }
    } catch (error) {
      console.error("Error saving customer information:", error);
      messageApi.error("Failed to save customer information.");
      setLoading(false);
    }
  };

  const handleCancel = () => {
    setVisible(false);
  };

  const uploadtobucketaws = async (filename, contentType) => {
    try {
      const client = new S3Client({
        region: "ap-south-1",
        credentials: {
          accessKeyId: "AKIA2P2T7DUXW2FJ7JRL",
          secretAccessKey: "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",
        },
      });
      const { url, fields } = await createPresignedPost(client, {
        Bucket: "kokoro-insurance",
        Key: filename,
        Conditions: [
          ["content-length-range", 0, 10485760], // up to 10 MB
          ["starts-with", "$Content-Type", contentType],
        ],
        Fields: {
          acl: "public-read",
          "Content-Type": contentType,
        },
        Expires: 600, // Seconds before the presigned post expires. 3600 by default.
      });
      return { url, fields };
    } catch (error) {
      return error;
    }
  };

  const beforeUpload = (file) => {
    const isLt10M = file.size / 1024 / 1024 < 10;
    if (!isLt10M) {
      message.error("File must be smaller than 10MB!");
    }
    return isLt10M ? false : Upload.LIST_IGNORE;
  };

  return (
    <>
      {contextHolder}
      <LoadingSpinner loading={loading} />

      <Modal
        title="Add Policy"
        visible={visible}
        onOk={handleOk}
        onCancel={handleCancel}
        footer={[
          <Button key="cancel" onClick={handleCancel}>
            Cancel
          </Button>,
          <Button
            key="submit"
            type="primary"
            loading={loading}
            onClick={handleOk}
          >
            Save
          </Button>,
        ]}
      >
        <Form form={form} layout="vertical">
          <Form.Item
            name="policyType"
            label="Policy Type"
            rules={[
              {
                required: true,
                message: "Please select Policy Type",
              },
            ]}
          >
            <Select
              placeholder="Please select Policy Type"
              options={insuranceProductData.map((insuranceProduct) => ({
                label: insuranceProduct,
                value: insuranceProduct,
              }))}
            />
          </Form.Item>
        </Form>

        <Upload.Dragger
          maxCount={1}
          accept=".pdf"
          name="files"
          fileList={fileList}
          onChange={({ fileList }) => setFileList(fileList)}
          beforeUpload={beforeUpload} // Prevents automatic upload and checks file size
        >
          <p className="ant-upload-drag-icon">
            <InboxOutlined />
          </p>
          <p className="ant-upload-text">
            Click or drag your Policy to this area to upload
          </p>
        </Upload.Dragger>
      </Modal>
    </>
  );
};

export default AddExistingPolicyForm;
