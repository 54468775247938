import { initializeApp } from "firebase/app";
import { GoogleAuthProvider, OAuthProvider } from "firebase/auth";
import { getAuth } from "firebase/auth";
import { getFirestore } from "firebase/firestore";


// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyBgYGulsDfu4VFt_tcPfQwAPjZccMe7nA0",
  authDomain: "pelagic-core-297908.firebaseapp.com",
  databaseURL: "https://pelagic-core-297908-default-rtdb.firebaseio.com",
  projectId: "pelagic-core-297908",
  storageBucket: "pelagic-core-297908.appspot.com",
  messagingSenderId: "376262739834",
  appId: "1:376262739834:web:5a6b5c71e03dab70cbf8c3",
  measurementId: "G-YKVWVBQRMD",
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
export const auth = getAuth();
export const googleprovider = new GoogleAuthProvider();
export const microsoftprovider = new OAuthProvider("microsoft.com");

export const db = getFirestore(app);