import React, { useRef } from "react";
import { Form, Input, Select, DatePicker, Button } from "antd";
import SignatureCanvas from "react-signature-canvas";

const { Option } = Select;

const PolicyForm = () => {
  const formRef = useRef(null);
  const signatureRef = useRef(null);

  const onFinish = (values) => {
    console.log("Form submitted:", values);
    const signatureImage = signatureRef.current.toDataURL(); // Get signature as base64 data URL
    console.log("Signature Image:", signatureImage);
    // Handle form submission logic (e.g., API request to submit policy application with signature)
  };

  return (
    <Form
      name="policy-form"
      onFinish={onFinish}
      labelCol={{ span: 6 }}
      wrapperCol={{ span: 12 }}
      initialValues={{ remember: true }}
    >
      {/* Personal Information */}
      <Form.Item
        label="Name"
        name="name"
        rules={[{ required: true, message: "Please enter your name" }]}
      >
        <Input />
      </Form.Item>

      <Form.Item
        label="Contact Number"
        name="contactNumber"
        rules={[
          { required: true, message: "Please enter your contact number" },
        ]}
      >
        <Input />
      </Form.Item>

      <Form.Item
        label="Date of Birth"
        name="dateOfBirth"
        rules={[
          { required: true, message: "Please select your date of birth" },
        ]}
      >
        <DatePicker />
      </Form.Item>

      {/* Property Details */}
      <Form.Item
        label="Property Address"
        name="propertyAddress"
        rules={[
          { required: true, message: "Please enter your property address" },
        ]}
      >
        <Input />
      </Form.Item>

      <Form.Item
        label="Property Value"
        name="propertyValue"
        rules={[
          { required: true, message: "Please enter your property value" },
        ]}
      >
        <Input prefix="$" />
      </Form.Item>

      {/* Coverage Selection */}
      <Form.Item
        label="Insurance Type"
        name="insuranceType"
        rules={[
          { required: true, message: "Please select the type of insurance" },
        ]}
      >
        <Select placeholder="Select">
          <Option value="homeowners">Homeowners Insurance</Option>
          <Option value="auto">Auto Insurance</Option>
          {/* Add more options for different insurance types */}
        </Select>
      </Form.Item>

      <Form.Item
        label="Coverage Limit"
        name="coverageLimit"
        rules={[{ required: true, message: "Please enter the coverage limit" }]}
      >
        <Input prefix="$" />
      </Form.Item>

      <Form.Item
        label="Policy Start Date"
        name="policyStartDate"
        rules={[
          { required: true, message: "Please select the policy start date" },
        ]}
      >
        <DatePicker />
      </Form.Item>

      {/* Signature Field */}
      <Form.Item
        label="Digital Signature"
        name="signature"
        rules={[
          { required: true, message: "Please provide your digital signature" },
        ]}
      >
        <SignatureCanvas
          ref={signatureRef}
          penColor="black"
          canvasProps={{
            width: 300,
            height: 150,
            className: "signature-canvas",
          }}
        />
      </Form.Item>

      {/* Submit Button */}
      <Form.Item wrapperCol={{ offset: 6, span: 12 }}>
        <Button type="primary" onClick={() => formRef.current.submit()}>
          Submit Policy Application
        </Button>
      </Form.Item>
    </Form>
  );
};

export default PolicyForm;
