import axios from "axios";

const API = axios.create({
  baseURL: "https://kokoro-express-backends-production.up.railway.app",
  headers: {
    "Content-type": "application/json",
    Accept: "application/json",
  },
});

export default API;
