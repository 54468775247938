import { EditFilled, SaveFilled } from "@ant-design/icons";
import { Button, Select, Space, Table, Tooltip } from "antd";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import LoadingSpinner from "../components/Loader";
import { fetchTeamMembers } from "../store/reducers/teamReducer";

const TeamMembers = () => {
  const dispatch = useDispatch();
  const [uploading, setUploading] = useState(null);
  const agentAuthToken = useSelector((state) => state?.auth?.token);
  const agentOrganisationId = useSelector(
    (state) => state?.auth?.auth_profile?.organisation_id
  );
  const teamMemberData = useSelector((state) => state?.team?.teamMembersList);
  const userPermissionsData = ["read","write","admin"]
  // Prepare the data source with rowSpan calculated
  const dataSource = teamMemberData?.map((member) => {
      const rowSpanData = [];

      member?.groups?.forEach((group, index) => {
        rowSpanData.push({
          id: `${member?.id}`,
          key: `${member?.id}-${group?.id}`,
          name: index === 0 ? `${member?.firstName} ${member?.lastName}` : "",
          email: index === 0 ? member?.email : "",
          groupId: group?.id,
          group: group?.name,
          permission:
            member?.permissions
              .filter((permission) => permission?.groupId === group?.id)
              .map((p) => `${p.level}`)[0] || "No Permission",
          rowSpan: index === 0 ? member?.groups?.length : 0,
        });
      });

      return rowSpanData;
    })
    .flat();

  const columns = [
    {
      title: "Id",
      dataIndex: "id",
      key: "id",
      rowScope: "row",
      render: (text, record) => ({
        children: text,
        props: { rowSpan: record?.rowSpan },
      }),
    },
    {
      title: "Agent Name",
      dataIndex: "name",
      key: "name",
      render: (text, record) => ({
        children: text,
        props: { rowSpan: record?.rowSpan },
      }),
    },
    {
      title: "Agent Email",
      dataIndex: "email",
      key: "email",
      render: (text, record) => ({
        children: text,
        props: { rowSpan: record?.rowSpan },
      }),
    },
    {
      title: "Groups",
      dataIndex: "group",
      key: "group",
    },
    {
      title: "Permissions",
      dataIndex: "permission",
      key: "permission",
      render: (text, record) => ({
        children: [
          <div style={{ display: "flex", justifyContent: "space-between" }}>
            <Select
              // disabled={true}
              bordered={false}
              value = {text}
              placeholder={"Assign Permission"}
              style={{ width: "79%", padding: "0px" }}
              options={userPermissionsData.map((permissionData) => ({
                label: permissionData,
                value: permissionData,
              }))}
            />
            <Space>
              <Tooltip title={"Change Permission"}>
                <Button style={{ justifySelf: "center", flex: "col" }}>
                  <EditFilled />
                </Button>
              </Tooltip>
              <Tooltip title={"Save Permission"}>
                <Button style={{ justifySelf: "center", flex: "col" }} onClick={(e) => console.log(record, 'object')}>
                  <SaveFilled />
                </Button>
              </Tooltip>
            </Space>
          </div>,
        ],
      }),
    },
  ];

  useEffect(() => {
    setUploading(true);
    const data = {
      organisation_id: agentOrganisationId,
      token: agentAuthToken,
    };
    dispatch(fetchTeamMembers(data)).then((response) => {
      if (response?.payload?.data?.success === true) {
        setUploading(false);
      } else {
        setUploading(false);
      }
    });
  }, [agentAuthToken, agentOrganisationId, dispatch]);
  return (
    <div
      style={{
        minHeight: "66vh",
        margin: "25px",
        width: "100%",
        justifyContent: "center",
      }}
    >
      <LoadingSpinner loading={uploading} />

      <Table
        bordered
        dataSource={dataSource}
        columns={columns}
        pagination={false}
        scroll={{
          y: 350,
        }}
        title={() => <h3>My Team</h3>}
      />
    </div>
  );
};

export default TeamMembers;
