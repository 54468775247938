import { Button, Flex, Modal, Table, Typography, Tooltip, message } from "antd";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  fetchDocsList,
  deleteDocumentData,
} from "../store/reducers/customerReducer";
import AddCustomerDocsForm from "./AddCustomerDocsForm";
import LoadingSpinner from "./Loader";
import {
  DeleteTwoTone,
  EyeTwoTone,
  ExclamationCircleFilled,
} from "@ant-design/icons";
const { confirm } = Modal;

const { Text } = Typography;

// Component for displaying Customer Docs table
const CustomerDocsTable = ({ customer }) => {
  const [isAddDocFormVisible, setAddDocFormVisible] = useState(false);
  const [uploading, setUploading] = useState(false);
  const dispatch = useDispatch();
  const documentList = useSelector((state) => state?.customer?.documentList);

  const [pdfUrl, setpdfUrl] = useState("");
  const [isModalOpen, setIsModalOpen] = useState(false);

  const showModal = (url) => {
    setpdfUrl(url);
    setIsModalOpen(true);
  };

  const handleOk = () => {
    setIsModalOpen(false);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };

  // Function to convert a Firestore Timestamp to a JavaScript Date object
  function convertTimestampToDate(timestamp) {
    const { seconds, nanoseconds } = timestamp;
    const milliseconds = seconds * 1000 + nanoseconds / 1000000;
    return new Date(milliseconds);
  }

  // Helper function to format the date
  function formatDate(date) {
    const options = { day: "2-digit", month: "long", year: "numeric" };
    return new Intl.DateTimeFormat("en-GB", options)
      .format(date)
      .replace(" ", "-");
  }

  const showDeleteConfirm = (record) => {
    confirm({
      title: "Are you sure delete this Document?",
      icon: <ExclamationCircleFilled />,
      content: "Deleted documents cannot be restored.",
      okText: "Yes",
      okType: "danger",
      cancelText: "No",
      onOk() {
        setUploading(true);
        const data = { ...record, customerId: customer?.customerId };
        dispatch(deleteDocumentData(data)).then((response) => {
          if (response?.payload?.success === true) {
            dispatch(fetchDocsList(data)).then((result) => {
              if (result?.payload?.success === true) {
                setUploading(false);
                message.success("Document Deleted Successfully");
              } else {
                setUploading(false);
                message.success("Document Deleted Successfully");
              }
            });
          } else {
            setUploading(false);
            message.error("Something went wrong, please try again later");
          }
        });
      },
      onCancel() {},
    });
  };

  const columns = [
    {
      title: "Document Type",
      dataIndex: "documentType",
      key: "documentType",
      render: (_, record) => <>{record?.documentType}</>,
    },
    {
      title: "Uploaded Date",
      dataIndex: "uploadedDate",
      key: "uploadedDate",
      render: (_, record) => {
        const dateObject = convertTimestampToDate(record?.uploadedDate);
        const formattedDate = formatDate(dateObject);
        return <>{formattedDate}</>;
      },
    },
    {
      title: "Action",
      dataIndex: "documentUrl",
      key: "documentUrl",
      render: (_, record) => (
        <Flex gap={16}>
          <Tooltip title={"View Document"}>
            <Button onClick={() => showModal(record?.documentUrl)}>
              <EyeTwoTone />
            </Button>
          </Tooltip>
          <Tooltip title={"Delete Document"}>
            <Button onClick={() => showDeleteConfirm(record)}>
              <DeleteTwoTone twoToneColor="#dc3545" />
            </Button>
          </Tooltip>
        </Flex>
      ),
    },
  ];

  useEffect(() => {
    setUploading(true);
    const data = { customerId: customer?.customerId };
    dispatch(fetchDocsList(data)).then((response) => {
      if (response?.payload?.success === true) {
        setUploading(false);
      } else {
        setUploading(false);
      }
    });
  }, [customer, dispatch]);

  return (
    <>
      <AddCustomerDocsForm
        visible={isAddDocFormVisible}
        setVisible={setAddDocFormVisible}
        customer={customer}
      />

      <LoadingSpinner loading={uploading} />

      <Modal
        title="Document"
        open={isModalOpen}
        onOk={handleOk}
        onCancel={handleCancel}
        width="80%"
        style={{ top: 5 }}
      >
        <div style={{ height: "80vh" }}>
          <iframe
            title="PDF Viewer"
            src={pdfUrl}
            width="100%"
            height="100%"
            frameBorder="0"
          />
        </div>
      </Modal>

      <Table
        columns={columns}
        dataSource={documentList}
        pagination={false}
        scroll={{
          y: 170,
        }}
        title={() => {
          return (
            <Flex dir="row" justify={"space-between"}>
              <Text style={{ fontWeight: 600, fontSize: 16 }}>Documents</Text>
              <Button
                type="primary"
                onClick={() => {
                  setAddDocFormVisible(true);
                }}
              >
                Add Document
              </Button>
            </Flex>
          );
        }}
      />
    </>
  );
};

export default CustomerDocsTable;
