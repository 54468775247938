import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { fetchorganizationGroupsListAPIHelper, fetchTeamMembersDataAPIHelper } from "../../helpers/teamAPI";

const initialState = {
  teamMembersList: [],
  organisation:'',
  groupsList:[]
};


export const fetchTeamMembers = createAsyncThunk(
  "team/fetchTeamMembersData",
  async (data, thunkAPI) => {
    try {
      const response = await fetchTeamMembersDataAPIHelper(data);
      return response;
    } catch (err) {
      return thunkAPI.rejectWithValue(err.message);
    }
  }
);

export const fetchOrganizationGroupsList = createAsyncThunk(
  "team/fetchorganizationGroups",
  async (data, thunkAPI) => {
    try {
      const response = await fetchorganizationGroupsListAPIHelper(data);
      return response;
    } catch (err) {
      return thunkAPI.rejectWithValue(err.message);
    }
  }
);
export const teamSlice = createSlice({
  name: "team",
  initialState,
  reducers: {
    updateOrganisation: (state, action) => {
      state.organisation = action?.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchTeamMembers.fulfilled, (state, action) => {
        state.teamMembersList = action?.payload?.data?.teamMembersList;
      })
      .addCase(fetchTeamMembers.rejected, (state, action) => {
        state.teamMembersList = [];
      })
      .addCase(fetchOrganizationGroupsList.fulfilled, (state, action) => {
        state.groupsList = action?.payload?.data?.groups;
      })
      .addCase(fetchOrganizationGroupsList.rejected, (state, action) => {
        state.groupsList = [];
      });
  },
});

// Action creators are generated for each case reducer function
export const { updateOrganisation } = teamSlice.actions;

export default teamSlice.reducer;
