import { Button, Flex, Modal, Table, Typography, Tooltip, message } from "antd";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import AddFamilyMemberForm from "./AddFamilyMemberForm";
import {
  fetchMemberList,
  deleteMemberData,
} from "../store/reducers/customerReducer";
import { DeleteTwoTone, ExclamationCircleFilled } from "@ant-design/icons";
import LoadingSpinner from "./Loader";
const { confirm } = Modal;

const { Text } = Typography;

// Component for displaying Family Members of Customer.
const FamilyMemberTable = ({ customer }) => {
  const [isFamilyMemberFormVisible, setFamilyMemberFormVisible] =
    useState(false);
  const customerMemberList = useSelector(
    (state) => state?.customer?.customerMemberList
  );
  const dispatch = useDispatch();
  const [uploading, setUploading] = useState(false);

  const showDeleteConfirm = (record) => {
    confirm({
      title: "Are you sure remove this Member?",
      icon: <ExclamationCircleFilled />,
      content: "Removed members cannot be restored.",
      okText: "Yes",
      okType: "danger",
      cancelText: "No",
      onOk() {
        setUploading(true);
        const data = { ...record, customerId: customer?.customerId };
        dispatch(deleteMemberData(data)).then((response) => {
          if (response?.payload?.success === true) {
            dispatch(fetchMemberList(data)).then((result) => {
              if (result?.payload?.success === true) {
                setUploading(false);
                message.success("Member Deleted Successfully");
              } else {
                setUploading(false);
                message.success("Member Deleted Successfully");
              }
            });
          } else {
            setUploading(false);
            message.error("Something went wrong, please try again later");
          }
        });
      },
      onCancel() {
        // console.log("Cancel");
      },
    });
  };

  const columns = [
    {
      title: "Name",
      dataIndex: "memberName",
      key: "memberName",
      render: (_, record) => <>{record?.memberDetails?.memberFirstName} {record?.memberDetails?.memberLastName}</>,
    },
    {
      title: "Phone Number",
      dataIndex: "memberPhoneNumber",
      key: "memberPhoneNumber",
      render: (_, record) => {
        return <>{record?.memberDetails?.memberPhoneNumber}</>;
      },
    },
    {
      title: "Date of Birth",
      dataIndex: "memberDOB",
      key: "memberDOB",
      render: (_, record) => {
        return <>{record?.memberDetails?.memberDOB}</>;
      },
    },
    {
      title: "Relation",
      dataIndex: "memberRelation",
      key: "memberRelation",
      render: (_, record) => {
        return <>{record?.memberDetails?.memberRelation}</>;
      },
    },
    {
      title: "Action",
      dataIndex: "policyUrl",
      key: "policyUrl",
      render: (_, record) => (
        <Flex gap={16}>
          <Tooltip title={"Delete Policy"}>
            <Button onClick={() => showDeleteConfirm(record)}>
              <DeleteTwoTone twoToneColor="#dc3545" />
            </Button>
          </Tooltip>
        </Flex>
      ),
    },
  ];

  useEffect(() => {
    setUploading(true);
    const data = { customerId: customer?.customerId };
    dispatch(fetchMemberList(data)).then((response) => {
      if (response?.payload?.success === true) {
        setUploading(false);
      } else {
        setUploading(false);
      }
    });
  }, [customer, dispatch]);

  return (
    <>
      <AddFamilyMemberForm
        visible={isFamilyMemberFormVisible}
        setVisible={setFamilyMemberFormVisible}
        customer={customer}
      />

      <LoadingSpinner loading={uploading} />

      <Table
        columns={columns}
        dataSource={customerMemberList}
        pagination={false}
        scroll={{
          y: 170,
        }}
        title={() => {
          return (
            <Flex dir="row" justify={"space-between"}>
              <Text style={{ fontWeight: 600, fontSize: 16 }}>Members</Text>
              <Button
                type="primary"
                onClick={() => {
                  setFamilyMemberFormVisible(true);
                }}
              >
                Add Member
              </Button>
            </Flex>
          );
        }}
      />
    </>
  );
};

export default FamilyMemberTable;
