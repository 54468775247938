import {
  Table,
  Button,
  Flex,
  Typography,
  Row,
  Col,
  Card,
  Statistic,
} from "antd";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import LoadingSpinner from "../components/Loader";
import {
  fetchCustomersList,
  setActiveCustomer,
} from "../store/reducers/authReducer";
import AddCustomerForm from "../components/AddCustomerForm";
import {
  FileDoneOutlined,
  ProfileOutlined,
  UserOutlined,
} from "@ant-design/icons";
import { fetchOrganizationGroupsList } from "../store/reducers/teamReducer";
const { Text } = Typography;

const Dashboard = () => {
  const agentOrganisationId = useSelector((state) => state?.auth?.auth_profile?.organisation_id);
  const agentAuthToken = useSelector((state) => state?.auth?.token);

  const [uploading, setUploading] = useState(null);
  const dispatch = useDispatch();
  const customers = useSelector((state) => state?.auth?.customers);
  const navigate = useNavigate();
  const [isCustomerFormVisisble, setCustomerFormVisible] = useState(false);

  const onViewDetailsForCustomer = (data) => {
    dispatch(setActiveCustomer(data));
    navigate({ pathname: "/customer-account" });
  };

  useEffect(() => {
    setUploading(true);
    const data = {
      token: agentAuthToken,
      organisation_id: agentOrganisationId,
    };

    dispatch(fetchCustomersList(data)).then((response) => {
      dispatch(fetchOrganizationGroupsList(data)).then((resp) => {
        setUploading(false);
      });
    });
  }, [agentAuthToken, agentOrganisationId, dispatch]);

  const CustomerColoums = [
    {
      title: "Customer Name",
      dataIndex: "name",
      key: "name",
      render: (_, record) => (
        <>
          {record.firstName}  {record.lastName}
        </>
      ),
    },
    {
      title: "Email",
      dataIndex: "email",
      key: "email",
    },
    {
      title: "Phone",
      dataIndex: "phone",
      key: "phone",
    },
    {
      title: "View Customer",
      dataIndex: "url",
      key: "url",
      render: (_, record) => (
        <Button onClick={() => onViewDetailsForCustomer(record)}>
          Details
        </Button>
      ),
    },
  ];

  return (
    <div
      style={{
        minHeight: "66vh",
        margin: "25px",
        width: "100%",
        justifyContent: "center",
      }}
    >
      <AddCustomerForm
        visible={isCustomerFormVisisble}
        setVisible={setCustomerFormVisible}
      />
      <LoadingSpinner loading={uploading} />
      <Row style={{ marginBottom: "2%" }} gutter={16}>
        <Col span={8}>
          <Card bordered={false}>
            <Statistic
              title={
                <>
                  Customers <UserOutlined />
                </>
              }
              value={customers?.length}
            />
          </Card>
        </Col>
        <Col span={8}>
          <Card bordered={false}>
            <Statistic
              title={
                <>
                  {" "}
                  Quotes <ProfileOutlined />
                </>
              }
              value={4}
            />
          </Card>
        </Col>
        <Col span={8}>
          <Card bordered={false}>
            <Statistic
              title={
                <>
                  {" "}
                  Policies <FileDoneOutlined />
                </>
              }
              value={4}
            />
          </Card>
        </Col>
      </Row>

      <Table
        dataSource={customers}
        columns={CustomerColoums}
        pagination={false}
        scroll={{
          y: 260,
        }}
        title={() => {
          return (
            <Flex dir="row" justify={"space-between"}>
              <Text style={{ fontWeight: 600, fontSize: 16 }}>Customers</Text>
              <Button
                type="primary"
                onClick={() => {
                  setCustomerFormVisible(true);
                }}
              >
                New Customer
              </Button>
            </Flex>
          );
        }}
        rowKey="url"
        onRow={(record, rowIndex) => {
          return {
            onClick: () => {
              onViewDetailsForCustomer(record);
            },
          };
        }}
      />
    </div>
  );
};

export default Dashboard;
