import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { addCustomerDataAPIHelper, addMemberDataAPIHelper, addPolicyDataAPIHelper, fetchCustomerDataAPIHelper, fetchCustomerProfileAPIHelper, fetchDocsListAPIHelper, fetchMemberListAPIHelper, fetchPolicyListAPIHelper, addDocumentDataAPIHelper, deletePolicyAPIHelper, deleteDocumentAPIHelper, deleteMemberDataAPIHelper } from "../../helpers/customerAPI";

const initialState = {
  customerDetails: {},
  customerProfile:{},
  policyList:[],
  customerMemberList:[],
  documentList:[]
};

// To Add Customer Health Declaration Data
export const saveCustomerData = createAsyncThunk(
  "customer/addCustomerData",
  async (data, thunkAPI) => {
    try {
      const response = await addCustomerDataAPIHelper(data);
      return response;
    } catch (err) {
      return thunkAPI.rejectWithValue(err.message);
    }
  }
);

// To Fetch Customer Health Declaration Data
export const fetchCustomerData = createAsyncThunk(
  "customer/fetchCustomerData",
  async (data, thunkAPI) => {
    try {
      const response = await fetchCustomerDataAPIHelper(data);
      return response;
    } catch (err) {
      return thunkAPI.rejectWithValue(err.message);
    }
  }
);

// To Fetch Customer Profile
export const fetchCustomerProfile = createAsyncThunk(
  "customer/fetchCustomerProfile",
  async (data, thunkAPI) => {
    try {
      const response = await fetchCustomerProfileAPIHelper(data);
      return response;
    } catch (err) {
      return thunkAPI.rejectWithValue(err.message);
    }
  }
);

// To Add Customer Policy 
export const addPolicyData = createAsyncThunk(
  "customer/addPolicyData",
  async (data, thunkAPI) => {
    try {
      const response = await addPolicyDataAPIHelper(data);
      return response;
    } catch (err) {
      return thunkAPI.rejectWithValue(err.message);
    }
  }
);

// To Fetch Customer Policies List
export const fetchPolicyList = createAsyncThunk(
  "customer/fetchPolicyList",
  async (data, thunkAPI) => {
    try {
      const response = await fetchPolicyListAPIHelper(data);
      return response;
    } catch (err) {
      return thunkAPI.rejectWithValue(err.message);
    }
  }
);

// To Update Customer Policy
export const deletePolicyData = createAsyncThunk(
  "customer/deletePolicyData",
  async (data, thunkAPI) => {
    try {
      const response = await deletePolicyAPIHelper(data);
      return response;
    } catch (err) {
      return thunkAPI.rejectWithValue(err.message);
    }
  }
);

// To Add Customer Document 
export const addDocumentData = createAsyncThunk(
  "customer/addDocumentData",
  async (data, thunkAPI) => {
    try {
      const response = await addDocumentDataAPIHelper(data);
      return response;
    } catch (err) {
      return thunkAPI.rejectWithValue(err.message);
    }
  }
);

// To Fetch Customer Documents List
export const fetchDocsList = createAsyncThunk(
  "customer/fetchDocsList",
  async (data, thunkAPI) => {
    try {
      const response = await fetchDocsListAPIHelper(data);
      return response;
    } catch (err) {
      return thunkAPI.rejectWithValue(err.message);
    }
  }
);

// To Update Customer Document
export const deleteDocumentData = createAsyncThunk(
  "customer/deleteDocumentData",
  async (data, thunkAPI) => {
    try {
      const response = await deleteDocumentAPIHelper(data);
      return response;
    } catch (err) {
      return thunkAPI.rejectWithValue(err.message);
    }
  }
);

// To Add Customer member 
export const addMemberData = createAsyncThunk(
  "customer/addMemberData",
  async (data, thunkAPI) => {
    try {
      const response = await addMemberDataAPIHelper(data);
      return response;
    } catch (err) {
      return thunkAPI.rejectWithValue(err.message);
    }
  }
);

// To Fetch Customer Members List
export const fetchMemberList = createAsyncThunk(
  "customer/fetchMemberList",
  async (data, thunkAPI) => {
    try {
      const response = await fetchMemberListAPIHelper(data);
      return response;
    } catch (err) {
      return thunkAPI.rejectWithValue(err.message);
    }
  }
);

// To Update Customer member
export const deleteMemberData = createAsyncThunk(
  "customer/deleteMemberData",
  async (data, thunkAPI) => {
    try {
      const response = await deleteMemberDataAPIHelper(data);
      return response;
    } catch (err) {
      return thunkAPI.rejectWithValue(err.message);
    }
  }
);

export const customerSlice = createSlice({
  name: "customer",
  initialState,
  reducers: {
    updateCustomerStatus: (state, action) => {
      state.is_customer = true;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(saveCustomerData.fulfilled, (state, action) => {
        state.customerDetails = action?.payload?.data?.customerDetails
      })
      .addCase(saveCustomerData.rejected, (state, action) => {
      })
      .addCase(fetchCustomerData.fulfilled, (state, action) => {
        state.customerDetails = action?.payload?.data?.customerDetails;
      })
      .addCase(fetchCustomerData.rejected, (state, action) => {
      })
      .addCase(fetchCustomerProfile.fulfilled, (state, action) => {
        state.customerProfile = action?.payload?.customerProfile;
      })
      .addCase(fetchCustomerProfile.rejected, (state, action) => {
      })
      // Policy Cases ------------------>
      .addCase(addPolicyData.fulfilled, (state, action) => {
      })
      .addCase(addPolicyData.rejected, (state, action) => {
      })
      .addCase(fetchPolicyList.fulfilled, (state, action) => {
        state.policyList = action?.payload?.policyList;
      })
      .addCase(fetchPolicyList.rejected, (state, action) => {
      })
      .addCase(deletePolicyData.fulfilled, (state, action) => {
      })
      .addCase(deletePolicyData.rejected, (state, action) => {
      })
      // Member Cases ------------------>
      .addCase(addMemberData.fulfilled, (state, action) => {
      })
      .addCase(addMemberData.rejected, (state, action) => {
      })
      .addCase(fetchMemberList.fulfilled, (state, action) => {
        state.customerMemberList = action?.payload?.memberList;
      })
      .addCase(fetchMemberList.rejected, (state, action) => {
      })
      .addCase(deleteMemberData.fulfilled, (state, action) => {
      })
      .addCase(deleteMemberData.rejected, (state, action) => {
      })
      // Document Cases ------------------>
      .addCase(addDocumentData.fulfilled, (state, action) => {
      })
      .addCase(addDocumentData.rejected, (state, action) => {
      })
      .addCase(fetchDocsList.fulfilled, (state, action) => {
        state.documentList = action?.payload?.documentList;
      })
      .addCase(fetchDocsList.rejected, (state, action) => {
      })
      .addCase(deleteDocumentData.fulfilled, (state, action) => {
      })
      .addCase(deleteDocumentData.rejected, (state, action) => {
      });
  },
});

// Action creators are generated for each case reducer function
export const { updateCustomerStatus } = customerSlice.actions;

export default customerSlice.reducer;
