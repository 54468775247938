import React from 'react';
import { Card, Tabs, Form, Input, Button } from 'antd';
import PolicyForm from './PolicyForm';
import ClaimForm from './ClaimForm';
import WitnessStatementForm from './WitnessForm';
import MedicalReportForm from './MedicalReports';
import RepairEstimatesForm from './RepairEstimatesForm';
import PoliceForm from './PoliceForm';

const { TabPane } = Tabs;

const CustomerPage = () => {
  const customerData = {
    firstName: 'John',
    lastName: 'Doe',
    email: 'johndoe@example.com',
    phoneNumber: '123-456-7890',
    policyNumber: 'ABC123',
    policyType: 'Auto Insurance',
  };

  const onFinish = (values) => {
    console.log('Form submitted:', values);
    // Handle form submission logic (e.g., API request to submit customer details)
  };

  return (
    <div style={{ padding: '20px', width: '100%' }}>
      <h1>Customer Information</h1>

      {/* Customer Contact Information Section */}
      <div style={{ marginBottom: '20px' }}>
        {/* <h2>Customer Contact Information</h2> */}
        <Card>
          <p><strong>First Name:</strong> {customerData.firstName}</p>
          <p><strong>Last Name:</strong> {customerData.lastName}</p>
          <p><strong>Email:</strong> {customerData.email}</p>
          <p><strong>Phone Number:</strong> {customerData.phoneNumber}</p>
          <p><strong>Policy Number:</strong> {customerData.policyNumber}</p>
          <p><strong>Policy Type:</strong> {customerData.policyType}</p>
        </Card>
      </div>

      {/* Tabs Section for Forms */}
      <div>
        <Tabs defaultActiveKey="1" >
          <TabPane tab="Claim Form" key="1">
            <ClaimForm ></ClaimForm>
          </TabPane>
          <TabPane tab="Policy Info" key="2">
            <PolicyForm ></PolicyForm>
          </TabPane>
          <TabPane tab="Witness Statements" key="3">
            <WitnessStatementForm />
          </TabPane>
          <TabPane tab="Police Form" key="4">
            <PoliceForm />
          </TabPane>
          <TabPane tab="Medical Reports" key="5">
            <MedicalReportForm />
          </TabPane>
          <TabPane tab="Repair Estimates" key="6">
            <RepairEstimatesForm />
          </TabPane>
        </Tabs>
      </div>
    </div>
  );
};

export default CustomerPage;
