import { db } from "../config/firebaseConfig";
import {
  collection,
  addDoc,
  query,
  where,
  getDocs,
  updateDoc,
  getDoc,
  doc,
  orderBy,
} from "firebase/firestore";
import API from "../store/utils/apiEndPoint";

const KOKORO_CUSTOMERS = "kokoro-customers";
const KOKORO_QUOTES = "kokoro-customer-proposal";
const KOKORO_AGENTS = "kokoro-agents";

// For agent to login
export const LoginAPIHelper = async (payload) => {
  try {
    const config = {
      headers: {
        accept: "application/json",
        "Content-Type": "application/json",
      },
    };

    let response = await API.post(
      `/user/login`,
      {
        email: payload?.email,
        password: payload?.password,
      },
      config
    );
    return response;
  } catch (error) {
    return error?.response;
  }
};

// For agent to signup
export const SignupAPIHelper = async (payload) => {
  try {
    const config = {
      headers: {
        accept: "application/json",
        "Content-Type": "application/json",
      },
    };

    let response = await API.post(
      `/user/register`,
      {
        email: payload?.email,
        firstName: payload?.firstName,
        lastName: payload?.lastName,
        password: payload?.password,
      },
      config
    );
    return response;
  } catch (error) {
    return error?.response;
  }
};

// To create Quote and its respective Declaration form
export const CreateQuoteAndDeclarationAPIHelper = async (payload) => {
  try {
    const config = {
      headers: {
        accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${payload?.token}`,
      },
    };
    let response = await API.post(
      `/customer-accounts/quote/`,
      payload,
      config
    );
    return response;
  } catch (error) {
    return error?.response;
  }
};

// To Update Quote
export const updateQuoteProposalAPIHelper = async (payload) => {
  try {
    const config = {
      headers: {
        accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${payload?.token}`,
      },
    };
    let response = await API.put(
      `/customer-accounts/quote/${payload?.quoteId}`,
      {
        quoteStatus : payload?.status,
        notes : payload?.notes,
        preferredProduct : payload?.preferredProduct,
        quoteDetails : payload?.quoteDetails,
        addedByUserId:payload?.addedByUserId,
        groupId:payload?.groupId,
      },
      config
    );
    return response;
  } catch (error) {
    return error?.response;
  }
};

// To fetch particular Quote form
export const fetchQuoteAPIHelper = async (payload) => {
  try {
    const config = {
      headers: {
        accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${payload?.token}`,
      },
    };
    let response = await API.get(
      `/customer-accounts/${payload?.customerId}/quote/${payload?.quoteId}`,
      config
    );
    return response;
  } catch (error) {
    return error?.response;
  }
};

export const fetchProposalAPIHelper = async (data) => {
  try {
    const agentEmailList = new Set();

    if (data?.agentEmail) {
      agentEmailList.add(data?.agentEmail);
    }

    if (data?.organisation_id === "Kokoro-ALM") {
      const agentCollectionRef = collection(db, KOKORO_AGENTS);
      const org_q = query(
        agentCollectionRef,
        where("organisation_id", "==", "Kokoro-ALM")
      );
      const org_querySnapshot = await getDocs(org_q);
      org_querySnapshot.forEach((doc) => {
        agentEmailList.add(doc.data().email);
      });
    }

    const customerCollectionRef = collection(db, KOKORO_QUOTES);
    const q = query(
      customerCollectionRef,
      where("agentEmail", "in", Array.from(agentEmailList))
    );
    const querySnapshot = await getDocs(q);
    const customerData = [];
    querySnapshot.forEach((doc) => {
      customerData.push({ ...doc.data() });
    });
    return {
      success: true,
      message: "Proposal Fetched Successfully",
      customerProposals: customerData,
    };
  } catch (error) {
    throw error;
  }
};

// For Adding Customer
export const addCustomer = async (payload) => {
  try {
    const config = {
      headers: {
        accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${payload?.token}`,
      },
    };
    let response = await API.post(
      `/groups/${payload?.groupId}/customers`,
      {
        firstName: payload?.firstName,
        lastName: payload?.lastName,
        phone: payload?.phoneNumber,
        email: payload?.email,
        addedByUserId: payload?.user_id,
      },
      config
    );
    return response;
  } catch (error) {
    return error?.response;
  }
};

// Fetch all Customers
export const fetchCustomers = async (payload) => {
  try {
    const config = {
      headers: {
        accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${payload?.token}`,
      },
    };
    let response = await API.get(
      `/customer-accounts/customers/`,
      config
    );
    return response;
  } catch (error) {
    return error?.response;
  }
};

// Fetch all Quotes of single customer
export const fetchCustomerQuoteListAPIHelper = async (payload) => {
  try {
    const config = {
      headers: {
        accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${payload?.token}`,
      },
    };
    let response = await API.get(
      `/customer-accounts/${payload?.customerId}/quotes/`,
      config
    );
    return response;
  } catch (error) {
    return error?.response;
  }
};

// Fetch all Declaration Docs of single customer
export const fetchCustomerDeclarationListAPIHelper = async (data) => {
  try {
    const customerDocRef = doc(db, KOKORO_CUSTOMERS, data?.customerId);
    const docSnap = await getDoc(customerDocRef);

    if (docSnap.exists()) {
      var customersRef = collection(customerDocRef, "Health_decleration_form");
      const q = query(customersRef, orderBy("createdAt", "desc"));
      const querySnapshot = await getDocs(q);
      const customerDeclarationDocsList = [];
      querySnapshot.forEach((doc) => {
        customerDeclarationDocsList.push({ ...doc.data(), id: doc.id });
      });
      return {
        success: true,
        customerDeclarationDocs: customerDeclarationDocsList,
        message: "Declaration Docs fetched successfully",
      };
    } else {
      return { success: false, message: "No such Document" };
    }
  } catch (error) {
    throw error;
  }
};

// Not Using
export const addCustomerProductProposalAPIHelper = async (data) => {
  try {
    const customerDocRef = doc(db, KOKORO_CUSTOMERS, data?.customerId);
    const docSnap = await getDoc(customerDocRef);

    if (docSnap.exists()) {
      await addDoc(collection(customerDocRef, "Proposals"), {
        data: data,
        createdAt: new Date(),
        status: "Processing",
      });
      return { success: true, message: "Proposal created Successfully" };
    } else {
      return { success: false, message: "No such Document" };
    }
  } catch (error) {
    throw error;
  }
};

// Not Using
export const fetchCustomerProductProposalListAPIHelper = async (data) => {
  try {
    const customerDocRef = doc(db, KOKORO_CUSTOMERS, data?.customerId);
    const docSnap = await getDoc(customerDocRef);

    if (docSnap.exists()) {
      var customersRef = collection(customerDocRef, "Proposals");
      const q = query(customersRef, orderBy("createdAt", "desc"));
      const querySnapshot = await getDocs(q);
      const customerProductProposalList = [];
      querySnapshot.forEach((doc) => {
        customerProductProposalList.push({ ...doc.data(), id: doc.id });
      });
      return {
        success: true,
        customerProductProposals: customerProductProposalList,
        message: "Proposals fetched successfully",
      };
    } else {
      return { success: false, message: "No such Document" };
    }
  } catch (error) {
    throw error;
  }
};

/**
 * Add Organzation
 *
 * Add
 */
