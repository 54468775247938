import { Table, Button, Space, Flex, Typography } from "antd";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import LoadingSpinner from "../components/Loader";
import {
  fetchAllProposal,
  fetchCustomersList,
  setActiveCustomer,
} from "../store/reducers/authReducer";
import { useSession } from '../helpers/sessionContext';
import AddProductForm from "../components/AddProductForm";
const { Text } = Typography;

const InsuranceProducts = () => {
  const { sessionId } = useSession();
  const agentEmail = useSelector((state) => state?.auth?.auth_profile?.email);
  const agentOrganisationId = useSelector((state) => state?.auth?.auth_profile?.organisation_id);
  const [uploading, setUploading] = useState(null);
  const dispatch = useDispatch();
  const customers = useSelector((state) => state?.auth?.customers);
  const navigate = useNavigate();

  const [isCustomerFormVisisble, setCustomerFormVisible] = useState(false);

  const onViewDetailsForCustomer = (data) => {
    dispatch(setActiveCustomer(data));
    navigate({ pathname: "/customer-account" }) 
  }

  useEffect(() => {
    setUploading(true);
    const data = { agentEmail: agentEmail, organisation_id:agentOrganisationId };
    dispatch(fetchAllProposal(data)).then((response) => {
      if (response?.payload?.success === true) {
        setUploading(false);
      } else {
        setUploading(false);
      }
    });

    dispatch(fetchCustomersList(data)).then((response) => {
      if (response?.payload?.success === true) {
        setUploading(false);
      } else {
        setUploading(false);
      }
    });
  }, [agentEmail, agentOrganisationId, dispatch]);

  const CustomerColoums = [
    {
      title: "Insurance Plan",
      dataIndex: "firstName",
      key: "firstName",
    },
    {
      title: "Insurance Company",
      dataIndex: "agentName",
      key: "agentName",
    },
    {
      title: "Product Name",
      dataIndex: "agentEmail",
      key: "agentEmail",
    },
    // {
    //   title: "View Customer",
    //   dataIndex: "url",
    //   key: "url",
    //   render: (_, record) => (
    //     <Button onClick={() => onViewDetailsForCustomer(record)}>Details</Button>
    //   ),
    // },
  ];

  return (
    <div
      style={{
        minHeight: "66vh",
        margin: "25px",
        width: "100%",
        justifyContent: "center",
      }}
    >

      <AddProductForm
        visible={isCustomerFormVisisble}
        setVisible={setCustomerFormVisible}
      />

      <LoadingSpinner loading={uploading} />
        <Table
          dataSource={customers}
          columns={CustomerColoums}
          pagination={false}
          scroll={{
            y: 350,
          }}
          title={() => {
            return (
              <Flex dir="row" justify={"space-between"}>
                <Text style={{ fontWeight: 600, marginLeft: 12, fontSize: 16 }}>
                  Insurance Products
                </Text>
                <Button type="primary" onClick={() => {setCustomerFormVisible(true)}}>
                  New Product
                </Button>
              </Flex>
            );
          }}
        //   rowKey="url"
        //   onRow={(record, rowIndex) => {
        //     return {
        //       onClick: () => {
        //         onViewDetailsForCustomer(record); 
        //       }
        //     };
        //   }}
    
        />
    </div>
  );
};

export default InsuranceProducts;
