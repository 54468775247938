import React, { useState, useRef } from "react";
import {
  Modal,
  Form,
  Input,
  Button,
  Select,
  Divider,
  Space,
  message,
  InputNumber,
} from "antd";
import { addCustomer } from "../helpers/authAPI";
import { useSelector } from "react-redux";
// import firebase from 'firebase/app';
// import 'firebase/firestore';
import { PlusOutlined } from "@ant-design/icons";

let index = 0;

const { Option } = Select;

const AddProductForm = ({ visible, setVisible }) => {
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const [messageApi, contextHolder] = message.useMessage();

  const agentInfo = useSelector((state) => state?.auth?.auth_profile);

  const handleOk = async () => {
    try {
      setLoading(true);
      const formData = await form.validateFields();

      formData["agentEmail"] = agentInfo?.email;
      formData["agentName"] = agentInfo?.name;

      addCustomer(formData)
        .then((response) => {
          messageApi.open({
            type: "success",
            content: "Added customer to your account",
            duration: 5,
          });
        })
        .catch((err) => {
          messageApi.open({
            type: "error",
            content: "Unable to add customer. Please try again",
            duration: 5,
          });
        });
      form.resetFields();
      setLoading(false);
      setVisible(false);
    } catch (error) {
      console.error("Error saving customer information:", error);
      setLoading(false);
    }
  };

  const handleCancel = () => {
    setVisible(false);
  };

  // Start Insurance Company
  const [insuranceCompanies, setInsuranceCompanies] = useState([
    "HDFC ERGO",
    "Care Health",
    "Niva Bupa",
    "ICICI Lombard",
  ]);
  const [companyName, setCompanyName] = useState("");
  const companyInputRef = useRef(null);

  const addInsuranceCompanies = (e) => {
    e.preventDefault();
    setInsuranceCompanies([
      ...insuranceCompanies,
      companyName || `New Company ${index++}`,
    ]);
    setCompanyName("");
    setTimeout(() => {
      companyInputRef.current?.focus();
    }, 0);
  };
  // END  Insurance Comapny

  // Start Policy Period
  const [policyPeriodList, setPolicyPeriodList] = useState([
    "1 Year",
    "2 Years",
    "3 Years",
    "4 Years",
  ]);
  const [policyPeriodValue, setPolicyPeriodValue] = useState("");
  const policyPeriodInputRef = useRef(null);

  const addPolicyPeriod = (e) => {
    e.preventDefault();
    setPolicyPeriodList([
      ...policyPeriodList,
      `${policyPeriodValue} Years` || `${policyPeriodList.length + 1} Years`,
    ]);
    setPolicyPeriodValue("");
    setTimeout(() => {
      policyPeriodInputRef.current?.focus();
    }, 0);
  };
  // END  Policy Period

  return (
    <>
      {contextHolder}

      <Modal
        title="Product Information"
        visible={visible}
        onOk={handleOk}
        onCancel={handleCancel}
        footer={[
          <Button key="cancel" onClick={handleCancel}>
            Cancel
          </Button>,
          <Button
            key="submit"
            type="primary"
            loading={loading}
            onClick={handleOk}
          >
            Save
          </Button>,
        ]}
      >
        <Form form={form} layout="vertical">
          <Form.Item
            name="insurancePlan"
            label="Insurance Plan"
            rules={[{ required: true, message: "Please enter Insurance Plan" }]}
          >
            <Input />
          </Form.Item>
          {/* Done */}
          <Form.Item
            name="insuranceCompany"
            label="Insurance Company"
            rules={[
              { required: true, message: "Please select Insurance Company" },
            ]}
          >
            <Select
              placeholder="Insurance Company"
              dropdownRender={(menu) => (
                <>
                  {menu}
                  <Divider
                    style={{
                      margin: "8px 0",
                    }}
                  />
                  <Space
                    style={{
                      padding: "4px 8px 4px",
                      display: "flex",
                    }}
                  >
                    <Input
                      placeholder="Enter Compnay Name"
                      ref={companyInputRef}
                      value={companyName}
                      onChange={(event) => {
                        setCompanyName(event.target.value);
                      }}
                      onKeyDown={(e) => e.stopPropagation()}
                    />
                    <Button
                      type="text"
                      icon={<PlusOutlined />}
                      onClick={addInsuranceCompanies}
                    >
                      Add Company
                    </Button>
                  </Space>
                </>
              )}
              options={insuranceCompanies.map((company) => ({
                label: company,
                value: company,
              }))}
            />
          </Form.Item>
          {/* Done */}
          <Form.Item
            name="productName"
            label="Product Name"
            rules={[
              {
                required: true,
                message: "Please enter Product Name",
              },
            ]}
          >
            <Input placeholder="Product Name" />
          </Form.Item>
          {/* Done */}
          <Form.Item
            name="policyPeriod"
            label="Policy Periods"
            rules={[
              {
                required: true,
                message: "Please enter Policy Period",
              },
            ]}
          >
            <Select
              mode="multiple"
              allowClear
              placeholder="Policy Period List"
              dropdownRender={(menu) => (
                <>
                  {menu}
                  <Divider
                    style={{
                      margin: "8px 0",
                    }}
                  />
                  <Space
                    style={{
                      padding: "4px 8px 4px",
                      display: "flex",
                    }}
                  >
                    <InputNumber
                      style={{ width: "100%" }}
                      min={2}
                      max={99}
                      placeholder="Enter Policy Period"
                      ref={policyPeriodInputRef}
                      value={policyPeriodValue}
                      onChange={(value) => {
                        setPolicyPeriodValue(value);
                      }}
                      onKeyDown={(e) => e.stopPropagation()}
                    />
                    <Button
                      type="text"
                      icon={<PlusOutlined />}
                      onClick={addPolicyPeriod}
                    >
                      Add Policy Period
                    </Button>
                  </Space>
                </>
              )}
              options={policyPeriodList.map((policyPeriod) => ({
                label: policyPeriod,
                value: policyPeriod,
              }))}
            />
          </Form.Item>
          <Form.Item
            name="deductible"
            label="Deductible"
            rules={[
              {
                required: true,
                message: "Please enter Deductible",
              },
            ]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            name="Entry Age Min & Max"
            label="Entry Age Min & Max"
            rules={[{ required: true, message: "Entry Age Min & Max" }]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            name="waitingPeriodDiabetesBloodPressureCholesterol"
            label="Waiting Period - Diabetes, Blood Pressure, Cholesterol"
            rules={[{ required: true, message: "Please enter Waiting Period" }]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            name="roomType"
            label="Room Type"
            rules={[{ required: true, message: "Please enter Room Type" }]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            name="ICU"
            label="ICU"
            rules={[{ required: true, message: "Please enter ICU Details" }]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            name="restoreBenefit"
            label="Restore Benefit"
            rules={[
              {
                required: true,
                message: "Please enter Restore Benefit",
              },
            ]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            name="initialWaitingPeriod"
            label="Initial Waiting Period"
            rules={[
              {
                required: true,
                message: "Please enter Initial Waiting Period",
              },
            ]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            name="preExistingDiseaseWaitingPeriod"
            label="Pre Existing Disease Waiting Period"
            rules={[
              {
                required: true,
                message: "Please enter Pre Existing Disease Waiting Period",
              },
            ]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            name="specificDiseaseWaitingPeriod"
            label="Specific Disease Waiting Period"
            rules={[
              {
                required: true,
                message: "Please enter Specific Disease Waiting Period",
              },
            ]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            name="dayCareTreatment"
            label="Day Care Treatment"
            rules={[
              {
                required: true,
                message: "Please enter Day Care Treatment",
              },
            ]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            name="mentalHealthcare"
            label="Mental Healthcare"
            rules={[
              { required: true, message: "Please enter Mental Healthcare" },
            ]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            name="AYUSHTreatment"
            label="AYUSH Treatment"
            rules={[
              { required: true, message: "Please enter AYUSH Treatment" },
            ]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            name="modernTreatment"
            label="Modern Treatment"
            rules={[
              { required: true, message: "Please enter Modern Treatment" },
            ]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            name="Domiciliary Hospitalization"
            label="Domiciliary Hospitalization"
            rules={[
              {
                required: true,
                message: "Please enter Domiciliary Hospitalization",
              },
            ]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            name="prePostHospitalization"
            label="Pre & Post Hospitalization"
            rules={[
              {
                required: true,
                message: "Please enter Pre & Post Hospitalization",
              },
            ]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            name="costOfConsumables"
            label="Cost Of Consumables"
            rules={[
              { required: true, message: "Please enter Cost Of Consumables" },
            ]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            name="roadAmbulanceCharges"
            label="Road Ambulance Charges"
            rules={[
              {
                required: true,
                message: "Please enter Road Ambulance Charges",
              },
            ]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            name="airAmbulanceCharges"
            label="Air Ambulance Charges"
            rules={[
              { required: true, message: "Please enter Air Ambulance Charges" },
            ]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            name="healthCheckup"
            label="Health Check up"
            rules={[
              {
                required: true,
                message: "Please enter Health Check up Coverage",
              },
            ]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            name="organDonorExpenses"
            label="Organ Donor Expenses"
            rules={[
              { required: true, message: "Please enter Organ Donor Expenses" },
            ]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            name="maternityCharges"
            label="Maternity Charges"
            rules={[
              { required: true, message: "Please enter Maternity Charges" },
            ]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            name="coPayment"
            label="Co - Payment"
            rules={[
              { required: true, message: "Please enter Co - Payment Details" },
            ]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            name="teleMedicalScreening"
            label="Tele- Medical Screening"
            rules={[
              {
                required: true,
                message: "Please enter Tele- Medical Screening",
              },
            ]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            name="lifeLongRenewal"
            label="Life Long Renewal"
            rules={[
              { required: true, message: "Please enter Life Long Renewal" },
            ]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            name="animalBiteVaccination"
            label="Animal Bite (Vaccination)"
            rules={[
              {
                required: true,
                message: "Please enter Animal Bite (Vaccination)",
              },
            ]}
          >
            <Input />
          </Form.Item>
          Unique Features Sum Insured
          {/* <Form.Item
          name="insuranceProductNeed"
          label="Insurance Product Need"
          rules={[
            {
              required: true,
              message: "Please select your insurance product need",
            },
          ]}
        >
          <Select>
            <Option value="Motor Insurance">Motor Insurance</Option>
            <Option value="Health">Health</Option>
            <Option value="Corporate">Corporate</Option>
            <Option value="Others">Others</Option>
          </Select>
        </Form.Item> */}
        </Form>
      </Modal>
    </>
  );
};

export default AddProductForm;
