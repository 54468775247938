import React from "react";
import { Spin } from "antd";

export default function LoadingSpinner({ loading }) {
  return (
    <>
      <Spin spinning={loading} size="large" tip="Loading..." fullscreen />
    </>
  );
}
