import { configureStore } from "@reduxjs/toolkit";
import logger from "redux-logger";
import authReducer from "./reducers/authReducer";
import customerReducer from "./reducers/customerReducer";
import { combineReducers } from "@reduxjs/toolkit";
import { persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";
import { USER_LOGOUT } from "./actions/authActions";
import teamReducer from "./reducers/teamReducer";

const persistConfig = {
  key: "persist-root",
  storage,
};

const reducer = combineReducers({
  auth: authReducer,
  customer: customerReducer,
  team:teamReducer
});

const rootReducer = (state, action) => {
  if (action.type === USER_LOGOUT) {
    state = undefined;

    localStorage.removeItem('sessionId');
    // localStorage.clear();
  }

 
  
  return reducer(state, action);
};

const persistedReducer = persistReducer(persistConfig, rootReducer);

export default configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) => getDefaultMiddleware().concat(logger),
});

