import React from 'react';
import { Form, Input, Upload, Button, DatePicker } from 'antd';
import { UploadOutlined } from '@ant-design/icons';

const { TextArea } = Input;

const RepairEstimatesForm = () => {
  const onFinish = (values) => {
    console.log('Form submitted:', values);
    // Handle form submission logic (e.g., API request to submit repair estimates)
  };

  const normFile = (e) => {
    if (Array.isArray(e)) {
      return e;
    }
    return e && e.fileList;
  };

  return (
    <Form
      name="repair-estimates-form"
      onFinish={onFinish}
      labelCol={{ span: 6 }}
      wrapperCol={{ span: 12 }}
      initialValues={{ remember: true }}
    >
      {/* Itemized List of Damaged Property */}
      <h3>1. Itemized List of Damaged Property</h3>
      <Form.Item
        label="Description of Damaged Property"
        name="damagedProperty"
        rules={[{ required: true, message: 'Please describe the damaged property' }]}
      >
        <TextArea rows={4} />
      </Form.Item>

      {/* Labor Costs */}
      <h3>2. Labor Costs</h3>
      <Form.Item
        label="Labor Costs (per hour)"
        name="laborCost"
        rules={[{ required: true, message: 'Please specify the labor costs' }]}
      >
        <Input prefix="$" />
      </Form.Item>

      {/* Materials and Parts */}
      <h3>3. Materials and Parts</h3>
      <Form.Item
        label="Materials and Parts Needed"
        name="materialsNeeded"
        rules={[{ required: true, message: 'Please specify the materials needed' }]}
      >
        <TextArea rows={4} />
      </Form.Item>

      {/* Scope of Work */}
      <h3>4. Scope of Work</h3>
      <Form.Item
        label="Description of Work"
        name="scopeOfWork"
        rules={[{ required: true, message: 'Please describe the scope of work' }]}
      >
        <TextArea rows={4} />
      </Form.Item>

      {/* Cost Breakdown */}
      <h3>5. Cost Breakdown</h3>
      <Form.Item
        label="Detailed Cost Breakdown"
        name="costBreakdown"
        rules={[{ required: true, message: 'Please provide a detailed cost breakdown' }]}
      >
        <TextArea rows={4} />
      </Form.Item>

      {/* Timeline for Repairs */}
      <h3>6. Timeline for Repairs</h3>
      <Form.Item
        label="Estimated Timeline"
        name="timeline"
        rules={[{ required: true, message: 'Please specify the estimated timeline' }]}
      >
        <Input />
      </Form.Item>

      {/* Contractor Information */}
      <h3>7. Contractor Information</h3>
      <Form.Item
        label="Contractor Contact Information"
        name="contractorInfo"
        rules={[{ required: true, message: 'Please provide the contractor\'s contact information' }]}
      >
        <Input />
      </Form.Item>

      {/* Additional Notes or Recommendations */}
      <h3>8. Additional Notes or Recommendations</h3>
      <Form.Item
        label="Additional Notes"
        name="additionalNotes"
      >
        <TextArea rows={4} />
      </Form.Item>

      {/* Photographic Documentation */}
      <h3>9. Photographic Documentation</h3>
      <Form.Item
        label="Upload Photos"
        name="photos"
        valuePropName="fileList"
        getValueFromEvent={normFile}
        extra="Please upload supporting photos of the damaged property."
      >
        <Upload name="photos" multiple={true}>
          <Button icon={<UploadOutlined />}>Click to Upload</Button>
        </Upload>
      </Form.Item>

      {/* Submit Button */}
      <Form.Item wrapperCol={{ offset: 6, span: 12 }}>
        <Button type="primary" htmlType="submit">
          Submit Repair Estimates
        </Button>
      </Form.Item>
    </Form>
  );
};

export default RepairEstimatesForm;
