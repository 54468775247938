import React, { useEffect, useState } from "react";
import {
  Row,
  Col,
  Button,
  Card,
  Form,
  Input,
  DatePicker,
  Divider,
  Select,
  Space,
  message,
  InputNumber,
} from "antd";
import { CloseOutlined } from "@ant-design/icons";
import dayjs from "dayjs";
import customParseFormat from "dayjs/plugin/customParseFormat";
import {
  fetchCustomerData,
  fetchCustomerProfile,
  saveCustomerData,
} from "../store/reducers/customerReducer";
import { useDispatch} from "react-redux";
import { useSearchParams, useNavigate } from "react-router-dom";
import LoadingSpinner from "../components/Loader";
import TextArea from "antd/es/input/TextArea";

dayjs.extend(customParseFormat);
const dateFormat = "DD-MMMM-YYYY";
const today = dayjs().format(dateFormat);

function HealthDeclaration() {
  const [form] = Form.useForm();
  const SELECT_PLACEHOLDER = "Select any one";
  const dispatch = useDispatch();
  const [searchParams] = useSearchParams();
  const collectionId = searchParams.get("id");
  const [uploading, setUploading] = useState(null);
  const navigate = useNavigate();

  const onFinish = (values) => {
    setUploading(true);
    const data = {
      customerDetails: JSON.stringify(values),
      collectionId: collectionId,
    };
    dispatch(saveCustomerData(data))
      .then((resp) => {
        if (resp?.payload?.success === true) {
          setUploading(false);
          message.success(resp?.payload?.message);
        } else {
          setUploading(false);
          message.warning("something went wrong!");
        }
      })
      .catch((error) => {
        message.warning("something went wrong!");
        setUploading(false);
      });
    setUploading(false);
  };
  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };

  useEffect(() => {
    setUploading(true);
    const data = { collectionId: collectionId };
    dispatch(fetchCustomerProfile(data))
      .then((result) => {
        if (result?.payload?.success === true) {
          dispatch(fetchCustomerData(data)).then((response) => {
            if (response?.payload?.success === true) {
              const newDataArray = response?.payload?.customerDetails?.items?.map((item) => {
                var newItem = { ...item };
                newItem.dob = dayjs(item.dob);
                if (newItem.diabetesDuration) {
                  newItem.diabetesDuration = dayjs(item.diabetesDuration);
                }
                if (newItem.bloodpressureDuration) {
                  newItem.bloodpressureDuration = dayjs(
                    item.bloodpressureDuration
                  );
                }
                if (newItem.asthmaDuration) {
                  newItem.asthmaDuration = dayjs(item.asthmaDuration);
                }
                if (newItem.cholesterolDuration) {
                  newItem.cholesterolDuration = dayjs(item.cholesterolDuration);
                }
                if (newItem.thyroidDuration) {
                  newItem.thyroidDuration = dayjs(item.thyroidDuration);
                }
                return newItem;
              });
              form.setFieldsValue(response?.payload?.customerDetails);
              form.setFieldsValue({
                items: newDataArray,
                proposerDOB: dayjs(response?.payload?.customerDetails?.proposerDOB),
                NomineeDOB: dayjs(response?.payload?.customerDetails?.NomineeDOB),
              });
              setUploading(false);
            } else {
              form.setFieldsValue({
                Proposername: result?.payload?.customerProfile.firstName,
                emailID: result?.payload?.customerProfile?.email,
                mobilenumber: result?.payload?.customerProfile?.phoneNumber,
              });
              setUploading(false);
            }
          });
        } else {
          message.warning("something went wrong!");
          navigate({ pathname: "/" });
        }
      })
      .catch((error) => {
        message.warning("something went wrong!");
      });
  }, [collectionId, form, dispatch, navigate]);

  return (
    <Row
      style={{ minHeight: "75vh", width: "100%" }}
      justify="center"
      align="middle"
    >
      <LoadingSpinner loading={uploading} />
      <Col
        xs={24}
        sm={12}
        lg={8}
        style={{ display: "flex", width: "100%" }}
        justifyContent="center"
      >
        <Form
          layout="vertical"
          form={form}
          name="Health Declaration Form"
          style={{
            width: "100%",
            marginTop: "5%",
            marginBottom: "5%",
          }}
          autoComplete="off"
          initialValues={{
            items: [],
          }}
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
        >
          <Card
            title={`Health Declaration Form`}
            key={"Client_Information_Sheet "}
            style={{ marginTop: "5%" }}
          >
            <Divider orientation="left" orientationMargin="0">
              Contact Information
            </Divider>

            <Form.Item
              label="Proposer Name"
              name="Proposername"
              rules={[
                {
                  required: true,
                  message: "Please enter your Proposer Name!",
                },
              ]}
            >
              <Input />
            </Form.Item>

            <Form.Item
              label="Email ID"
              name="emailID"
              rules={[
                {
                  required: true,
                  message: "Please enter your Email ID!",
                },
              ]}
            >
              <Input />
            </Form.Item>

            <Form.Item
              label="Mobile Number"
              name="mobilenumber"
              rules={[
                {
                  required: true,
                  message: "Please enter your Mobile Number!",
                },
              ]}
            >
              <Input />
            </Form.Item>

            <Form.Item
              label="Alternate Mobile Number"
              name="AlternateMobileNumber"
              rules={[
                {
                  required: true,
                  message: "Please enter your Alternate Mobile Number!",
                },
              ]}
            >
              <Input />
            </Form.Item>

            <Form.Item
              label="Full Address"
              name="FullAddress"
              rules={[
                {
                  required: true,
                  message: "Please enter your Full Address!",
                },
              ]}
            >
              <TextArea />
            </Form.Item>

            <Divider orientation="left" orientationMargin="0">
              Insurance Needs
            </Divider>

            <Form.Item
              label="No of Insured"
              name="NumberOfInsured"
              rules={[
                {
                  required: true,
                  message: "Please enter your No. of Insured!",
                },
              ]}
            >
              <Input />
            </Form.Item>

            <Form.Item
              label="Is Proposer included"
              name="Proposerincluded"
              rules={[
                {
                  required: true,
                  message: "Please select your Is Proposer included!",
                },
              ]}
            >
              <Select
                placeholder={SELECT_PLACEHOLDER}
                options={[
                  {
                    value: "Yes",
                    label: "Yes",
                  },
                  {
                    value: "No",
                    label: "No",
                  },
                ]}
              />
            </Form.Item>

            <Divider orientation="left" orientationMargin="0">
              Additional Information
            </Divider>

            <Form.Item
              label="Proposer Date of Birth"
              name="proposerDOB"
              rules={[
                {
                  required: true,
                  message: "Please select Proposer Date of Birth!",
                },
              ]}
            >
              <DatePicker
                style={{
                  width: "100%",
                }}
                format={dateFormat}
                maxDate={dayjs(today, dateFormat)}
              />
            </Form.Item>

            <Form.Item
              label="Gender"
              name="gender"
              rules={[
                {
                  required: true,
                  message: "Please select your Gender!",
                },
              ]}
            >
              <Select
                placeholder={SELECT_PLACEHOLDER}
                options={[
                  {
                    value: "Male",
                    label: "Male",
                  },
                  {
                    value: "Female",
                    label: "Female",
                  },
                  {
                    value: "Other",
                    label: "Other",
                  },
                ]}
              />
            </Form.Item>

            <Form.Item
              label="Marital Status"
              name="maritalstatus"
              rules={[
                {
                  required: true,
                  message: "Please enter your Marital Status!",
                },
              ]}
            >
              <Select
                placeholder={SELECT_PLACEHOLDER}
                options={[
                  {
                    value: "Married",
                    label: "Married",
                  },
                  {
                    value: "Unmarried",
                    label: "Unmarried",
                  },
                  {
                    value: "Other",
                    label: "Other",
                  },
                ]}
              />
            </Form.Item>

            <Divider orientation="left" orientationMargin="0">
              Occupation
            </Divider>

            <Form.Item
              label="Occupation"
              name="occupation"
              rules={[
                {
                  required: true,
                  message: "Please enter your Occupation!",
                },
              ]}
            >
              <Input />
            </Form.Item>

            <Form.Item
              label="Company Name"
              name="companyName"
              rules={[
                {
                  required: true,
                  message: "Please enter your company name!",
                },
              ]}
            >
              <Input />
            </Form.Item>

            <Form.Item
              label="Annual Income"
              name="annualincome"
              rules={[
                {
                  required: true,
                  message: "Please enter your Annual Income!",
                },
              ]}
            >
              <Input />
            </Form.Item>

            <Divider orientation="left" orientationMargin="0">
              Nominee
            </Divider>

            <Form.Item
              label="Nominee Name"
              name="NomineeName"
              rules={[
                {
                  required: true,
                  message: "Please enter your Nominee Name!",
                },
              ]}
            >
              <Input />
            </Form.Item>

            <Form.Item
              label="Nominee Date of Birth"
              name="NomineeDOB"
              rules={[
                {
                  required: true,
                  message: "Please select Nominee Date of Birth!",
                },
              ]}
            >
              <DatePicker
                style={{
                  width: "100%",
                }}
                format={dateFormat}
                maxDate={dayjs(today, dateFormat)}
              />
            </Form.Item>

            <Form.Item
              label="Nominee Relation"
              name="NomineeRelation"
              rules={[
                {
                  required: true,
                  message: "Please enter your Nominee Relation!",
                },
              ]}
            >
              <Input />
            </Form.Item>

            <Divider orientation="left" orientationMargin="0">
              KYC Information
            </Divider>

            <Form.Item
              label="PAN Number"
              name="PANnumber"
              rules={[
                {
                  required: true,
                  message: "Please enter your PAN Number!",
                },
              ]}
            >
              <Input />
            </Form.Item>

            <Form.Item
              label="Aadhaar Number"
              name="AadhaarNumber"
              rules={[
                {
                  required: true,
                  message: "Please enter your Aadhaar Number!",
                },
              ]}
            >
              <Input />
            </Form.Item>

            <Form.Item
              label="E-Insurance Account"
              name="EInsuranceAccount"
              // rules={[
              //   {
              //     required: true,
              //     message: "Please enter your E-Insurance Account!",
              //   },
              // ]}
            >
              <Input />
            </Form.Item>
          </Card>

          <Form.List name="items">
            {(fields, { add, remove }) => (
              <div
                style={{
                  display: "flex",
                  rowGap: 16,
                  flexDirection: "column",
                }}
              >
                {fields.map((field) => (
                  <Card
                    size="small"
                    title={`Insured ${field.name + 1}`}
                    key={field.key}
                    style={{ marginTop: "5%" }}
                    extra={
                      <CloseOutlined
                        onClick={() => {
                          remove(field.name);
                        }}
                      />
                    }
                  >
                    <Form.Item
                      label="Full Name as per Govt. ID"
                      name={[field.name, "fullName"]}
                      rules={[
                        {
                          required: true,
                          message: "Please enter your Full Name!",
                        },
                      ]}
                    >
                      <Input />
                    </Form.Item>

                    <Row style={{ justifyContent: "space-between" }}>
                      <Col xs={24} sm={12} lg={10}>
                        <Form.Item
                          label="Date of Birth"
                          name={[field.name, "dob"]}
                          rules={[
                            {
                              required: true,
                              message: "Please select your Date of Birth!",
                            },
                          ]}
                        >
                          <DatePicker
                            style={{
                              width: "100%",
                            }}
                            format={dateFormat}
                            maxDate={dayjs(today, dateFormat)}
                          />
                        </Form.Item>
                      </Col>
                      <Col xs={24} sm={12} lg={10}>
                        <Form.Item
                          label="Gender"
                          name={[field.name, "gender"]}
                          rules={[
                            {
                              required: true,
                              message: "Please select your Gender!",
                            },
                          ]}
                        >
                          <Select
                            placeholder={SELECT_PLACEHOLDER}
                            options={[
                              {
                                value: "Male",
                                label: "Male",
                              },
                              {
                                value: "Female",
                                label: "Female",
                              },
                              {
                                value: "Other",
                                label: "Other",
                              },
                            ]}
                          />
                        </Form.Item>
                      </Col>
                    </Row>

                    <Form.Item
                      label="Occupation"
                      name={[field.name, "occupation"]}
                      rules={[
                        {
                          required: true,
                          message: "Please enter your Occupation!",
                        },
                      ]}
                    >
                      <Input />
                    </Form.Item>

                    <Row style={{ justifyContent: "space-between" }}>
                      <Col xs={24} sm={12} lg={10}>
                        <Form.Item
                          label="Height (in Cm)"
                          name={[field.name, "height"]}
                          rules={[
                            {
                              required: true,
                              message: "Please enter your Height!",
                            },
                          ]}
                        >
                          <InputNumber style={{width:'100%'}} min={1}/>
                        </Form.Item>
                      </Col>
                      <Col xs={24} sm={12} lg={10}>
                        <Form.Item
                          label="Weight (in Kg)"
                          name={[field.name, "weight"]}
                          rules={[
                            {
                              required: true,
                              message: "Please enter your Weight!",
                            },
                          ]}
                        >
                          <InputNumber style={{width:'100%'}} min={1}/>
                        </Form.Item>
                      </Col>
                    </Row>

                    <Divider orientation="left" orientationMargin="0">
                      Pre Existing Disease - Yes/No, if Yes Specify Since when
                    </Divider>

                    <Row style={{ justifyContent: "space-between" }}>
                      <Col xs={24} sm={12} lg={10}>
                        <Form.Item
                          label="Diabetes"
                          name={[field.name, "diabetes"]}
                          rules={[
                            {
                              required: true,
                              message: "Please select an option!",
                            },
                          ]}
                        >
                          <Select
                            placeholder={SELECT_PLACEHOLDER}
                            options={[
                              {
                                value: "Yes",
                                label: "Yes",
                              },
                              {
                                value: "No",
                                label: "No",
                              },
                            ]}
                          />
                        </Form.Item>
                      </Col>
                      <Col xs={24} sm={12} lg={10}>
                        <Form.Item noStyle shouldUpdate>
                          {({ getFieldsValue }) =>
                            getFieldsValue()?.items &&
                            getFieldsValue()?.items[field?.name]?.diabetes ===
                              "Yes" ? (
                              <Form.Item
                                name={[field.name, "diabetesDuration"]}
                                label="Diabetes (Duration)"
                                rules={[
                                  {
                                    required: true,
                                    message: "Please input Diabetes duration!",
                                  },
                                ]}
                              >
                                <DatePicker
                                  style={{
                                    width: "100%",
                                  }}
                                  format={dateFormat}
                                  maxDate={dayjs(today, dateFormat)}
                                />
                              </Form.Item>
                            ) : null
                          }
                        </Form.Item>
                      </Col>
                    </Row>

                    <Row style={{ justifyContent: "space-between" }}>
                      <Col xs={24} sm={12} lg={10}>
                        <Form.Item
                          label="Blood Pressure"
                          name={[field.name, "bloodpressure"]}
                          rules={[
                            {
                              required: true,
                              message: "Please select an option!",
                            },
                          ]}
                        >
                          <Select
                            placeholder={SELECT_PLACEHOLDER}
                            options={[
                              {
                                value: "Yes",
                                label: "Yes",
                              },
                              {
                                value: "No",
                                label: "No",
                              },
                            ]}
                          />
                        </Form.Item>
                      </Col>
                      <Col xs={24} sm={12} lg={10}>
                        <Form.Item noStyle shouldUpdate>
                          {({ getFieldsValue }) =>
                            getFieldsValue()?.items &&
                            getFieldsValue()?.items[field.name]
                              ?.bloodpressure === "Yes" ? (
                              <Form.Item
                                label="Blood Pressure (Duration)"
                                name={[field.name, "bloodpressureDuration"]}
                                rules={[
                                  {
                                    required: true,
                                    message:
                                      "Please input Blood Pressure duration!",
                                  },
                                ]}
                              >
                                <DatePicker
                                  style={{
                                    width: "100%",
                                  }}
                                  format={dateFormat}
                                  maxDate={dayjs(today, dateFormat)}
                                />
                              </Form.Item>
                            ) : null
                          }
                        </Form.Item>
                      </Col>
                    </Row>

                    <Row style={{ justifyContent: "space-between" }}>
                      <Col xs={24} sm={12} lg={10}>
                        <Form.Item
                          label="Asthma"
                          name={[field.name, "asthma"]}
                          rules={[
                            {
                              required: true,
                              message: "Please select an option!",
                            },
                          ]}
                        >
                          <Select
                            placeholder={SELECT_PLACEHOLDER}
                            options={[
                              {
                                value: "Yes",
                                label: "Yes",
                              },
                              {
                                value: "No",
                                label: "No",
                              },
                            ]}
                          />
                        </Form.Item>
                      </Col>
                      <Col xs={24} sm={12} lg={10}>
                        <Form.Item noStyle shouldUpdate>
                          {({ getFieldsValue }) =>
                            getFieldsValue()?.items &&
                            getFieldsValue()?.items[field.name]?.asthma ===
                              "Yes" ? (
                              <Form.Item
                                label="Asthma (Duration)"
                                name={[field.name, "asthmaDuration"]}
                                rules={[
                                  {
                                    required: true,
                                    message: "Please input Asthma duration!",
                                  },
                                ]}
                              >
                                <DatePicker
                                  style={{
                                    width: "100%",
                                  }}
                                  format={dateFormat}
                                  maxDate={dayjs(today, dateFormat)}
                                />
                              </Form.Item>
                            ) : null
                          }
                        </Form.Item>
                      </Col>
                    </Row>

                    <Row style={{ justifyContent: "space-between" }}>
                      <Col xs={24} sm={12} lg={10}>
                        <Form.Item
                          label="Cholesterol"
                          name={[field.name, "cholesterol"]}
                          rules={[
                            {
                              required: true,
                              message: "Please select an option!",
                            },
                          ]}
                        >
                          <Select
                            placeholder={SELECT_PLACEHOLDER}
                            options={[
                              {
                                value: "Yes",
                                label: "Yes",
                              },
                              {
                                value: "No",
                                label: "No",
                              },
                            ]}
                          />
                        </Form.Item>
                      </Col>
                      <Col xs={24} sm={12} lg={10}>
                        <Form.Item noStyle shouldUpdate>
                          {({ getFieldsValue }) =>
                            getFieldsValue()?.items &&
                            getFieldsValue()?.items[field.name]?.cholesterol ===
                              "Yes" ? (
                              <Form.Item
                                shouldUpdate
                                label="Cholesterol (Duration)"
                                name={[field.name, "cholesterolDuration"]}
                                rules={[
                                  {
                                    required: true,
                                    message:
                                      "Please input Cholesterol duration!",
                                  },
                                ]}
                              >
                                <DatePicker
                                  style={{
                                    width: "100%",
                                  }}
                                  format={dateFormat}
                                  maxDate={dayjs(today, dateFormat)}
                                />
                              </Form.Item>
                            ) : null
                          }
                        </Form.Item>
                      </Col>
                    </Row>

                    <Row style={{ justifyContent: "space-between" }}>
                      <Col xs={24} sm={12} lg={10}>
                        <Form.Item
                          label="Thyroid (Hyper/Hypo)"
                          name={[field.name, "thyroid"]}
                          rules={[
                            {
                              required: true,
                              message: "Please select an option!",
                            },
                          ]}
                        >
                          <Select
                            placeholder={SELECT_PLACEHOLDER}
                            options={[
                              {
                                value: "Yes",
                                label: "Yes",
                              },
                              {
                                value: "No",
                                label: "No",
                              },
                            ]}
                          />
                        </Form.Item>
                      </Col>
                      <Col xs={24} sm={12} lg={10}>
                        <Form.Item noStyle shouldUpdate>
                          {({ getFieldsValue }) =>
                            getFieldsValue()?.items &&
                            getFieldsValue()?.items[field.name]?.thyroid ===
                              "Yes" ? (
                              <Form.Item
                                label="Thyroid (Duration)"
                                name={[field.name, "thyroidDuration"]}
                                rules={[
                                  {
                                    required: true,
                                    message: "Please input Thyroid duration!",
                                  },
                                ]}
                              >
                                <DatePicker
                                  style={{
                                    width: "100%",
                                  }}
                                  format={dateFormat}
                                  maxDate={dayjs(today, dateFormat)}
                                />
                              </Form.Item>
                            ) : null
                          }
                        </Form.Item>
                      </Col>
                    </Row>

                    <Row style={{ justifyContent: "space-between" }}>
                      <Col xs={24} sm={12} lg={10}>
                        <Form.Item
                          label="Genetic Disease"
                          name={[field.name, "genetic"]}
                          rules={[
                            {
                              required: true,
                              message: "Please select an option!",
                            },
                          ]}
                        >
                          <Select
                            placeholder={SELECT_PLACEHOLDER}
                            options={[
                              {
                                value: "Yes",
                                label: "Yes",
                              },
                              {
                                value: "No",
                                label: "No",
                              },
                            ]}
                          />
                        </Form.Item>
                      </Col>
                      <Col xs={24} sm={12} lg={10}>
                        <Form.Item noStyle shouldUpdate>
                          {({ getFieldsValue }) =>
                            getFieldsValue()?.items &&
                            getFieldsValue()?.items[field.name]?.genetic ===
                              "Yes" ? (
                              <Form.Item
                                label="Specify Genetic Disease"
                                name={[field.name, "geneticDisease"]}
                                rules={[
                                  {
                                    required: true,
                                    message:
                                      "Please input Genetic disease info!",
                                  },
                                ]}
                              >
                                <Input />
                              </Form.Item>
                            ) : null
                          }
                        </Form.Item>
                      </Col>
                    </Row>

                    <Form.Item
                      label="Any Surgery (if yes Specify Details and share with us)"
                      name={[field.name, "surgery"]}
                      rules={[
                        {
                          required: true,
                          message: "Please select an option!",
                        },
                      ]}
                    >
                      <Select
                        placeholder={SELECT_PLACEHOLDER}
                        options={[
                          {
                            value: "Yes",
                            label: "Yes",
                          },
                          {
                            value: "No",
                            label: "No",
                          },
                        ]}
                      />
                    </Form.Item>

                    <Form.Item noStyle shouldUpdate>
                      {({ getFieldsValue }) =>
                        getFieldsValue()?.items &&
                        getFieldsValue()?.items[field.name]?.surgery ===
                          "Yes" ? (
                          <Form.Item
                            label="Surgery Details"
                            name={[field.name, "surgeryName"]}
                            rules={[
                              {
                                required: true,
                                message: "Please input Surgery details",
                              },
                            ]}
                          >
                            <TextArea />
                          </Form.Item>
                        ) : null
                      }
                    </Form.Item>

                    <Form.Item
                      label="Medication/Tablet (if yes Specify Details and share with us)"
                      name={[field.name, "medication"]}
                      rules={[
                        {
                          required: true,
                          message: "Please select an option!",
                        },
                      ]}
                    >
                      <Select
                        placeholder={SELECT_PLACEHOLDER}
                        options={[
                          {
                            value: "Yes",
                            label: "Yes",
                          },
                          {
                            value: "No",
                            label: "No",
                          },
                        ]}
                      />
                    </Form.Item>

                    <Form.Item noStyle shouldUpdate>
                      {({ getFieldsValue }) =>
                        getFieldsValue()?.items &&
                        getFieldsValue()?.items[field.name]?.medication ===
                          "Yes" ? (
                          <Form.Item
                            label="Medication/Tablet Details"
                            name={[field.name, "medicationName"]}
                            rules={[
                              {
                                required: true,
                                message: "Please input Medication Details!",
                              },
                            ]}
                          >
                            <TextArea />
                          </Form.Item>
                        ) : null
                      }
                    </Form.Item>

                    <Divider orientation="left" orientationMargin="0">
                      Does the Person has the habbit of consuming below
                    </Divider>

                    <Row style={{ justifyContent: "space-between" }}>
                      <Col xs={24} sm={12} lg={10}>
                        <Form.Item
                          label="Cigarette/Bidi"
                          name={[field.name, "cigarettebidi"]}
                          rules={[
                            {
                              required: true,
                              message: "Please select Yes or No",
                            },
                          ]}
                        >
                          <Select
                            placeholder={SELECT_PLACEHOLDER}
                            options={[
                              {
                                value: "Yes",
                                label: "Yes",
                              },
                              {
                                value: "No",
                                label: "No",
                              },
                            ]}
                          />
                        </Form.Item>
                      </Col>
                      <Col xs={24} sm={12} lg={10}>
                        <Form.Item noStyle shouldUpdate>
                          {({ getFieldsValue }) =>
                            getFieldsValue()?.items &&
                            getFieldsValue()?.items[field.name]
                              ?.cigarettebidi === "Yes" ? (
                              <Form.Item
                                label="Cigarette Per Day"
                                name={[field.name, "cigarettebidicount"]}
                                rules={[
                                  {
                                    required: true,
                                    message: "Please input Cigarette count!",
                                  },
                                ]}
                              >
                                <InputNumber style={{width:'100%'}} max={100} min={1}/>
                              </Form.Item>
                            ) : null
                          }
                        </Form.Item>
                      </Col>
                    </Row>

                    <Row style={{ justifyContent: "space-between" }}>
                      <Col xs={24} sm={12} lg={10}>
                        <Form.Item
                          label="Tabacco Pouches"
                          name={[field.name, "tabaccopouches"]}
                          rules={[
                            {
                              required: true,
                              message: "Please select Yes or No",
                            },
                          ]}
                        >
                          <Select
                            placeholder={SELECT_PLACEHOLDER}
                            options={[
                              {
                                value: "Yes",
                                label: "Yes",
                              },
                              {
                                value: "No",
                                label: "No",
                              },
                            ]}
                          />
                        </Form.Item>
                      </Col>
                      <Col xs={24} sm={12} lg={10}>
                        <Form.Item noStyle shouldUpdate>
                          {({ getFieldsValue }) =>
                            getFieldsValue()?.items &&
                            getFieldsValue()?.items[field.name]
                              ?.tabaccopouches === "Yes" ? (
                              <Form.Item
                                label="Pouches Per Day"
                                name={[field.name, "tabaccopouchescount"]}
                                rules={[
                                  {
                                    required: true,
                                    message:
                                      "Please input Tabacco Pouches count!",
                                  },
                                ]}
                              >
                                <InputNumber style={{width:'100%'}} max={100} min={1}/>
                              </Form.Item>
                            ) : null
                          }
                        </Form.Item>
                      </Col>
                    </Row>

                    <Row style={{ justifyContent: "space-between" }}>
                      <Col xs={24} sm={12} lg={10}>
                        <Form.Item
                          label="Alcohol"
                          name={[field.name, "alcohol"]}
                          rules={[
                            {
                              required: true,
                              message: "Please select Yes or No",
                            },
                          ]}
                        >
                          <Select
                            placeholder={SELECT_PLACEHOLDER}
                            options={[
                              {
                                value: "Yes",
                                label: "Yes",
                              },
                              {
                                value: "No",
                                label: "No",
                              },
                            ]}
                          />
                        </Form.Item>
                      </Col>
                      <Col xs={24} sm={12} lg={10}>
                        <Form.Item noStyle shouldUpdate>
                          {({ getFieldsValue }) =>
                            getFieldsValue()?.items &&
                            getFieldsValue()?.items[field.name]?.alcohol ===
                              "Yes" ? (
                              <Form.Item
                                label="Alcohol Frequency"
                                name={[field.name, "alcoholcount"]}
                                rules={[
                                  {
                                    required: true,
                                    message:
                                      "Please select frequency of Alcohol!",
                                  },
                                ]}
                              >
                                <Select
                                  placeholder={SELECT_PLACEHOLDER}
                                  options={[
                                    {
                                      value: "Daily",
                                      label: "Daily",
                                    },
                                    {
                                      value: "Weekly",
                                      label: "Weekly",
                                    },
                                    {
                                      value: "Monthly",
                                      label: "Monthly",
                                    },
                                    {
                                      value: "Occassionally",
                                      label: "Occasionally",
                                    },
                                  ]}
                                />
                              </Form.Item>
                            ) : null
                          }
                        </Form.Item>
                      </Col>
                    </Row>

                    <Row style={{ justifyContent: "space-between" }}>
                      <Col xs={24} sm={12} lg={10}>
                        <Form.Item
                          label="Gutka Pouches"
                          name={[field.name, "gutkapouches"]}
                          rules={[
                            {
                              required: true,
                              message: "Please select Yes or No",
                            },
                          ]}
                        >
                          <Select
                            placeholder={SELECT_PLACEHOLDER}
                            options={[
                              {
                                value: "Yes",
                                label: "Yes",
                              },
                              {
                                value: "No",
                                label: "No",
                              },
                            ]}
                          />
                        </Form.Item>
                      </Col>
                      <Col xs={24} sm={12} lg={10}>
                        <Form.Item noStyle shouldUpdate>
                          {({ getFieldsValue }) =>
                            getFieldsValue()?.items &&
                            getFieldsValue()?.items[field.name]
                              ?.gutkapouches === "Yes" ? (
                              <Form.Item
                                label="Pouches Per Day"
                                name={[field.name, "gutkapouchescount"]}
                                rules={[
                                  {
                                    required: true,
                                    message:
                                      "Please input Gutka pouches count!",
                                  },
                                ]}
                              >
                                <InputNumber style={{width:'100%'}} max={100} min={1}/>
                              </Form.Item>
                            ) : null
                          }
                        </Form.Item>
                      </Col>
                    </Row>

                    <Form.Item
                      label="Drugs"
                      name={[field.name, "drugs"]}
                      rules={[
                        {
                          required: true,
                          message: "Please select Yes or No",
                        },
                      ]}
                    >
                      <Select
                        placeholder={SELECT_PLACEHOLDER}
                        options={[
                          {
                            value: "Yes",
                            label: "Yes",
                          },
                          {
                            value: "No",
                            label: "No",
                          },
                        ]}
                      />
                    </Form.Item>

                    <Form.Item noStyle shouldUpdate>
                      {({ getFieldsValue }) =>
                        getFieldsValue()?.items &&
                        getFieldsValue()?.items[field.name]?.drugs === "Yes" ? (
                          <Form.Item
                            label="Drugs Details"
                            name={[field.name, "drugsdetails"]}
                            rules={[
                              {
                                required: true,
                                message: "Please specify Details!",
                              },
                            ]}
                          >
                            <TextArea />
                          </Form.Item>
                        ) : null
                      }
                    </Form.Item>
                  </Card>
                ))}

                <Row
                  style={{
                    display: "flex",
                    justifyContent: "space-around",
                    marginTop: "5%",
                  }}
                >
                  <Space>
                    <Form.Item>
                      <Button type="dashed" onClick={() => add()}>
                        + Add Person
                      </Button>
                    </Form.Item>

                    {fields.length > 0 ? (
                      <Form.Item>
                        <Button type="primary" htmlType="submit">
                          Submit
                        </Button>
                      </Form.Item>
                    ) : null}
                  </Space>
                </Row>
              </div>
            )}
          </Form.List>
        </Form>
      </Col>
    </Row>
  );
}

export default HealthDeclaration;
