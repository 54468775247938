import React from 'react';
import { Form, Input, DatePicker, Button } from 'antd';
// import 'antd/dist/antd.css'; // Import Ant Design styles

const ClaimForm = () => {
  const onFinish = (values) => {
    console.log('Form submitted:', values);
    // Handle form submission logic (e.g., API request to submit the claim)
  };

  return (
    <Form
      name="claim-form"
      onFinish={onFinish}
      labelCol={{ span: 6 }}
      wrapperCol={{ span: 12 }}
      initialValues={{ remember: true }}
    >
      {/* Personal Information */}
      <Form.Item
        label="Name"
        name="name"
        rules={[{ required: true, message: 'Please enter your name' }]}
      >
        <Input />
      </Form.Item>

      <Form.Item
        label="Contact Number"
        name="contactNumber"
        rules={[{ required: true, message: 'Please enter your contact number' }]}
      >
        <Input />
      </Form.Item>

      {/* Incident Details */}
      <Form.Item
        label="Incident Date"
        name="incidentDate"
        rules={[{ required: true, message: 'Please select the incident date' }]}
      >
        <DatePicker />
      </Form.Item>

      <Form.Item
        label="Incident Location"
        name="incidentLocation"
        rules={[{ required: true, message: 'Please enter the incident location' }]}
      >
        <Input />
      </Form.Item>

      <Form.Item
        label="Description of Incident"
        name="incidentDescription"
        rules={[{ required: true, message: 'Please describe the incident' }]}
      >
        <Input.TextArea />
      </Form.Item>

      {/* Other Information */}
      <Form.Item
        label="Witness Information"
        name="witnessInformation"
      >
        <Input />
      </Form.Item>

      {/* Submit Button */}
      <Form.Item wrapperCol={{ offset: 6, span: 12 }}>
        <Button type="primary" htmlType="submit">
          Submit Claim
        </Button>
      </Form.Item>
    </Form>
  );
};

export default ClaimForm;
