import React, { useState } from "react";
import {
  Modal,
  Form,
  Input,
  Button,
  Select,
  DatePicker,
  InputNumber,
} from "antd";
import { useDispatch} from "react-redux";
import { message } from "antd";
import dayjs from "dayjs";
import customParseFormat from "dayjs/plugin/customParseFormat";
import LoadingSpinner from "./Loader";
import { addMemberData, fetchMemberList } from "../store/reducers/customerReducer";

dayjs.extend(customParseFormat);
const dateFormat = "DD-MMMM-YYYY";
const today = dayjs().format(dateFormat);

const AddFamilyMemberForm = ({ visible, setVisible, customer }) => {
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const [messageApi, contextHolder] = message.useMessage();
  const dispatch = useDispatch();
  const [memberDob, setMemberDob] = useState("");

  const memberRelationData = ["Husband", "Wife", "Father", "Mother", "Brother", "Sister", "Son", "Daughter"];

  const handleOk = async () => {
    try {
      setLoading(true);
      setVisible(false);
      const formData = await form.validateFields();
      formData["customerId"] = customer?.customerId;
      formData["memberDOB"] = memberDob;
      dispatch(addMemberData(formData))
        .then((response) => {
          if (response?.payload?.success === true) {
            dispatch(fetchMemberList(formData)).then((response) => {
              if (response?.payload?.success === true) {
                setLoading(false);
                message.success("Member added Successfully");
              } else {
                setLoading(false);
              }
            });
          } else {
            message.warning("Error! Please try again later");
            setLoading(false);
          }
        })
        .catch((err) => {
          setLoading(false);
          messageApi.open({
            type: "error",
            content: "Unable to add Member. Please try again",
            duration: 5,
          });
        });
      form.resetFields();
    } catch (error) {
      console.error("Error adding Member information:", error);
      setLoading(false);
    }
  };

  const handleCancel = () => {
    setVisible(false);
  };

  return (
    <>
      {contextHolder}
      <LoadingSpinner loading={loading} />

      <Modal
        title="Add Member Form"
        visible={visible}
        onOk={handleOk}
        onCancel={handleCancel}
        footer={[
          <Button key="cancel" onClick={handleCancel}>
            Cancel
          </Button>,
          <Button
            key="submit"
            type="primary"
            loading={loading}
            onClick={handleOk}
          >
            Save
          </Button>,
        ]}
      >
        <Form form={form} layout="vertical">
          <Form.Item
            name="memberFirstName"
            label="First Name"
            rules={[
              { required: true, message: "Please enter first name" },
              {
                pattern: /^[A-Za-z\s.-]*$/,
                message:
                  "Please enter a valid First Name (no special characters)",
              },
            ]}
          >
            <Input placeholder="Please enter first name" />
          </Form.Item>

          <Form.Item
            name="memberLastName"
            label="Last Name"
            rules={[
              { required: true, message: "Please enter last name" },
              {
                pattern: /^[A-Za-z\s.-]*$/,
                message:
                  "Please enter a valid Last Name (no special characters)",
              },
            ]}
          >
            <Input placeholder="Please enter last name" />
          </Form.Item>

          <Form.Item
            label="Member Date of Birth"
            name="memberDOB"
            rules={[
              {
                required: true,
                message: "Please select Member Date of Birth!",
              },
            ]}
          >
            <DatePicker
              onChange={(date, dateString) => {
                setMemberDob(dateString);
              }}
              style={{
                width: "100%",
              }}
              format={dateFormat}
              maxDate={dayjs(today, dateFormat)}
            />
          </Form.Item>

          <Form.Item
            name="memberPhoneNumber"
            label="Phone Number"
            rules={[
              {
                pattern: /^[0-9]{10}$/,
                message: "Phone Number should contain only 10 digits",
              },
            ]}
          >
            <InputNumber
              placeholder="Please enter Phone number"
              style={{ width: "100%" }}
              minLength={10}
              maxLength={10}
            />
          </Form.Item>

          <Form.Item
            name="memberRelation"
            label="Relation"
            rules={[
              {
                required: true,
                message: "Please select Relation",
              },
            ]}
          >
            <Select
              placeholder="Please select Relation"
              options={memberRelationData.map((relationData) => ({
                label: relationData,
                value: relationData,
              }))}
            />
          </Form.Item>
        </Form>
      </Modal>
    </>
  );
};

export default AddFamilyMemberForm;
