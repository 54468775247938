import API from "../store/utils/apiEndPoint";

export const fetchTeamMembersDataAPIHelper = async (payload) => {
  try {
    const config = {
      headers: {
        accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${payload?.token}`,
      },
    };
    let response = await API.get(
      `team-members/users/${payload?.organisation_id}`,
      config
    );
    return response;
  } catch (error) {
    return error?.response;
  }
};

export const fetchorganizationGroupsListAPIHelper = async (payload) => {
  try {
    const config = {
      headers: {
        accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${payload?.token}`,
      },
    };
    let response = await API.get(
      `team-members/groups/${payload?.organisation_id}`,
      config
    );
    return response;
  } catch (error) {
    return error?.response;
  }
};
