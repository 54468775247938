import { Button, Flex, Modal, Table, Typography, Tooltip, message } from "antd";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  fetchPolicyList,
  deletePolicyData,
} from "../store/reducers/customerReducer";
import AddExistingPolicyForm from "./AddExistingPolicyForm";
import LoadingSpinner from "./Loader";
import {
  DeleteTwoTone,
  EyeTwoTone,
  ExclamationCircleFilled,
} from "@ant-design/icons";
const { confirm } = Modal;

const { Text } = Typography;

// Component for displaying Existing Policies table
const ExistingPolicyTable = ({ customer }) => {
  const [isExistingPolicyFormVisible, setExistingPolicyFormVisible] =
    useState(false);
  const [uploading, setUploading] = useState(false);
  const dispatch = useDispatch();
  const policyList = useSelector((state) => state?.customer?.policyList);

  const [pdfUrl, setpdfUrl] = useState("");
  const [isModalOpen, setIsModalOpen] = useState(false);

  const showModal = (url) => {
    setpdfUrl(url);
    setIsModalOpen(true);
  };

  const handleOk = () => {
    setIsModalOpen(false);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };

  const showDeleteConfirm = (record) => {
    confirm({
      title: "Are you sure delete this Policy?",
      icon: <ExclamationCircleFilled />,
      content: "Deleted policies cannot be restored.",
      okText: "Yes",
      okType: "danger",
      cancelText: "No",
      onOk() {
        setUploading(true);
        const data = { ...record, customerId: customer?.customerId };
        dispatch(deletePolicyData(data)).then((response) => {
          if (response?.payload?.success === true) {
            dispatch(fetchPolicyList(data)).then((result) => {
              if (result?.payload?.success === true) {
                setUploading(false);
                message.success("Policy Deleted Successfully");
              } else {
                setUploading(false);
                message.success("Policy Deleted Successfully");
              }
            });
          } else {
            setUploading(false);
            message.error("Something went wrong, please try again later");
          }
        });
      },
      onCancel() {
        // console.log("Cancel");
      },
    });
  };

  const columns = [
    {
      title: "Policy Type",
      dataIndex: "policyType",
      key: "policyType",
      render: (_, record) => <>{record?.policyType}</>,
    },
    {
      title: "Expiry Date",
      dataIndex: "expiryDate",
      key: "expiryDate",
      render: (_, record) => {
        return <>{record?.expiryDate}</>;
      },
    },
    {
      title: "Action",
      dataIndex: "policyUrl",
      key: "policyUrl",
      render: (_, record) => (
        <Flex gap={16}>
          <Tooltip title={"View Policy"}>
            <Button onClick={() => showModal(record?.policyUrl)}>
              <EyeTwoTone />
            </Button>
          </Tooltip>
          <Tooltip title={"Delete Policy"}>
            <Button onClick={() => showDeleteConfirm(record)}>
              <DeleteTwoTone twoToneColor="#dc3545" />
            </Button>
          </Tooltip>
        </Flex>
      ),
    },
  ];

  useEffect(() => {
    setUploading(true);
    const data = { customerId: customer?.customerId };
    dispatch(fetchPolicyList(data)).then((response) => {
      if (response?.payload?.success === true) {
        setUploading(false);
      } else {
        setUploading(false);
      }
    });
  }, [customer, dispatch]);

  return (
    <>
      <AddExistingPolicyForm
        visible={isExistingPolicyFormVisible}
        setVisible={setExistingPolicyFormVisible}
        customer={customer}
      />

      <LoadingSpinner loading={uploading} />

      <Modal
        title="Your Policy"
        open={isModalOpen}
        onOk={handleOk}
        onCancel={handleCancel}
        width="80%"
        style={{ top: 5 }}
      >
        <div style={{ height: "80vh" }}>
          <iframe
            title="PDF Viewer"
            src={pdfUrl}
            width="100%"
            height="100%"
            frameBorder="0"
          />
        </div>
      </Modal>

      <Table
        columns={columns}
        dataSource={policyList}
        pagination={false}
        scroll={{
          y: 170,
        }}
        title={() => {
          return (
            <Flex dir="row" justify={"space-between"}>
              <Text style={{ fontWeight: 600, fontSize: 16 }}>Policies</Text>
              <Button
                type="primary"
                onClick={() => {
                  setExistingPolicyFormVisible(true);
                }}
              >
                Add An Existing Policy
              </Button>
            </Flex>
          );
        }}
      />
    </>
  );
};

export default ExistingPolicyTable;
