import React from "react";
import { Layout, Flex, Card, Tabs} from "antd";
import { useSelector } from "react-redux";
import CustomerInformation from "../components/CustomerInformation";
import QuoteComparison from "../components/QuoteComparison";
import AgentViewHealthDeclarationForm from "../components/AgentViewHealthDeclarationForm";

const { Content } = Layout;

function NewQuote() {
  const activeCustomer = useSelector((state) => state?.auth?.activeCustomer);
  const tabList = ["Quote Comparison", "Health Declaration"];

  return (
    <>
      <Layout>
        <Content style={{ padding: "20px" }}>
          <Flex gutter={[16, 16]} flex={""} style={{ flexDirection: "column" }}>
            <CustomerInformation customer={activeCustomer} />
            <Tabs
              type="card"
              items={tabList.map((tabHeader) => {
                return {
                  label: tabHeader,
                  key: tabHeader,
                  children: tabHeader === "Quote Comparison" ? (
                    <Card title="New Quotes Form">
                      <QuoteComparison />
                    </Card>
                  ) : (
                    <Card title={'Health Declaration Form'}>
                      <AgentViewHealthDeclarationForm />
                    </Card>
                  )
                };
              })}
            />
          </Flex>
        </Content>
      </Layout>
    </>
  );
}

export default NewQuote;
