import { db } from "../config/firebaseConfig";
import {
  collection,
  addDoc,
  query,
  getDocs,
  doc,
  getDoc,
  where,
  limit,
  orderBy,
  updateDoc,
} from "firebase/firestore";
import API from "../store/utils/apiEndPoint";

const KOKORO_CUSTOMERS = "/kokoro-customers";

// Save Customer Health Form
export const addCustomerDataAPIHelper = async (payload) => {
  try {
    const config = {
      headers: {
        accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${payload?.token}`,
      },
    };
    let response = await API.put(
      `/customer-accounts/${payload?.declarationToken}/declaration/${payload?.quoteId}`,
      {
        addedByUserId: payload?.userId,
        groupId: payload?.groupId,
        isFinalSubmission: payload?.isFinalSubmission,
        declarationDetails: payload?.updatedCustomerDetails,
        declarationId:payload?.declarationId,
      },
      config
    );
    return response;
  } catch (error) {
    return error?.response;
  }
};

// Fetch Health Declaration Details
export const fetchCustomerDataAPIHelper = async (payload) => {
  try {
    const config = {
      headers: {
        accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${payload?.token}`,
      },
    };
    let response = await API.get(
      `/customer-accounts/${payload?.declarationToken}/declaration/${payload?.quoteId}`,
      config
    );
    return response;
  } catch (error) {
    return error?.response;
  }
};

// Fetch Customer profile
export const fetchCustomerProfileAPIHelper = async (data) => {
  try {
    const customerDocRef = doc(db, KOKORO_CUSTOMERS, data.customerId);
    const docSnap = await getDoc(customerDocRef);
    if (docSnap.exists()) {
      return {
        success: true,
        message: "Details saved Successfully",
        customerProfile: docSnap.data(),
      };
    } else {
      return { success: false, message: "No such Document" };
    }
  } catch (error) {
    throw error;
  }
};

// add Policy
export const addPolicyDataAPIHelper = async (data) => {
  try {
    const customerDocRef = doc(db, KOKORO_CUSTOMERS, data?.customerId);
    const docSnap = await getDoc(customerDocRef);

    if (docSnap.exists()) {
      const docRef = await addDoc(collection(customerDocRef, "Policies"), {
        policyUrl: data?.policyUrl,
        policyType: data?.policyType,
        createdAt: new Date(),
        isDeleted: false,
        lastModifiedDate: new Date(),
      });
      return { success: true, message: "Details saved Successfully" };
    } else {
      return { success: false, message: "No such Document" };
    }
  } catch (error) {
    throw error;
  }
};

// Fetch Policy
export const fetchPolicyListAPIHelper = async (data) => {
  try {
    const customerDocRef = doc(db, KOKORO_CUSTOMERS, data?.customerId);
    const docSnap = await getDoc(customerDocRef);
    if (docSnap.exists()) {
      var customersRef = collection(customerDocRef, "Policies");
      const q = query(
        customersRef,
        where("isDeleted", "==", false),
        orderBy("policyType")
      );
      const querySnapshot = await getDocs(q);
      const policyList = [];
      querySnapshot.forEach((doc) => {
        data = doc.data();
        data["policyId"] = doc.id;
        policyList.push(data);
      });
      return {
        success: true,
        policyList: policyList,
        message: "Details fetched successfully",
      };
    } else {
      return { success: false, message: "No such Document" };
    }
  } catch (error) {
    throw error;
  }
};

// To Delete Policy
export const deletePolicyAPIHelper = async (data) => {
  try {
    const customerDocRef = doc(
      db,
      KOKORO_CUSTOMERS,
      data?.customerId,
      "Policies",
      data?.policyId
    );
    const docSnap = await getDoc(customerDocRef);
    if (docSnap.exists()) {
      await updateDoc(customerDocRef, {
        isDeleted: true,
        lastModifiedDate: new Date(),
      });
      return { success: true, message: "Policy updated Successfully" };
    } else {
      return { success: false, message: "Policy not Found" };
    }
  } catch (error) {
    throw error;
  }
};

// add member
export const addMemberDataAPIHelper = async (data) => {
  try {
    const customerDocRef = doc(db, KOKORO_CUSTOMERS, data?.customerId);
    const docSnap = await getDoc(customerDocRef);

    if (docSnap.exists()) {
      const docRef = await addDoc(collection(customerDocRef, "Members"), {
        memberDetails: data,
        createdAt: new Date(),
        isDeleted: false,
        lastModifiedDate: new Date(),
      });
      return { success: true, message: "Member added Successfully" };
    } else {
      return { success: false, message: "Customer Not Found" };
    }
  } catch (error) {
    throw error;
  }
};

// fetch member
export const fetchMemberListAPIHelper = async (data) => {
  try {
    const customerDocRef = doc(db, KOKORO_CUSTOMERS, data?.customerId);
    const docSnap = await getDoc(customerDocRef);
    if (docSnap.exists()) {
      var customersRef = collection(customerDocRef, "Members");
      const q = query(
        customersRef,
        where("isDeleted", "==", false),
        orderBy("createdAt")
      );
      const querySnapshot = await getDocs(q);
      const memberList = [];
      querySnapshot.forEach((doc) => {
        data = doc.data();
        data["memberId"] = doc.id;
        memberList.push(data);
      });
      return {
        success: true,
        memberList: memberList,
        message: "Details fetched successfully",
      };
    } else {
      return { success: false, message: "No such Document" };
    }
  } catch (error) {
    throw error;
  }
};

// To Delete Member
export const deleteMemberDataAPIHelper = async (data) => {
  try {
    const customerDocRef = doc(
      db,
      KOKORO_CUSTOMERS,
      data?.customerId,
      "Members",
      data?.memberId
    );
    const docSnap = await getDoc(customerDocRef);
    if (docSnap.exists()) {
      await updateDoc(customerDocRef, {
        isDeleted: true,
        lastModifiedDate: new Date(),
      });
      return { success: true, message: "Document updated Successfully" };
    } else {
      return { success: false, message: "Document not Found" };
    }
  } catch (error) {
    throw error;
  }
};

// add Document
export const addDocumentDataAPIHelper = async (data) => {
  try {
    const customerDocRef = doc(db, KOKORO_CUSTOMERS, data?.customerId);
    const docSnap = await getDoc(customerDocRef);

    if (docSnap.exists()) {
      const docRef = await addDoc(collection(customerDocRef, "Documents"), {
        documentUrl: data?.documentUrl,
        documentType: data?.documentType,
        uploadedDate: new Date(),
        isDeleted: false,
        lastModifiedDate: new Date(),
      });
      return { success: true, message: "Details saved Successfully" };
    } else {
      return { success: false, message: "No such Document" };
    }
  } catch (error) {
    throw error;
  }
};

// fetch Document
export const fetchDocsListAPIHelper = async (data) => {
  try {
    const customerDocRef = doc(db, KOKORO_CUSTOMERS, data?.customerId);
    const docSnap = await getDoc(customerDocRef);
    if (docSnap.exists()) {
      var customersRef = collection(customerDocRef, "Documents");
      const q = query(
        customersRef,
        where("isDeleted", "==", false),
        orderBy("documentType")
      );
      const querySnapshot = await getDocs(q);
      const documentList = [];
      querySnapshot.forEach((doc) => {
        data = doc.data();
        data["documentId"] = doc.id;
        documentList.push(data);
      });
      return {
        success: true,
        documentList: documentList,
        message: "Details fetched successfully",
      };
    } else {
      return { success: false, message: "No such Document" };
    }
  } catch (error) {
    throw error;
  }
};

// To Delete Document
export const deleteDocumentAPIHelper = async (data) => {
  try {
    const customerDocRef = doc(
      db,
      KOKORO_CUSTOMERS,
      data?.customerId,
      "Documents",
      data?.documentId
    );
    const docSnap = await getDoc(customerDocRef);
    if (docSnap.exists()) {
      await updateDoc(customerDocRef, {
        isDeleted: true,
        lastModifiedDate: new Date(),
      });
      return { success: true, message: "Document updated Successfully" };
    } else {
      return { success: false, message: "Document not Found" };
    }
  } catch (error) {
    throw error;
  }
};
