import React from "react";
import { Layout, Flex, Tabs } from "antd";
import { useSelector } from "react-redux";
import QuotesTable from "../components/QuotesTable";
import CustomerInformation from "../components/CustomerInformation";
import ExistingPolicyTable from "../components/ExistingPolicyTable";
import FamilyMemberTable from "../components/FamilyMemberTable";
import CustomerDocsTable from "../components/CustomerDocsTable";

const { Content } = Layout;

function CustomerAccount() {
  const activeCustomer = useSelector((state) => state?.auth?.activeCustomer);
  const tabList = ["Quotes", "Policies", "Docs", "Members"];

  return (
    <>
      <Layout>
        <Content style={{ padding: "20px" }}>
          <Flex gutter={[16, 16]} flex={""} style={{ flexDirection: "column" }}>
            <CustomerInformation customer={activeCustomer} />
            <Tabs
              type="card"
              items={tabList.map((tabHeader) => {
                return {
                  label: tabHeader,
                  key: tabHeader,
                  children:
                    tabHeader === "Quotes" ? (
                      <QuotesTable customer={activeCustomer} />
                    ) : tabHeader === "Policies" ? (
                      <ExistingPolicyTable customer={activeCustomer} />
                    ) : tabHeader === "Members" ? (
                      <FamilyMemberTable customer={activeCustomer} />
                    ) : (
                      <CustomerDocsTable customer={activeCustomer} />
                    ),
                };
              })}
            />
          </Flex>
        </Content>
      </Layout>
    </>
  );
}

export default CustomerAccount;
