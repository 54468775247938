import React from "react";
import { Typography, Card } from "antd";
const { Text } = Typography;

function AppFooter() {
  return (
    <Card
      style={{ width: "100%", border: "none" }}
      styles={{ body: { padding: 0, overflow: "hidden" } }}
    >
      <Typography style={{ textAlign: "center", margin: "2vh" }}>
        <Text>Copyright ©2024 </Text>
        <br />
        <Text style={{ width: "100%" }}>
          KOKORO INSURANCE BROKERS PRIVATE LIMITED
        </Text>
      </Typography>
    </Card>
  );
}

export default AppFooter;
