import React from 'react';
import { Form, Input, DatePicker, Upload, Button, message } from 'antd';
import { UploadOutlined } from '@ant-design/icons';

const { TextArea } = Input;

const IncidentReportForm = () => {
  const onFinish = (values) => {
    console.log('Form submitted:', values);
    // Handle form submission logic (e.g., API request to submit incident report with files)
    message.success('Incident report submitted successfully!');
  };

  const normFile = (e) => {
    if (Array.isArray(e)) {
      return e;
    }
    return e && e.fileList;
  };

  return (
    <Form
      name="incident-report-form"
      onFinish={onFinish}
      labelCol={{ span: 6 }}
      wrapperCol={{ span: 12 }}
      initialValues={{ remember: true }}
    >
      {/* Incident Details */}
      <Form.Item
        label="Type of Incident"
        name="incidentType"
        rules={[{ required: true, message: 'Please specify the type of incident' }]}
      >
        <Input />
      </Form.Item>

      <Form.Item
        label="Date of Incident"
        name="incidentDate"
        rules={[{ required: true, message: 'Please select the date of the incident' }]}
      >
        <DatePicker />
      </Form.Item>

      <Form.Item
        label="Location of Incident"
        name="incidentLocation"
        rules={[{ required: true, message: 'Please specify the location of the incident' }]}
      >
        <Input />
      </Form.Item>

      <Form.Item
        label="Description of Incident"
        name="incidentDescription"
        rules={[{ required: true, message: 'Please describe the incident' }]}
      >
        <TextArea rows={4} />
      </Form.Item>

      {/* Upload Police Report or Documentation */}
      <Form.Item
        label="Upload Police Report or Documentation"
        name="incidentFiles"
        valuePropName="fileList"
        getValueFromEvent={normFile}
        extra="Please upload any relevant files (e.g., police report, photographs)."
      >
        <Upload name="incidentFiles" multiple={true}>
          <Button icon={<UploadOutlined />}>Click to Upload</Button>
        </Upload>
      </Form.Item>

      {/* Submit Button */}
      <Form.Item wrapperCol={{ offset: 6, span: 12 }}>
        <Button type="primary" htmlType="submit">
          Submit Incident Report
        </Button>
      </Form.Item>
    </Form>
  );
};

export default IncidentReportForm;
