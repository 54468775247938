import React, { useState } from "react";
import { Modal, Form, Input, Button, Select, DatePicker } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { message } from "antd";
import {
  createQuoteAndDeclaration,
  fetchCustomerQuoteList,
} from "../store/reducers/authReducer";
import dayjs from "dayjs";
import customParseFormat from "dayjs/plugin/customParseFormat";
import LoadingSpinner from "./Loader";
import { useNavigate } from "react-router-dom";

dayjs.extend(customParseFormat);
const dateFormat = "DD-MMMM-YYYY";
const today = dayjs().format(dateFormat);
const oneYearAgo = dayjs().subtract(2, "year").format(dateFormat);

const AddQuoteForm = ({ visible, setVisible, customer }) => {
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const [messageApi, contextHolder] = message.useMessage();
  const agentInfo = useSelector((state) => state?.auth);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [proposalDate, setProposalDate] = useState("");

  const insuranceProductData = [
    "Health Insurance",
    "Motor Insurance",
    "Corporate Insurance",
    "Other Insurance",
  ];
  const agentBQPData = ["Vijaya Kumar Govada", "Rakesh Nagar", "Pradnya More"];
  const familyCompositionData = [
    "1 Adult + 1 Child",
    "1 Adult + 0 Child",
    "1 Adult + 2 Child",
    "1 Adult + 3 Child",
    "1 Adult + 4 Child",
    "2 Adult + 0 Child",
    "2 Adult + 1 Child",
    "2 Adult + 2 Child",
    "2 Adult + 3 Child",
    "2 Adult + 4 Child",
    "3 Adult + 0 Child",
    "3 Adult + 1 Child",
    "4 Adult + 0 Child",
    "4 Adult + 1 Child",
    "4 Adult + 2 Child",
  ];

  const handleOk = async () => {
    try {
      setLoading(true);
      setVisible(false);
      const formData = await form.validateFields();
      formData["addedByUserId"] = agentInfo?.auth_profile?.user_id;
      formData["token"] = agentInfo?.token;
      formData["groupId"] = customer?.groupId;
      formData["customerId"] = customer?.id;
      formData["proposalDate"] = proposalDate;
      formData["status"] = "Pending";

      dispatch(createQuoteAndDeclaration(formData))
        .then((response) => {
          if (response?.payload?.data?.success === true) {
            const data = { customerId: customer?.id, token: agentInfo?.token};
            dispatch(fetchCustomerQuoteList(data)).then((resp) => {
              setLoading(false);
              message.success("Quote created Successfully");
              navigate({
                pathname: "/customer-account/new-quote",
              });
            });
          } else {
            message.warning("Error! Please try again later");
            setLoading(false);
          }
        })
        .catch((err) => {
          setLoading(false);
          messageApi.open({
            type: "error",
            content: "Unable to create Quote. Please try again",
            duration: 5,
          });
        });
      form.resetFields();
    } catch (error) {
      console.error("Error saving customer information:", error);
      setLoading(false);
    }
  };

  const handleCancel = () => {
    setVisible(false);
  };

  return (
    <>
      {contextHolder}
      <LoadingSpinner loading={loading} />

      <Modal
        title="Quote Information"
        visible={visible}
        onOk={handleOk}
        onCancel={handleCancel}
        footer={[
          <Button key="cancel" onClick={handleCancel}>
            Cancel
          </Button>,
          <Button
            key="submit"
            type="primary"
            loading={loading}
            onClick={handleOk}
          >
            Save
          </Button>,
        ]}
      >
        <Form form={form} layout="vertical">
          <Form.Item
            name="insuranceProductNeed"
            label="Insurance Product Need"
            rules={[
              {
                required: true,
                message: "Please select your insurance product need",
              },
            ]}
          >
            <Select
              placeholder="Please select insurance product need"
              options={insuranceProductData.map((insuranceProduct) => ({
                label: insuranceProduct,
                value: insuranceProduct,
              }))}
            />
          </Form.Item>

          <Form.Item
            name="familyComposition"
            label="Family Composition"
            rules={[
              {
                required: true,
                message: "Please select Family Composition",
              },
            ]}
          >
            <Select
              placeholder="Please select family composition"
              options={familyCompositionData.map((familyCompo) => ({
                label: familyCompo,
                value: familyCompo,
              }))}
            />
          </Form.Item>

          <Form.Item
            label="No of Insured"
            name="NumberOfInsured"
            rules={[
              {
                required: true,
                message: "Please enter your No. of Insured!",
              },
            ]}
          >
            <Input
              type="number"
              placeholder="Please enter no. of Insured"
              min={1}
              max={15}
            />
          </Form.Item>

          <Form.Item
            label="Age of Members (Years)"
            name="proposerFamilyMembersAge"
            rules={[
              {
                required: true,
                message: "Please enter Age of Members (in Years)",
              },
            ]}
          >
            <Input
              type="text"
              placeholder="Please enter Age of Members (in Years)"
            />
          </Form.Item>

          <Form.Item
            label="Is Proposer included"
            name="Proposerincluded"
            rules={[
              {
                required: true,
                message: "Please select your Is Proposer included!",
              },
            ]}
          >
            <Select
              placeholder="Please select any one"
              options={[
                {
                  value: "Yes",
                  label: "Yes",
                },
                {
                  value: "No",
                  label: "No",
                },
              ]}
            />
          </Form.Item>

          <Form.Item
            name="agentBQP"
            label="BQP"
            rules={[
              {
                required: true,
                message: "Please select BQP",
              },
            ]}
          >
            <Select
              placeholder="Please select BQP"
              options={agentBQPData.map((bqpName) => ({
                label: bqpName,
                value: bqpName,
              }))}
            />
          </Form.Item>

          <Form.Item
            name="proposalDate"
            label="Date Of Quote"
            rules={[
              {
                required: true,
                message: "Please select Date Of Quote!",
              },
            ]}
          >
            <DatePicker
              onChange={(date, dateString) => {
                setProposalDate(dateString);
              }}
              style={{ width: "100%" }}
              format={dateFormat}
              minDate={dayjs(oneYearAgo, dateFormat)}
              maxDate={dayjs(today, dateFormat)}
            />
          </Form.Item>

          <Form.Item
            label="Location"
            name="quoteLocation"
            rules={[
              {
                required: true,
                message: "Please enter Location",
              },
            ]}
          >
            <Input type="text" placeholder="Enter Location" />
          </Form.Item>
        </Form>
      </Modal>
    </>
  );
};

export default AddQuoteForm;
