import React, { useEffect, useState } from "react";
import {
  Steps,
  Button,
  Form,
  Input,
  Row,
  Col,
  Card,
  DatePicker,
  Divider,
  Select,
  Space,
  message,
  InputNumber,
  Flex,
  Typography,
  Result,
  Alert,
} from "antd";
import { HeartOutlined } from "@ant-design/icons";
import dayjs from "dayjs";
import customParseFormat from "dayjs/plugin/customParseFormat";
import {
  fetchCustomerData,
  fetchCustomerProfile,
  saveCustomerData,
} from "../store/reducers/customerReducer";
import { useDispatch, useSelector } from "react-redux";
import { useSearchParams, useNavigate } from "react-router-dom";
import LoadingSpinner from "../components/Loader";
import TextArea from "antd/es/input/TextArea";

import "../App.css"; // Import CSS file for styles
import Marquee from "react-fast-marquee";

const { Title, Text } = Typography;
dayjs.extend(customParseFormat);
const dateFormat = "DD-MMMM-YYYY";
// const today = dayjs().format(dateFormat);
const tenYearAgo = dayjs().subtract(10, "year").format(dateFormat);
const oneDayAgo = dayjs().subtract(2, "day").format(dateFormat);

const { Step } = Steps;

const BlinkingWarningIcon = () => {
  return (
    <div className="blinking-icon">
      <HeartOutlined type="warning" style={{ fontSize: 20, color: "red" }} />
    </div>
  );
};

const NewHealthDeclarationForm = () => {
  const [current, setCurrent] = useState(0);
  const [form] = Form.useForm();
  const SELECT_PLACEHOLDER = "Select any one";
  const dispatch = useDispatch();
  const [searchParams] = useSearchParams();
  const declarationToken = searchParams.get("declarationToken");
  const quoteId = searchParams.get("quoteId");
  const [uploading, setUploading] = useState(null);
  const navigate = useNavigate();
  const customerDetails = useSelector(
    (state) => state?.customer?.customerDetails?.declarationValue
    ? state?.customer?.customerDetails?.declarationValue
    : undefined
  );
  const declarationId = useSelector((state) => state?.customer?.customerDetails?.id)

  const handlePanNumberChange = (e) => {
    const { value } = e.target;
    e.target.value = value.toUpperCase();
  };

  let sections = [
    {
      slug: "contact_information",
      title: "Contact Information",
      content: (
        <Card
          // title={`Health Declaration Form`}
          key={"Client_Information_Sheet "}
          style={{ marginTop: "5%" }}
        >
          <Divider orientation="left" orientationMargin="0">
            Contact Information
          </Divider>

          <Form.Item
            label="Proposer Name"
            name="proposerName"
            rules={[
              {
                required: true,
                message: "Please enter your Proposer Name!",
              },
              {
                pattern: /^[A-Za-z\s.-]*$/,
                message:
                  "Please enter a valid Proposer Name (no special characters)",
              },
            ]}
          >
            <Input />
          </Form.Item>

          <Form.Item
            label="Email ID"
            name="proposerEmail"
            rules={[
              {
                type: "email",
                required: true,
                message: "Please enter your valid Email ID!",
              },
            ]}
          >
            <Input />
          </Form.Item>

          <Form.Item
            label="Mobile Number"
            name="proposerPhone"
            rules={[
              {
                required: true,
                message: "Please enter your Mobile Number!",
              },
              {
                pattern: /^[0-9]{10}$/,
                message: "Mobile Number should contain only 10 digits",
              },
            ]}
          >
            <InputNumber
              style={{ width: "100%" }}
              minLength={10}
              maxLength={10}
            />
          </Form.Item>

          <Form.Item
            label="Alternate Mobile Number"
            name="AlternateMobileNumber"
            rules={[
              {
                required: true,
                message: "Please enter your Alternate Mobile Number!",
              },
              {
                pattern: /^[0-9]{10}$/,
                message: "Phone Number should contain only 10 digits",
              },
            ]}
          >
            <InputNumber
              style={{ width: "100%" }}
              minLength={10}
              maxLength={10}
            />
          </Form.Item>

          <Form.Item
            label="Full Address"
            name="FullAddress"
            rules={[
              {
                required: true,
                message: "Please enter your Full Address!",
              },
            ]}
          >
            <TextArea />
          </Form.Item>
        </Card>
      ),
    },
    {
      slug: "insurance_needs",
      title: "Insurance Needs",
      content: (
        <Card
          //title={`Health Declaration Form`}
          key={"Client_Information_Sheet "}
          style={{ marginTop: "5%" }}
        >
          <Alert
            banner
            message={
              <Marquee pauseOnHover gradient={false}>
                This is a View only Page. Please save and proceed further.
              </Marquee>
            }
          />

          <Divider orientation="left" orientationMargin="0">
            Insurance Needs
          </Divider>

          <Form.Item
            label="No of Insured"
            name="NumberOfInsured"
            rules={[
              {
                required: true,
                message: "Please enter your No. of Insured!",
              },
            ]}
          >
            <Input
              type="number"
              disabled={true}
              min={1}
              max={15}
              onChange={(e) => handleInsuredCount(e.target.value)}
            />
          </Form.Item>

          <Form.Item
            label="Is Proposer included"
            name="Proposerincluded"
            rules={[
              {
                required: true,
                message: "Please select your Is Proposer included!",
              },
            ]}
          >
            <Select
              disabled={true}
              placeholder={SELECT_PLACEHOLDER}
              options={[
                {
                  value: "Yes",
                  label: "Yes",
                },
                {
                  value: "No",
                  label: "No",
                },
              ]}
            />
          </Form.Item>
        </Card>
      ),
    },
    {
      slug: "additional_information",
      title: "Additional Information",
      content: (
        <Card
          // title={`Health Declaration Form`}
          key={"Client_Information_Sheet "}
          style={{ marginTop: "5%" }}
        >
          <Divider orientation="left" orientationMargin="0">
            Additional Information
          </Divider>

          <Form.Item
            label="Proposer Date of Birth"
            name="proposerDOB"
            rules={[
              {
                required: true,
                message: "Please select Proposer Date of Birth!",
              },
            ]}
          >
            <DatePicker
              style={{
                width: "100%",
              }}
              format={dateFormat}
              maxDate={dayjs(tenYearAgo, dateFormat)}
            />
          </Form.Item>

          <Form.Item
            label="Gender"
            name="gender"
            rules={[
              {
                required: true,
                message: "Please select your Gender!",
              },
            ]}
          >
            <Select
              placeholder={SELECT_PLACEHOLDER}
              options={[
                {
                  value: "Male",
                  label: "Male",
                },
                {
                  value: "Female",
                  label: "Female",
                },
                {
                  value: "Other",
                  label: "Other",
                },
              ]}
            />
          </Form.Item>

          <Form.Item
            label="Marital Status"
            name="maritalstatus"
            rules={[
              {
                required: true,
                message: "Please enter your Marital Status!",
              },
            ]}
          >
            <Select
              placeholder={SELECT_PLACEHOLDER}
              options={[
                {
                  value: "Married",
                  label: "Married",
                },
                {
                  value: "Unmarried",
                  label: "Unmarried",
                },
                {
                  value: "Other",
                  label: "Other",
                },
              ]}
            />
          </Form.Item>
        </Card>
      ),
    },
    {
      slug: "occupation",
      title: "Occupation",
      content: (
        <Card
          // title={`Health Declaration Form`}
          key={"Client_Information_Sheet "}
          style={{ marginTop: "5%" }}
        >
          <Divider orientation="left" orientationMargin="0">
            Occupation
          </Divider>

          <Form.Item
            label="Occupation"
            name="occupation"
            rules={[
              {
                required: true,
                message: "Please enter your Occupation!",
              },
              {
                pattern: /^[A-Za-z\s.-]*$/,
                message:
                  "Please enter a valid Occupation (no special characters)",
              },
            ]}
          >
            <Input />
          </Form.Item>

          <Form.Item
            label="Company Name"
            name="companyName"
            rules={[
              {
                required: true,
                message: "Please enter your company name!",
              },
              {
                pattern: /^[A-Za-z\s.-]*$/,
                message:
                  "Please enter a valid Proposer Name (no special characters)",
              },
            ]}
          >
            <Input />
          </Form.Item>

          <Form.Item
            label="Annual Income"
            name="annualIncome"
            rules={[
              {
                required: true,
                message: "Please enter your Annual Income!",
              },
            ]}
          >
            <Select
              placeholder={SELECT_PLACEHOLDER}
              options={[
                {
                  value: "Upto 25,000",
                  label: "Upto 25,000",
                },
                {
                  value: "25,001 to 50,000",
                  label: "25,001 to 50,000",
                },
                {
                  value: "50,001 to 1,00,000",
                  label: "50,001 to 1,00,000",
                },
                {
                  value: "1,00,001 to 2,00,000",
                  label: "1,00,001 to 2,00,000",
                },
                {
                  value: "2,00,001 to 3,00,000",
                  label: "2,00,001 to 3,00,000",
                },
                {
                  value: "3,00,001 to 4,00,000",
                  label: "3,00,001 to 4,00,000",
                },
                {
                  value: "4,00,001 to 5,00,000",
                  label: "4,00,001 to 5,00,000",
                },
                {
                  value: "5,00,001 to 6,00,000",
                  label: "5,00,001 to 6,00,000",
                },
                {
                  value: "Above 6,00,000",
                  label: "Above 6,00,000",
                },
              ]}
            />
            {/* <Input /> */}
          </Form.Item>
        </Card>
      ),
    },
    {
      slug: "nominee",
      title: "Nominee",
      content: (
        <Card
          // title={`Health Declaration Form`}
          key={"Client_Information_Sheet "}
          style={{ marginTop: "5%" }}
        >
          <Divider orientation="left" orientationMargin="0">
            Nominee
          </Divider>

          <Form.Item
            label="Nominee Name"
            name="NomineeName"
            rules={[
              {
                required: true,
                message: "Please enter your Nominee Name!",
              },
              {
                pattern: /^[A-Za-z\s.]*$/,
                message:
                  "Please enter a valid Nominee Name (no special characters)",
              },
            ]}
          >
            <Input />
          </Form.Item>

          <Form.Item
            label="Nominee Date of Birth"
            name="NomineeDOB"
            rules={[
              {
                required: true,
                message: "Please select Nominee Date of Birth!",
              },
            ]}
          >
            <DatePicker
              style={{
                width: "100%",
              }}
              format={dateFormat}
              maxDate={dayjs(oneDayAgo, dateFormat)}
            />
          </Form.Item>

          <Form.Item
            label="Nominee Relation"
            name="NomineeRelation"
            rules={[
              {
                required: true,
                message: "Please enter your Nominee Relation!",
              },
              {
                pattern: /^[A-Za-z\s-]*$/,
                message:
                  "Please enter a valid Nominee Relation (no special characters)",
              },
            ]}
          >
            <Input />
          </Form.Item>
        </Card>
      ),
    },
    {
      slug: "kyc_information",
      title: "KYC Information",
      content: (
        <Card
          // title={`Health Declaration Form`}
          key={"Client_Information_Sheet "}
          style={{ marginTop: "5%" }}
        >
          <Divider orientation="left" orientationMargin="0">
            KYC Information
          </Divider>

          <Form.Item
            label="PAN Number"
            name="PANnumber"
            rules={[
              {
                required: true,
                message: "Please enter your PAN Number!",
              },
              {
                pattern: /^[A-Z]{5}[0-9]{4}[A-Z]{1}$/,
                message:
                  "PAN Number should contain exactly 10 alphanumeric characters.",
              },
            ]}
          >
            <Input maxLength={10} minLength={10} onInput={handlePanNumberChange} />
          </Form.Item>

          <Form.Item
            label="Aadhaar Number"
            name="AadhaarNumber"
            rules={[
              {
                required: true,
                message: "Please enter your Aadhaar Number",
              },
              {
                pattern: /^[0-9]{12}$/,
                message: "Aadhaar Number should contain only 12 digits",
              },
            ]}
          >
            <InputNumber
              style={{ width: "100%" }}
              maxLength={12}
              minLength={12}
            />
          </Form.Item>

          <Form.Item
            label="E-Insurance Account"
            name="EInsuranceAccount"
            rules={[
              // {
              //   required: true,
              //   message: "Please enter your E-Insurance Account!",
              // },
              {
                pattern: /^[A-Za-z0-9]*$/,
                message:
                  "E-Insurance Account can contain only alphanumeric characters.",
              },
            ]}
          >
            <Input />
          </Form.Item>
        </Card>
      ),
    },
  ];

  const [formSections, setSections] = useState(sections);

  const insuredPersonForm = {
    slug: "insured_person_card",
    title: "Insured Person",
    content: <></>,
  };

  const prev = () => {
    setCurrent(current - 1);
  };

  const onFinish = (values) => {    
    const updatedFormData = { ...customerDetails, ...values };
    
    setCurrent(current + 1);
    setUploading(true);
    const data = {
      updatedCustomerDetails: updatedFormData,
      token: declarationToken,
      declarationToken:declarationToken,
      quoteId: quoteId,
      isFinalSubmission:current === formSections.length ? true:false,
      declarationId:declarationId      
      // addedByUserId: payload?.userId,
      // groupId: payload?.groupId,
    };
    
    if (current === 2) {
      for (let i = 0; i < updatedFormData?.NumberOfInsured; i++) {
        sections.push(insuredPersonForm);
      }
      setSections(sections);
    }

    dispatch(saveCustomerData(data)).then((resp) => {
      if (resp?.payload?.data?.success === true) {
        setUploading(false);
        message.success(resp?.payload?.data?.message);
      } else {
        setUploading(false);
        message.warning("something went wrong!");
      }
    })
    .catch((error) => {
      message.warning("something went wrong!");
      setUploading(false);
    });
  };

  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };

  useEffect(() => {
    setUploading(true);
    const data = { token: declarationToken, quoteId: quoteId, declarationToken:declarationToken };
    dispatch(fetchCustomerData(data)).then((response) => {
      if (response?.payload?.data?.success === true) {
        const newDataArray = response?.payload?.data?.customerDetails?.declarationValue?.items?.map(
          (item) => {
            var newItem = { ...item };
            newItem.dob = item.dob ? dayjs(item?.dob) : undefined;
            if (newItem.diabetesDuration) {
              newItem.diabetesDuration = dayjs(item.diabetesDuration);
            }
            if (newItem.bloodpressureDuration) {
              newItem.bloodpressureDuration = dayjs(item.bloodpressureDuration);
            }
            if (newItem.asthmaDuration) {
              newItem.asthmaDuration = dayjs(item.asthmaDuration);
            }
            if (newItem.cholesterolDuration) {
              newItem.cholesterolDuration = dayjs(item.cholesterolDuration);
            }
            if (newItem.thyroidDuration) {
              newItem.thyroidDuration = dayjs(item.thyroidDuration);
            }
            return newItem;
          }
        );
        form.setFieldsValue(response?.payload?.data?.customerDetails?.declarationValue);
        form.setFieldsValue({
          items: newDataArray,
          proposerDOB: response?.payload?.data?.customerDetails?.declarationValue?.proposerDOB
            ? dayjs(response?.payload?.data?.customerDetails?.declarationValue?.proposerDOB)
            : undefined,
          NomineeDOB: response?.payload?.data?.customerDetails?.declarationValue?.NomineeDOB
            ? dayjs(response?.payload?.data?.customerDetails?.declarationValue?.NomineeDOB)
            : undefined,
        });
        // ======================= Testing =================================
        if (form.getFieldValue("items") === undefined) {
          form.setFieldsValue({
            items: Array.from(
              {
                length:
                  response?.payload?.data?.customerDetails?.declarationValue?.NumberOfInsured,
              },
              () => ({})
            ),
          });
        }
        

        // for (let i = 0; i < response?.payload?.data?.customerDetails?.data?.NumberOfInsured; i++) {
        //   sections.push(insuredPersonForm);
        // }
        // setSections(sections);
        // console.log(sections)
        // ===================================================================
        
        setUploading(false);
      } else {
        setUploading(false);
        message.warning("something went wrong!");
        navigate({ pathname: "/" });
      }
    });
  }, [declarationToken, quoteId, form, dispatch, navigate]);

  const handleInsuredCount = (count) => {
    count = parseInt(count);
    const newItems = [];
    for (let i = 0; i < count; i++) {
      newItems.push({});
    }
    form.setFieldsValue({
      items: newItems,
    });
  };

  return (
    <Row
      style={{ minHeight: "75vh", width: "100%" }}
      justify="center"
      align="middle"
    >
      <LoadingSpinner loading={uploading} />

      <Flex
        vertical
        justify="center"
        align="center"
        style={{ padding: 8, width: "100%", backgroundColor: "#faad14c9" }}
      >
        <Flex
          style={{ flexDirection: "row" }}
          align="center"
          justify="flex-start"
        >
          <BlinkingWarningIcon />{" "}
          <Title level={3}> Health Declaration Form </Title>
        </Flex>
        <Text>
          {" "}
          Please ensure you provide truthful information to avoid claim
          settlement issues.
        </Text>
      </Flex>

      {formSections.length === current ? (
        <>
          <Result
            status="success"
            title="Health Declaration Saved"
            extra={[
              <p>
                Your health declaration has been saved and our team has been
                informed. Our team will review and come back with
                recommendations as described by you.
              </p>,
              <br />,
              <p>Meanwhile, you'll receive a copy of the data shortly.</p>,
              <br />,
              <p>You may close this window now.</p>,
            ]}
          />
        </>
      ) : (
        <>
          {window.innerWidth > 575 && (
            <Steps
              size="small"
              style={{ margin: "5%" }}
              current={current}
              key={formSections.length}
            >
              {formSections.map((section, index) => (
                <Step key={index} title={section?.title} />
              ))}
            </Steps>
          )}

          <Col
            xs={24}
            sm={12}
            lg={8}
            style={{ display: "flex", width: "100%" }}
            justifyContent="center"
          >
            <Form
              layout="vertical"
              form={form}
              name="Health Declaration Form"
              style={{
                width: "100%",
                marginBottom: "5%",
              }}
              autoComplete="off"
              onFinish={onFinish}
              onFinishFailed={onFinishFailed}
            >
              {formSections[current]?.content}

              <Form.List name="items">
                {(fields, { add, remove }) => (
                  <div
                    style={{
                      display: "flex",
                      rowGap: 16,
                      flexDirection: "column",
                    }}
                  >
                    {fields.map((field) => (
                      <>
                        {current > 5 && current === 6 + field.name && (
                          <Card
                            size="small"
                            title={`Insured Person ${field.name + 1}`}
                            style={{ marginTop: "5%" }}
                          >
                            <Card
                              size="small"
                              key={field.key}
                              style={{ border: "none" }}
                            >
                              <Form.Item
                                label="Full Name as per Govt. ID"
                                name={[field.name, "fullName"]}
                                rules={[
                                  {
                                    required: true,
                                    message: "Please enter your Full Name!",
                                  },
                                  {
                                    pattern: /^[A-Za-z\s.-]*$/,
                                    message:
                                      "Please enter a valid Full Name (no special characters)",
                                  },
                                ]}
                              >
                                <Input />
                              </Form.Item>

                              <Row style={{ justifyContent: "space-between" }}>
                                <Col xs={24} sm={12} lg={10}>
                                  <Form.Item
                                    label="Date of Birth"
                                    name={[field.name, "dob"]}
                                    rules={[
                                      {
                                        required: true,
                                        message:
                                          "Please select your Date of Birth!",
                                      },
                                    ]}
                                  >
                                    <DatePicker
                                      style={{
                                        width: "100%",
                                      }}
                                      format={dateFormat}
                                      maxDate={dayjs(oneDayAgo, dateFormat)}
                                    />
                                  </Form.Item>
                                </Col>
                                <Col xs={24} sm={12} lg={10}>
                                  <Form.Item
                                    label="Gender"
                                    name={[field.name, "gender"]}
                                    rules={[
                                      {
                                        required: true,
                                        message: "Please select your Gender!",
                                      },
                                    ]}
                                  >
                                    <Select
                                      placeholder={SELECT_PLACEHOLDER}
                                      options={[
                                        {
                                          value: "Male",
                                          label: "Male",
                                        },
                                        {
                                          value: "Female",
                                          label: "Female",
                                        },
                                        {
                                          value: "Other",
                                          label: "Other",
                                        },
                                      ]}
                                    />
                                  </Form.Item>
                                </Col>
                              </Row>

                              <Form.Item
                                label="Occupation"
                                name={[field.name, "occupation"]}
                                rules={[
                                  {
                                    required: true,
                                    message: "Please enter your Occupation!",
                                  },
                                  {
                                    pattern: /^[A-Za-z\s.-]*$/,
                                    message:
                                      "Please enter a valid Occupation (no special characters)",
                                  },
                                ]}
                              >
                                <Input />
                              </Form.Item>

                              <Row style={{ justifyContent: "space-between" }}>
                                <Col xs={24} sm={12} lg={10}>
                                  <Form.Item
                                    label="Height (in Cm)"
                                    name={[field.name, "height"]}
                                    rules={[
                                      {
                                        required: true,
                                        message: "Please enter your Height!",
                                      },
                                    ]}
                                  >
                                    <InputNumber
                                      style={{ width: "100%" }}
                                      min={1}
                                    />
                                  </Form.Item>
                                </Col>
                                <Col xs={24} sm={12} lg={10}>
                                  <Form.Item
                                    label="Weight (in Kg)"
                                    name={[field.name, "weight"]}
                                    rules={[
                                      {
                                        required: true,
                                        message: "Please enter your Weight!",
                                      },
                                    ]}
                                  >
                                    <InputNumber
                                      style={{ width: "100%" }}
                                      min={1}
                                    />
                                  </Form.Item>
                                </Col>
                              </Row>
                            </Card>

                            <Card
                              size="small"
                              key={field.key}
                              style={{ border: "none" }}
                            >
                              <Divider orientation="left" orientationMargin="0">
                                Pre Existing Disease - Yes/No, if Yes Specify
                                Since when
                              </Divider>

                              <Row style={{ justifyContent: "space-between" }}>
                                <Col xs={24} sm={12} lg={10}>
                                  <Form.Item
                                    label="Diabetes"
                                    name={[field.name, "diabetes"]}
                                    rules={[
                                      {
                                        required: true,
                                        message: "Please select an option!",
                                      },
                                    ]}
                                  >
                                    <Select
                                      placeholder={SELECT_PLACEHOLDER}
                                      options={[
                                        {
                                          value: "Yes",
                                          label: "Yes",
                                        },
                                        {
                                          value: "No",
                                          label: "No",
                                        },
                                      ]}
                                    />
                                  </Form.Item>
                                </Col>
                                <Col xs={24} sm={12} lg={10}>
                                  <Form.Item noStyle shouldUpdate>
                                    {({ getFieldsValue }) =>
                                      getFieldsValue()?.items &&
                                      getFieldsValue()?.items[field?.name]
                                        ?.diabetes === "Yes" ? (
                                        <Form.Item
                                          name={[
                                            field.name,
                                            "diabetesDuration",
                                          ]}
                                          label="Diabetes (Duration)"
                                          rules={[
                                            {
                                              required: true,
                                              message:
                                                "Please input Diabetes duration!",
                                            },
                                          ]}
                                        >
                                          <DatePicker
                                            style={{
                                              width: "100%",
                                            }}
                                            format={dateFormat}
                                            maxDate={dayjs(
                                              oneDayAgo,
                                              dateFormat
                                            )}
                                          />
                                        </Form.Item>
                                      ) : null
                                    }
                                  </Form.Item>
                                </Col>
                              </Row>

                              <Row style={{ justifyContent: "space-between" }}>
                                <Col xs={24} sm={12} lg={10}>
                                  <Form.Item
                                    label="Blood Pressure"
                                    name={[field.name, "bloodpressure"]}
                                    rules={[
                                      {
                                        required: true,
                                        message: "Please select an option!",
                                      },
                                    ]}
                                  >
                                    <Select
                                      placeholder={SELECT_PLACEHOLDER}
                                      options={[
                                        {
                                          value: "Yes",
                                          label: "Yes",
                                        },
                                        {
                                          value: "No",
                                          label: "No",
                                        },
                                      ]}
                                    />
                                  </Form.Item>
                                </Col>
                                <Col xs={24} sm={12} lg={10}>
                                  <Form.Item noStyle shouldUpdate>
                                    {({ getFieldsValue }) =>
                                      getFieldsValue()?.items &&
                                      getFieldsValue()?.items[field.name]
                                        ?.bloodpressure === "Yes" ? (
                                        <Form.Item
                                          label="Blood Pressure (Duration)"
                                          name={[
                                            field.name,
                                            "bloodpressureDuration",
                                          ]}
                                          rules={[
                                            {
                                              required: true,
                                              message:
                                                "Please input Blood Pressure duration!",
                                            },
                                          ]}
                                        >
                                          <DatePicker
                                            style={{
                                              width: "100%",
                                            }}
                                            format={dateFormat}
                                            maxDate={dayjs(
                                              oneDayAgo,
                                              dateFormat
                                            )}
                                          />
                                        </Form.Item>
                                      ) : null
                                    }
                                  </Form.Item>
                                </Col>
                              </Row>

                              <Row style={{ justifyContent: "space-between" }}>
                                <Col xs={24} sm={12} lg={10}>
                                  <Form.Item
                                    label="Asthma"
                                    name={[field.name, "asthma"]}
                                    rules={[
                                      {
                                        required: true,
                                        message: "Please select an option!",
                                      },
                                    ]}
                                  >
                                    <Select
                                      placeholder={SELECT_PLACEHOLDER}
                                      options={[
                                        {
                                          value: "Yes",
                                          label: "Yes",
                                        },
                                        {
                                          value: "No",
                                          label: "No",
                                        },
                                      ]}
                                    />
                                  </Form.Item>
                                </Col>
                                <Col xs={24} sm={12} lg={10}>
                                  <Form.Item noStyle shouldUpdate>
                                    {({ getFieldsValue }) =>
                                      getFieldsValue()?.items &&
                                      getFieldsValue()?.items[field.name]
                                        ?.asthma === "Yes" ? (
                                        <Form.Item
                                          label="Asthma (Duration)"
                                          name={[field.name, "asthmaDuration"]}
                                          rules={[
                                            {
                                              required: true,
                                              message:
                                                "Please input Asthma duration!",
                                            },
                                          ]}
                                        >
                                          <DatePicker
                                            style={{
                                              width: "100%",
                                            }}
                                            format={dateFormat}
                                            maxDate={dayjs(
                                              oneDayAgo,
                                              dateFormat
                                            )}
                                          />
                                        </Form.Item>
                                      ) : null
                                    }
                                  </Form.Item>
                                </Col>
                              </Row>

                              <Row style={{ justifyContent: "space-between" }}>
                                <Col xs={24} sm={12} lg={10}>
                                  <Form.Item
                                    label="Cholesterol"
                                    name={[field.name, "cholesterol"]}
                                    rules={[
                                      {
                                        required: true,
                                        message: "Please select an option!",
                                      },
                                    ]}
                                  >
                                    <Select
                                      placeholder={SELECT_PLACEHOLDER}
                                      options={[
                                        {
                                          value: "Yes",
                                          label: "Yes",
                                        },
                                        {
                                          value: "No",
                                          label: "No",
                                        },
                                      ]}
                                    />
                                  </Form.Item>
                                </Col>
                                <Col xs={24} sm={12} lg={10}>
                                  <Form.Item noStyle shouldUpdate>
                                    {({ getFieldsValue }) =>
                                      getFieldsValue()?.items &&
                                      getFieldsValue()?.items[field.name]
                                        ?.cholesterol === "Yes" ? (
                                        <Form.Item
                                          shouldUpdate
                                          label="Cholesterol (Duration)"
                                          name={[
                                            field.name,
                                            "cholesterolDuration",
                                          ]}
                                          rules={[
                                            {
                                              required: true,
                                              message:
                                                "Please input Cholesterol duration!",
                                            },
                                          ]}
                                        >
                                          <DatePicker
                                            style={{
                                              width: "100%",
                                            }}
                                            format={dateFormat}
                                            maxDate={dayjs(
                                              oneDayAgo,
                                              dateFormat
                                            )}
                                          />
                                        </Form.Item>
                                      ) : null
                                    }
                                  </Form.Item>
                                </Col>
                              </Row>

                              <Row style={{ justifyContent: "space-between" }}>
                                <Col xs={24} sm={12} lg={10}>
                                  <Form.Item
                                    label="Thyroid (Hyper/Hypo)"
                                    name={[field.name, "thyroid"]}
                                    rules={[
                                      {
                                        required: true,
                                        message: "Please select an option!",
                                      },
                                    ]}
                                  >
                                    <Select
                                      placeholder={SELECT_PLACEHOLDER}
                                      options={[
                                        {
                                          value: "Yes",
                                          label: "Yes",
                                        },
                                        {
                                          value: "No",
                                          label: "No",
                                        },
                                      ]}
                                    />
                                  </Form.Item>
                                </Col>
                                <Col xs={24} sm={12} lg={10}>
                                  <Form.Item noStyle shouldUpdate>
                                    {({ getFieldsValue }) =>
                                      getFieldsValue()?.items &&
                                      getFieldsValue()?.items[field.name]
                                        ?.thyroid === "Yes" ? (
                                        <Form.Item
                                          label="Thyroid (Duration)"
                                          name={[field.name, "thyroidDuration"]}
                                          rules={[
                                            {
                                              required: true,
                                              message:
                                                "Please input Thyroid duration!",
                                            },
                                          ]}
                                        >
                                          <DatePicker
                                            style={{
                                              width: "100%",
                                            }}
                                            format={dateFormat}
                                            maxDate={dayjs(
                                              oneDayAgo,
                                              dateFormat
                                            )}
                                          />
                                        </Form.Item>
                                      ) : null
                                    }
                                  </Form.Item>
                                </Col>
                              </Row>

                              <Row style={{ justifyContent: "space-between" }}>
                                <Col xs={24} sm={12} lg={10}>
                                  <Form.Item
                                    label="Genetic Disease"
                                    name={[field.name, "genetic"]}
                                    rules={[
                                      {
                                        required: true,
                                        message: "Please select an option!",
                                      },
                                    ]}
                                  >
                                    <Select
                                      placeholder={SELECT_PLACEHOLDER}
                                      options={[
                                        {
                                          value: "Yes",
                                          label: "Yes",
                                        },
                                        {
                                          value: "No",
                                          label: "No",
                                        },
                                      ]}
                                    />
                                  </Form.Item>
                                </Col>
                                <Col xs={24} sm={12} lg={10}>
                                  <Form.Item noStyle shouldUpdate>
                                    {({ getFieldsValue }) =>
                                      getFieldsValue()?.items &&
                                      getFieldsValue()?.items[field.name]
                                        ?.genetic === "Yes" ? (
                                        <Form.Item
                                          label="Specify Genetic Disease"
                                          name={[field.name, "geneticDisease"]}
                                          rules={[
                                            {
                                              required: true,
                                              message:
                                                "Please input Genetic disease info!",
                                            },
                                            {
                                              pattern: /^[A-Za-z\s.-]*$/,
                                              message:
                                                "Please enter a valid Genetic disease info (no special characters)",
                                            },
                                          ]}
                                        >
                                          <Input />
                                        </Form.Item>
                                      ) : null
                                    }
                                  </Form.Item>
                                </Col>
                              </Row>

                              <Form.Item
                                label="Any Surgery (if yes Specify Details and share with us)"
                                name={[field.name, "surgery"]}
                                rules={[
                                  {
                                    required: true,
                                    message: "Please select an option!",
                                  },
                                ]}
                              >
                                <Select
                                  placeholder={SELECT_PLACEHOLDER}
                                  options={[
                                    {
                                      value: "Yes",
                                      label: "Yes",
                                    },
                                    {
                                      value: "No",
                                      label: "No",
                                    },
                                  ]}
                                />
                              </Form.Item>

                              <Form.Item noStyle shouldUpdate>
                                {({ getFieldsValue }) =>
                                  getFieldsValue()?.items &&
                                  getFieldsValue()?.items[field.name]
                                    ?.surgery === "Yes" ? (
                                    <Form.Item
                                      label="Surgery Details"
                                      name={[field.name, "surgeryName"]}
                                      rules={[
                                        {
                                          required: true,
                                          message:
                                            "Please input Surgery details",
                                        },
                                        {
                                          pattern: /^[A-Za-z0-9\s.,-]*$/,
                                          message:
                                            "Please enter a valid Surgery details (no special characters)",
                                        },
                                      ]}
                                    >
                                      <TextArea />
                                    </Form.Item>
                                  ) : null
                                }
                              </Form.Item>

                              <Form.Item
                                label="Medication/Tablet (if yes Specify Details and share with us)"
                                name={[field.name, "medication"]}
                                rules={[
                                  {
                                    required: true,
                                    message: "Please select an option!",
                                  },
                                ]}
                              >
                                <Select
                                  placeholder={SELECT_PLACEHOLDER}
                                  options={[
                                    {
                                      value: "Yes",
                                      label: "Yes",
                                    },
                                    {
                                      value: "No",
                                      label: "No",
                                    },
                                  ]}
                                />
                              </Form.Item>

                              <Form.Item noStyle shouldUpdate>
                                {({ getFieldsValue }) =>
                                  getFieldsValue()?.items &&
                                  getFieldsValue()?.items[field.name]
                                    ?.medication === "Yes" ? (
                                    <Form.Item
                                      label="Medication/Tablet Details"
                                      name={[field.name, "medicationName"]}
                                      rules={[
                                        {
                                          required: true,
                                          message:
                                            "Please input Medication Details!",
                                        },
                                        {
                                          pattern: /^[A-Za-z0-9\s.,-]*$/,
                                          message:
                                            "Please enter a valid Medication details (no special characters)",
                                        },
                                      ]}
                                    >
                                      <TextArea />
                                    </Form.Item>
                                  ) : null
                                }
                              </Form.Item>
                            </Card>

                            <Card
                              size="small"
                              key={field.key}
                              style={{ border: "none" }}
                            >
                              <Divider orientation="left" orientationMargin="0">
                                Does the Person has the habbit of consuming
                                below
                              </Divider>

                              <Row style={{ justifyContent: "space-between" }}>
                                <Col xs={24} sm={12} lg={10}>
                                  <Form.Item
                                    label="Cigarette/Bidi"
                                    name={[field.name, "cigarettebidi"]}
                                    rules={[
                                      {
                                        required: true,
                                        message: "Please select Yes or No",
                                      },
                                    ]}
                                  >
                                    <Select
                                      placeholder={SELECT_PLACEHOLDER}
                                      options={[
                                        {
                                          value: "Yes",
                                          label: "Yes",
                                        },
                                        {
                                          value: "No",
                                          label: "No",
                                        },
                                      ]}
                                    />
                                  </Form.Item>
                                </Col>
                                <Col xs={24} sm={12} lg={10}>
                                  <Form.Item noStyle shouldUpdate>
                                    {({ getFieldsValue }) =>
                                      getFieldsValue()?.items &&
                                      getFieldsValue()?.items[field.name]
                                        ?.cigarettebidi === "Yes" ? (
                                        <Form.Item
                                          label="Cigarette Per Day"
                                          name={[
                                            field.name,
                                            "cigarettebidicount",
                                          ]}
                                          rules={[
                                            {
                                              required: true,
                                              message:
                                                "Please input Cigarette count!",
                                            },
                                          ]}
                                        >
                                          <InputNumber
                                            style={{ width: "100%" }}
                                            max={100}
                                            min={1}
                                          />
                                        </Form.Item>
                                      ) : null
                                    }
                                  </Form.Item>
                                </Col>
                              </Row>

                              <Row style={{ justifyContent: "space-between" }}>
                                <Col xs={24} sm={12} lg={10}>
                                  <Form.Item
                                    label="Tabacco Pouches"
                                    name={[field.name, "tabaccopouches"]}
                                    rules={[
                                      {
                                        required: true,
                                        message: "Please select Yes or No",
                                      },
                                    ]}
                                  >
                                    <Select
                                      placeholder={SELECT_PLACEHOLDER}
                                      options={[
                                        {
                                          value: "Yes",
                                          label: "Yes",
                                        },
                                        {
                                          value: "No",
                                          label: "No",
                                        },
                                      ]}
                                    />
                                  </Form.Item>
                                </Col>
                                <Col xs={24} sm={12} lg={10}>
                                  <Form.Item noStyle shouldUpdate>
                                    {({ getFieldsValue }) =>
                                      getFieldsValue()?.items &&
                                      getFieldsValue()?.items[field.name]
                                        ?.tabaccopouches === "Yes" ? (
                                        <Form.Item
                                          label="Pouches Per Day"
                                          name={[
                                            field.name,
                                            "tabaccopouchescount",
                                          ]}
                                          rules={[
                                            {
                                              required: true,
                                              message:
                                                "Please input Tabacco Pouches count!",
                                            },
                                          ]}
                                        >
                                          <InputNumber
                                            style={{ width: "100%" }}
                                            max={100}
                                            min={1}
                                          />
                                        </Form.Item>
                                      ) : null
                                    }
                                  </Form.Item>
                                </Col>
                              </Row>

                              <Row style={{ justifyContent: "space-between" }}>
                                <Col xs={24} sm={12} lg={10}>
                                  <Form.Item
                                    label="Alcohol"
                                    name={[field.name, "alcohol"]}
                                    rules={[
                                      {
                                        required: true,
                                        message: "Please select Yes or No",
                                      },
                                    ]}
                                  >
                                    <Select
                                      placeholder={SELECT_PLACEHOLDER}
                                      options={[
                                        {
                                          value: "Yes",
                                          label: "Yes",
                                        },
                                        {
                                          value: "No",
                                          label: "No",
                                        },
                                      ]}
                                    />
                                  </Form.Item>
                                </Col>
                                <Col xs={24} sm={12} lg={10}>
                                  <Form.Item noStyle shouldUpdate>
                                    {({ getFieldsValue }) =>
                                      getFieldsValue()?.items &&
                                      getFieldsValue()?.items[field.name]
                                        ?.alcohol === "Yes" ? (
                                        <Form.Item
                                          label="Alcohol Frequency"
                                          name={[field.name, "alcoholcount"]}
                                          rules={[
                                            {
                                              required: true,
                                              message:
                                                "Please select frequency of Alcohol!",
                                            },
                                          ]}
                                        >
                                          <Select
                                            placeholder={SELECT_PLACEHOLDER}
                                            options={[
                                              {
                                                value: "Daily",
                                                label: "Daily",
                                              },
                                              {
                                                value: "Weekly",
                                                label: "Weekly",
                                              },
                                              {
                                                value: "Monthly",
                                                label: "Monthly",
                                              },
                                              {
                                                value: "Occassionally",
                                                label: "Occasionally",
                                              },
                                            ]}
                                          />
                                        </Form.Item>
                                      ) : null
                                    }
                                  </Form.Item>
                                </Col>
                              </Row>

                              <Row style={{ justifyContent: "space-between" }}>
                                <Col xs={24} sm={12} lg={10}>
                                  <Form.Item
                                    label="Gutka Pouches"
                                    name={[field.name, "gutkapouches"]}
                                    rules={[
                                      {
                                        required: true,
                                        message: "Please select Yes or No",
                                      },
                                    ]}
                                  >
                                    <Select
                                      placeholder={SELECT_PLACEHOLDER}
                                      options={[
                                        {
                                          value: "Yes",
                                          label: "Yes",
                                        },
                                        {
                                          value: "No",
                                          label: "No",
                                        },
                                      ]}
                                    />
                                  </Form.Item>
                                </Col>
                                <Col xs={24} sm={12} lg={10}>
                                  <Form.Item noStyle shouldUpdate>
                                    {({ getFieldsValue }) =>
                                      getFieldsValue()?.items &&
                                      getFieldsValue()?.items[field.name]
                                        ?.gutkapouches === "Yes" ? (
                                        <Form.Item
                                          label="Pouches Per Day"
                                          name={[
                                            field.name,
                                            "gutkapouchescount",
                                          ]}
                                          rules={[
                                            {
                                              required: true,
                                              message:
                                                "Please input Gutka pouches count!",
                                            },
                                          ]}
                                        >
                                          <InputNumber
                                            style={{ width: "100%" }}
                                            max={100}
                                            min={1}
                                          />
                                        </Form.Item>
                                      ) : null
                                    }
                                  </Form.Item>
                                </Col>
                              </Row>

                              <Form.Item
                                label="Drugs"
                                name={[field.name, "drugs"]}
                                rules={[
                                  {
                                    required: true,
                                    message: "Please select Yes or No",
                                  },
                                ]}
                              >
                                <Select
                                  placeholder={SELECT_PLACEHOLDER}
                                  options={[
                                    {
                                      value: "Yes",
                                      label: "Yes",
                                    },
                                    {
                                      value: "No",
                                      label: "No",
                                    },
                                  ]}
                                />
                              </Form.Item>

                              <Form.Item noStyle shouldUpdate>
                                {({ getFieldsValue }) =>
                                  getFieldsValue()?.items &&
                                  getFieldsValue()?.items[field.name]?.drugs ===
                                    "Yes" ? (
                                    <Form.Item
                                      label="Drugs Details"
                                      name={[field.name, "drugsdetails"]}
                                      rules={[
                                        {
                                          required: true,
                                          message: "Please specify Details!",
                                        },
                                      ]}
                                    >
                                      <TextArea />
                                    </Form.Item>
                                  ) : null
                                }
                              </Form.Item>
                            </Card>
                          </Card>
                        )}
                      </>
                    ))}
                  </div>
                )}
              </Form.List>

              <Row
                style={{
                  display: "flex",
                  justifyContent: "space-around",
                  marginTop: "5%",
                }}
              >
                <Space>
                  {current > 0 && current < formSections.length && (
                    <Form.Item>
                      <Button
                        style={{ margin: "0 8px" }}
                        onClick={() => prev()}
                      >
                        Previous
                      </Button>
                    </Form.Item>
                  )}

                  {current < formSections.length - 1 && (
                    <Form.Item>
                      <Button type="primary" htmlType="submit">
                        Save & Next
                      </Button>
                    </Form.Item>
                  )}

                  {current === formSections.length - 1 && (
                    <Form.Item>
                      <Button type="primary" htmlType="submit">
                        Finish
                      </Button>
                    </Form.Item>
                  )}
                </Space>
              </Row>
            </Form>
          </Col>
        </>
      )}
    </Row>
  );
};

export default NewHealthDeclarationForm;
