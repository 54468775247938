import React from "react";
import { Card, Flex } from "antd";
import { message } from "antd";
import { Typography } from "antd";
import {
  MailOutlined,
  PhoneOutlined,
  CopyOutlined,
  EnvironmentOutlined,
} from "@ant-design/icons";

const { Title, Text } = Typography;

const ContactCard = ({ customer }) => {
  const handleCopyEmail = (email) => {
    navigator.clipboard.writeText(email);
    message.success("Email copied to clipboard");
  };

  return (
    // <Card style={{ width: "100% !important" }}>
    <div>
      <Title
        level={3}
        style={{ marginTop: 0, height: "50px", width: "100% !important" }}
      >
        <Flex align={"center"} justify="space-between">
          {customer?.firstName} {customer?.lastName}
          <Flex vertical align="flex-end">
            <Text style={{ fontSize: 16 }}>
              <MailOutlined />
              <a
                style={{ paddingInline: 8 }}
                href={"mailto:" + customer?.email}
                onClick={() => handleCopyEmail(customer?.email)}
              >
                {customer?.email} <CopyOutlined style={{ marginLeft: 5 }} />
              </a>
            </Text>
            <Text style={{ fontSize: 16, paddingInline: 8 }}>
              <PhoneOutlined /> {customer?.phone}
            </Text>
            <Text style={{ fontSize: 14, paddingInline: 8 }}>
              <EnvironmentOutlined /> {customer?.location}
            </Text>
          </Flex>
        </Flex>
      </Title>
    </div>
    // {/* </Card> */}
  );
};

// Component for displaying customer information
const CustomerInformation = ({ customer }) => (
  <Card title="Customer Account" style={{ marginBottom: "20px" }}>
    <Flex justify="space-between">
      <Flex vertical style={{ width: "100%" }}>
        <ContactCard customer={customer} />
      </Flex>
    </Flex>
  </Card>
);

export default CustomerInformation;
