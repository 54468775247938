import React, { useEffect, useState } from "react";
import { Layout, Card, Table, Flex, Button, Modal, Tag, Row, Col } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { message } from "antd";
import { fetchCustomerQuoteList, updateCustomerQuoteProposal } from "../store/reducers/authReducer";
import LoadingSpinner from "../components/Loader";
import { Typography } from "antd";
import {
  MailOutlined,
  PhoneOutlined,
  CopyOutlined,
  MedicineBoxOutlined,
  CarOutlined,
  BankOutlined,
  GlobalOutlined,
  CheckCircleOutlined,
  CloseCircleOutlined,
  SyncOutlined,
} from "@ant-design/icons";
import {
  EmailIcon,
  EmailShareButton,
  WhatsappIcon,
  WhatsappShareButton,
} from "react-share";

const { Content } = Layout;
const { Title, Text } = Typography;


const ProductNeed = ({ product }) => {
  return (
    <Flex
      style={{
        fontSize: 16,
        fontWeight: 600,
        borderRadius: 9,
        border: "2px dotted teal",
        padding: 8,
      }}
      align="center"
    >
      <CarOutlined
        style={{
          marginInline: 8,
          display:
            product === "Motor Insurance"
              ? "block"
              : "none",
        }}
      />
      <MedicineBoxOutlined
        style={{
          marginInline: 8,
          display:
            product === "Health" ? "block" : "none",
        }}
      />
      <BankOutlined
        style={{
          marginInline: 8,
          display:
            product === "Corporate" ? "block" : "none",
        }}
      />
      <GlobalOutlined
        style={{
          marginInline: 8,
          display:
            product === "Others" ? "block" : "none",
        }}
      />
      <Text>{product}</Text>
    </Flex>
  );
};

// Component for displaying customer information
const CustomerInformation2 = ({customer, productNeed}) => (
  <Card style={{ marginBottom: "20px" }}>
    <Flex justify="space-between">
        <Card style={{ width: "50vw" }}>
            <Title level={3} style={{ marginTop: 0}}>
                <Flex align={"center"} justify="space-between">
                    {customer?.firstName} {customer?.lastName}
                    <ProductNeed product={productNeed} />
                </Flex>
            </Title>
        </Card>
    </Flex>
  </Card>
);


const ContactCard = ({ customer }) => {
  const handleCopyEmail = (email) => {
    navigator.clipboard.writeText(email);
    message.success("Email copied to clipboard");
  };

  return (
    <Card style={{ width: "100% !important" }}>
      <div>
        <Title level={3} style={{ marginTop: 0, height: "40px" }}>
          <Flex align={"center"} justify="space-between">
            {customer?.firstName} {customer?.lastName}
            {/* <ProductNeed customer={customer} /> */}
            <Flex vertical align="flex-end">
              <Text style={{ fontSize: 16 }}>
                <MailOutlined />
                <a
                  style={{paddingInline: 8}}
                  href={"mailto:" + customer?.email}
                  onClick={() => handleCopyEmail(customer?.email)}
                >
                  {customer?.email} <CopyOutlined style={{ marginLeft: 5 }} />
                </a>
              </Text>
              <Text style={{ fontSize: 16, paddingInline: 8}}>
                <PhoneOutlined /> {customer?.phone}
              </Text>
            </Flex>
          </Flex>
        </Title>
        {/* <Divider />
        <Text style={{ fontSize: 16 }}>
          {" "}
          <MailOutlined />{" "}
          <a
            href={"mailto:" + customer?.email}
            onClick={() => handleCopyEmail(customer?.email)}
          >
            {customer?.email} <CopyOutlined style={{ marginLeft: 5 }} />
          </a>
        </Text>{" "}
        <p></p>
        <Text style={{ fontSize: 16 }}>
          {" "}
          <PhoneOutlined /> {customer?.phoneNumber}
        </Text> */}
      </div>
    </Card>
  );
};

// Component for displaying customer information
const CustomerInformation = ({ customer, productNeed }) => (
  <Card title="Quotes & Proposal" style={{ marginBottom: "20px"}}>
    <Flex justify="space-between" >
      <Flex vertical style={{ width: "100%"}}>
        <ContactCard customer={customer} />
      </Flex>
      <Flex>
        {/* <RiskScoreCard riskScore={riskScore} /> */}
      </Flex>
    </Flex>
  </Card>
);

const DeclarationAndDocs = ({
  customer,
  activeCustomerId,
  activeProposalId,
  shareUrl = `https://${window.location.host}/new-health-declaration?id=${activeCustomerId}&proposalId=${activeProposalId}`,
  productNeed,
}) => {
  const [isShareModalOpen, setIsShareModalOpen] = useState(false);
  const handleShareOk = () => {
    setIsShareModalOpen(false);
  };
  const handleShareCancel = () => {
    setIsShareModalOpen(false);
  };

  const shareEmailBody = `Hello ${customer.firstName} ${customer.lastName},
  Please use the below link to complete your Health Declaration Form. Once you have completed the form, our team will get back to you.`;

  return (
    <Card title="Documents and Declarations" style={{ marginBottom: "20px" }}>
      <Modal
        title="Share Health Declaration"
        open={isShareModalOpen}
        onOk={handleShareOk}
        onCancel={handleShareCancel}
        centered
      >
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            gap: "10px",
            alignItems: "flex-start",
            width: "100%",
          }}
        >
          <h3>
            Share a health declaration form with customer to get access to their
            health profile.
          </h3>

          <WhatsappShareButton
            url={shareUrl}
            title="Health Declaration Form"
            style={{ width: "50%" }}
          >
            <Button
              className="bg-green-500 hover:bg-green-600 text-white font-bold py-2 px-4 rounded"
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                gap: "5px",
              }}
              block={true}
            >
              <WhatsappIcon size={24} round={true} />
              Share on Whatsapp
            </Button>
          </WhatsappShareButton>

          <EmailShareButton
            url={shareUrl}
            subject="Health Declaration Form"
            body={shareEmailBody}
            style={{ width: "50%" }}
          >
            <Button
              className="bg-red-500 hover:bg-red-600 text-white font-bold py-2 px-4 rounded"
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                gap: "5px",
              }}
              block={true}
            >
              <EmailIcon size={24} round={true} />
              Share via Your Email
            </Button>
          </EmailShareButton>

          <Button
            className="bg-red-500 hover:bg-red-600 text-white font-bold py-2 px-4 rounded"
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              gap: "5px",
              width: "50%",
            }}
            block={true}
            onClick={async () => {
              await navigator.clipboard.writeText(shareUrl);
              message.success("Health Declaration Link copied to clipboard");
            }}
          >
            <CopyOutlined size={24} />
            Copy to Clipboard
          </Button>
        </div>
      </Modal>

      <Flex justify="space-between">
        <Flex
          style={{ display: productNeed === "Health" ? "block" : "none",}}
        >
          <Button
            type="default"
            target="_blank"
            href={`https://${window.location.host}/new-health-declaration?id=${activeCustomerId}&proposalId=${activeProposalId}`}
          >
            View Health Declaration
          </Button>
          <Button
            style={{ marginInline: 10 }}
            onClick={() => setIsShareModalOpen(true)}
          >
            Share Health Declaration
          </Button>
        </Flex>
      </Flex>
    </Card>
  );
};

// Component for displaying quotes table
const QuotesTable = ({ quotes, onNewProposal, setUploading }) => {
  const columns = [
    {
      title: "Product Suggested",
      dataIndex: "productSuggested",
      key: "productSuggested",
    },
    {
      title: "Date",
      dataIndex: "date",
      key: "date",
    },
    {
      title: "Agent Email",
      dataIndex: "agentEmail",
      key: "agentEmail",
    },
    {
      title: "Premium Suggested",
      dataIndex: "premiumSuggested",
      key: "premiumSuggested",
    },
    {
      title: "Proposal PDF",
      dataIndex: "url",
      key: "url",
      render: (_, record) => (
        <Button onClick={() => showModal(record.url)}>View Proposal</Button>
      ),
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      render: (_, record) => (
        <>
          <Tag
            icon={ 
              record.status === "Accepted"
              ? (<CheckCircleOutlined />)
              : record.status === "Rejected"
              ? (<CloseCircleOutlined />)
              : (<SyncOutlined />)
            }
            color={
              record.status === "Accepted"
                ? "success"
                : record.status === "Rejected"
                ? "error"
                : "processing"
            }
          >
            {record.status}
          </Tag>
        </>
      ),
    },
  ];

  const [pdfUrl, setpdfUrl] = useState("");
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isShareModalOpen, setIsShareModalOpen] = useState(false);
  const dispatch = useDispatch();

  const showModal = (url) => {
    setpdfUrl(url);
    setIsModalOpen(true);
  };
  
  const handleOk = () => {
    setIsModalOpen(false);
  };
  
  const handleCancel = () => {
    setIsModalOpen(false);
  };

  const handleQuoteStatusChange = (quoteStatus) => {
    setUploading(true)
    const myOptions = quotes.filter((item) => item["url"] === pdfUrl);
    if (myOptions.length > 0) {
      const updatedQuote = { ...myOptions[0], status: quoteStatus };
      dispatch(updateCustomerQuoteProposal(updatedQuote)).then((response) => {
        if (response?.payload?.success) {
          setUploading(false)
          message.success("Succesfully updated Quotation Status");
        } else {
          setUploading(false)
          message.error("Could not update Quotation Status");
        }
      }).catch((error) => {
        setUploading(false)
        message.error("Error updating Quotation");
      });
    } else {
      setUploading(false)
      message.error("No matching quote found");
    }
  };

  return (
    <>
      <Modal
        title="Quote Proposal"
        open={isModalOpen}
        onOk={handleOk}
        onCancel={handleCancel}
        width="80%"
        style={{ top: 5 }}
        footer={(_, { CancelBtn }) => (
          <Row style={{display:"flex", justifyContent:"space-between"}}>
            <Col style={{ display: 'flex', justifyContent: 'space-between', gap: '10px' }}>
              <Button key="accept" type="primary" style={{ background: 'green' }}  onClick={() => handleQuoteStatusChange("Accepted")}>
                Accept Quote
              </Button>
              <Button key="reject" type="primary" danger onClick={() => handleQuoteStatusChange("Rejected")}>
                Reject Quote
              </Button>
            </Col>
            <Col style={{ display: 'flex', justifyContent: 'space-between', gap: '10px' }}>
              <Button type="primary" onClick={() => {
                alert('Feature Coming Soon'); 
              // setIsShareModalOpen(true)
              }}>
                Share Quote
              </Button>
              <Button type="primary" onClick={() => {alert('Feature Coming Soon');}}>
                Edit Quote
              </Button>
              <CancelBtn />
            </Col>
          </Row>
        )}
      >
        <div style={{ height: "80vh" }}>
          <iframe
            title="PDF Viewer"
            src={pdfUrl}
            width="100%"
            height="100%"
            frameBorder="0"
          />
        </div>
      </Modal>

      <Modal
        title="Share Health Quotation"
        open={isShareModalOpen}
        onOk={() => setIsShareModalOpen(false)}
        onCancel={() => setIsShareModalOpen(false)}
        centered
      >
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            gap: "10px",
            alignItems: "flex-start",
            width: "100%",
          }}
        >
          <h3>
            Share this Quote with customers.
          </h3>

          <WhatsappShareButton
            // url={pdfUrl}
            title="Health Insurance Quote Proposal"
            style={{ width: "50%" }}
          >
            <Button
              className="bg-green-500 hover:bg-green-600 text-white font-bold py-2 px-4 rounded"
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                gap: "5px",
              }}
              block={true}
            >
              <WhatsappIcon size={24} round={true} />
              Share on Whatsapp
            </Button>
          </WhatsappShareButton>

          <EmailShareButton
            subject="Health Insurance Quote Proposal"
            // body={shareEmailBody}
            style={{ width: "50%" }}
          >
            <Button
              className="bg-red-500 hover:bg-red-600 text-white font-bold py-2 px-4 rounded"
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                gap: "5px",
              }}
              block={true}
            >
              <EmailIcon size={24} round={true} />
              Share via Your Email
            </Button>
          </EmailShareButton>
        </div>
      </Modal>

      <Table
        title={() => {
          return (
            <Flex dir="row" justify={"space-between"}>
              <Text style={{ fontWeight: 600, fontSize: 16 }}>
                Quotes
              </Text>
              <Button type="primary" onClick={onNewProposal}>
                New Quotation
              </Button>
            </Flex>
          );
        }}
        columns={columns}
        dataSource={quotes}
        showSorterTooltip={true}
      />
    </>
  );
};


function CustomerProposal({ customer, quotes }) {
  const [messageApi, contextHolder] = message.useMessage();
  const activeCustomer = useSelector((state) => state?.auth?.activeCustomer);
  const activeCustomerQuotes = useSelector(
    (state) => state?.auth?.customerQuotes
  );
  const activeCustomerProductProposals = useSelector(
    (state) => state?.auth?.activeProposal
  );
  const agentEmail = useSelector((state) => state?.auth?.auth_profile?.email);
  const agentOrganisationId = useSelector(
    (state) => state?.auth?.auth_profile?.organisation_id
  );
  const [uploading, setUploading] = useState(false);

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const onNewProposal = () => {
    navigate({
      pathname: "/quote-proposal",
      search: `?customerId=${activeCustomer.id}&proposalId=${activeCustomerProductProposals.id}`,
    });
  };

  useEffect(() => {
    setUploading(true);
    const data = {
      agentEmail: agentEmail,
      proposerEmail: activeCustomer.email,
      organisation_id: agentOrganisationId,
      proposalId:activeCustomerProductProposals.id,
    };
    dispatch(fetchCustomerQuoteList(data)).then((response) => {
      if (response?.payload?.success === true) {
        setUploading(false);
      } else {
        setUploading(false);
      }
    });
  }, [activeCustomer, agentEmail, agentOrganisationId, activeCustomerProductProposals, dispatch]);
  return (
    <>
      <LoadingSpinner loading={uploading} />
      {contextHolder}
      <Layout>
        <Content style={{ padding: "20px" }}>
          <Flex gutter={[16, 16]} flex={""} style={{ flexDirection: "column" }}>
            <CustomerInformation
              customer={activeCustomer}
              productNeed={activeCustomerProductProposals?.data.insuranceProductNeed}
            />
            <DeclarationAndDocs
              customer={activeCustomer}
              onNewProposal={onNewProposal}
              activeProposalId={activeCustomerProductProposals.id}
              activeCustomerId={activeCustomer.id}
              productNeed={activeCustomerProductProposals?.data.insuranceProductNeed}
              ></DeclarationAndDocs>
            <QuotesTable
              quotes={activeCustomerQuotes}
              onNewProposal={onNewProposal}
              setUploading={setUploading}              
            />
          </Flex>
        </Content>
      </Layout>
    </>
  );
}

export default CustomerProposal;
