import React from 'react';
import { Form, Input, DatePicker, Button } from 'antd';

const { TextArea } = Input;

const MedicalReportForm = () => {
  const onFinish = (values) => {
    console.log('Form submitted:', values);
    // Handle form submission logic (e.g., API request to submit medical report)
  };

  return (
    <Form
      name="medical-report-form"
      onFinish={onFinish}
      labelCol={{ span: 6 }}
      wrapperCol={{ span: 12 }}
      initialValues={{ remember: true }}
    >
      {/* Patient Information */}
      <h3>1. Patient Information</h3>
      <Form.Item
        label="Full Name"
        name="fullName"
        rules={[{ required: true, message: 'Please enter the patient\'s full name' }]}
      >
        <Input />
      </Form.Item>

      <Form.Item
        label="Date of Birth"
        name="dateOfBirth"
        rules={[{ required: true, message: 'Please select the patient\'s date of birth' }]}
      >
        <DatePicker />
      </Form.Item>

      <Form.Item
        label="Contact Information"
        name="contactInfo"
        rules={[{ required: true, message: 'Please enter the patient\'s contact information' }]}
      >
        <Input />
      </Form.Item>

      {/* Medical History */}
      <h3>2. Medical History</h3>
      <Form.Item
        label="Medical History"
        name="medicalHistory"
        rules={[{ required: true, message: 'Please provide the patient\'s medical history' }]}
      >
        <TextArea rows={4} />
      </Form.Item>

      {/* Details of Injury or Condition */}
      <h3>3. Details of Injury or Condition</h3>
      <Form.Item
        label="Description of Injury/Condition"
        name="injuryDescription"
        rules={[{ required: true, message: 'Please describe the injury or medical condition' }]}
      >
        <TextArea rows={4} />
      </Form.Item>

      {/* Diagnosis and Treatment */}
      <h3>4. Diagnosis and Treatment</h3>
      <Form.Item
        label="Diagnosis"
        name="diagnosis"
        rules={[{ required: true, message: 'Please provide the diagnosis from the healthcare provider' }]}
      >
        <Input />
      </Form.Item>

      <Form.Item
        label="Treatment Plan"
        name="treatmentPlan"
        rules={[{ required: true, message: 'Please describe the treatment plan' }]}
      >
        <TextArea rows={4} />
      </Form.Item>

      {/* Medical Tests and Results */}
      <h3>5. Medical Tests and Results</h3>
      <Form.Item
        label="Medical Test Results"
        name="testResults"
        rules={[{ required: true, message: 'Please provide the results of medical tests' }]}
      >
        <TextArea rows={4} />
      </Form.Item>

      {/* Prognosis and Recovery */}
      <h3>6. Prognosis and Recovery</h3>
      <Form.Item
        label="Prognosis"
        name="prognosis"
        rules={[{ required: true, message: 'Please provide the prognosis from the healthcare provider' }]}
      >
        <Input />
      </Form.Item>

      {/* Cost and Expenses */}
      <h3>7. Cost and Expenses</h3>
      <Form.Item
        label="Medical Expenses"
        name="medicalExpenses"
        rules={[{ required: true, message: 'Please provide the details of medical expenses incurred' }]}
      >
        <Input />
      </Form.Item>

      {/* Physician's Notes and Recommendations */}
      <h3>8. Physician's Notes and Recommendations</h3>
      <Form.Item
        label="Physician's Notes"
        name="physicianNotes"
        rules={[{ required: true, message: 'Please provide the physician\'s notes and recommendations' }]}
      >
        <TextArea rows={4} />
      </Form.Item>

      {/* Submit Button */}
      <Form.Item wrapperCol={{ offset: 6, span: 12 }}>
        <Button type="primary" htmlType="submit">
          Submit Medical Report
        </Button>
      </Form.Item>
    </Form>
  );
};

export default MedicalReportForm;
