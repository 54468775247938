import React, { useState } from "react";
import { Modal, Form, Input, Button, InputNumber } from "antd";
import { addCustomer } from "../helpers/authAPI";
import { useDispatch, useSelector } from "react-redux";
import { message } from "antd";
import { fetchCustomersList } from "../store/reducers/authReducer";

const CustomerFormModal = ({ visible, setVisible }) => {
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const [messageApi, contextHolder] = message.useMessage();
  const dispatch = useDispatch();
  const agentAuthInfo = useSelector((state) => state?.auth);
  const groupId = useSelector((state) => {
    const groupsList = state?.team?.groupsList;
    const staffGroup = groupsList?.find(group => group?.name === 'staff');
    return staffGroup?.id; // Assuming each group has an 'id' property
  });

  const handleOk = async () => {
    try {
      setLoading(true);
      const formData = await form.validateFields();

      formData["user_id"] = agentAuthInfo?.auth_profile?.user_id;
      formData["token"] = agentAuthInfo?.token;
      formData["groupId"] = groupId

      addCustomer(formData)
        .then((response) => {
          if (response.data.success) {
            messageApi.open({
              type: 'success',
              content: response?.data?.message,
              duration: 5,
            });
            dispatch(fetchCustomersList(formData));
          } else {
            messageApi.open({
              type: 'error',
              content: response?.data?.message,
              duration: 5,
            });
          }
        })
        .catch((err) => {
          messageApi.open({
            type: "error",
            content: "Unable to add customer. Please try again",
            duration: 5,
          });
        });

      form.resetFields();
      setLoading(false);
      setVisible(false);
    } catch (error) {
      console.error("Error saving customer information:", error);
      setLoading(false);
    }
  };

  const handleCancel = () => {
    setVisible(false);
  };

  return (
    <>
      {contextHolder}

      <Modal
        title="Customer Information"
        visible={visible}
        onOk={handleOk}
        onCancel={handleCancel}
        footer={[
          <Button key="cancel" onClick={handleCancel}>
            Cancel
          </Button>,
          <Button
            key="submit"
            type="primary"
            loading={loading}
            onClick={handleOk}
          >
            Save
          </Button>,
        ]}
      >
        <Form form={form} layout="vertical">
          <Form.Item
            name="firstName"
            label="First Name"
            rules={[
              { required: true, message: "Please enter your first name" },
              {
                pattern: /^[A-Za-z\s.-]*$/,
                message:
                  "Please enter a valid First Name (no special characters)",
              },
            ]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            name="lastName"
            label="Last Name"
            rules={[
              { required: true, message: "Please enter your last name" },
              {
                pattern: /^[A-Za-z\s.-]*$/,
                message:
                  "Please enter a valid Last Name (no special characters)",
              },
            ]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            name="email"
            label="Email"
            rules={[
              {
                required: true,
                type: "email",
                message: "Please enter a valid email",
              },
            ]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            name="phoneNumber"
            label="Phone Number"
            rules={[
              { required: true, message: "Please enter your phone number" },
              {
                pattern: /^[0-9]{10}$/,
                message: "Phone Number should contain only 10 digits",
              },
            ]}
          >
            <InputNumber
              style={{ width: "100%" }}
              minLength={10}
              maxLength={10}
            />
          </Form.Item>
          <Form.Item
            label="Location"
            name="location"
            rules={[
              {
                required: true,
                message: "Please enter Location",
              },
            ]}
          >
            <Input type="text" placeholder="Enter Location" />
          </Form.Item>
        </Form>
      </Modal>
    </>
  );
};

export default CustomerFormModal;
