import React, { useState } from "react";

import { HomeOutlined, LogoutOutlined, UserOutlined } from "@ant-design/icons";
import { Button, Menu, Modal } from "antd";
import { useDispatch } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { auth } from "../config/firebaseConfig";
import { USER_LOGOUT } from "../store/actions/authActions";
import {
  logOutRecruiterProfile,
  resetState,
} from "../store/reducers/authReducer";
import LoadingSpinner from "./Loader";

const MenuBar = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);

  function getItem(label, key, icon, children, type) {
    return {
      key,
      icon,
      children,
      label,
      type,
    };
  }
  const items = [
    getItem(
      <Link to="/dashboard">Dashboard</Link>,
      "Dashboard",
      <HomeOutlined />
    ),
    getItem(
      <Link to="/team-members">My Team</Link>,
      "MyTeam",
      <UserOutlined />
    ),
    getItem(
      <Link onClick={() => setIsModalOpen(true)}>Sign out</Link>,
      "Logout",
      <LogoutOutlined />
    ),
  ];

  const handleLogOut = () => {
    setLoading(true);
    auth
      .signOut()
      .then(() => {
        dispatch(logOutRecruiterProfile()).then((response) => {
          if (response?.payload === true) {
            setLoading(false);
            dispatch(resetState());
            dispatch({ type: USER_LOGOUT });
            navigate({ pathname: "/" });
          } else {
            setLoading(false);
          }
        });
      })
      .catch((error) => {
        setLoading(false);
      });
  };

  const handleOk = () => {
    setIsModalOpen(false);
  };
  const handleCancel = () => {
    setIsModalOpen(false);
  };

  return (
    <Menu mode="inline" style={{ height: "100%" }}>
      <LoadingSpinner loading={loading} />

      <Modal
        title="Confirm"
        open={isModalOpen}
        onOk={handleOk}
        onCancel={handleCancel}
        footer={[
          <Button
            onClick={handleLogOut}
            style={{ marginRight: 8 }}
            type="primary"
          >
            Sign out
          </Button>,
          <Button onClick={handleCancel}>Cancel</Button>,
        ]}
      >
        <p>Are you sure you want to log out?</p>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "end",
          }}
        ></div>
      </Modal>

      <Menu
        defaultSelectedKeys={["1"]}
        mode="inline"
        theme="dark"
        items={items}
      />
    </Menu>
  );
};

export default MenuBar;
