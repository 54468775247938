import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import {
  addCustomerProductProposalAPIHelper,
  fetchCustomerProductProposalListAPIHelper,
  fetchCustomerQuoteListAPIHelper,
  fetchCustomers,
  fetchProposalAPIHelper,
  LoginAPIHelper,
  SignupAPIHelper,
  updateQuoteProposalAPIHelper,
  CreateQuoteAndDeclarationAPIHelper,
  fetchCustomerDeclarationListAPIHelper,
  fetchQuoteAPIHelper,
} from "../../helpers/authAPI";

const initialState = {
  firebaseResponse: "",
  firebaseToken: "",
  is_authenticated: false,
  auth_profile: {
    user_id:"",
    company: "",
    firstName: "",
    lastName:"",
    email: "",
    phone: "",
    company_website: "",
    organisation_id:"",
    city: "",
    address: "",
  },
  customerProposals: [],
  customerQuotes:[],
  customerDeclarationDocs: [],
  customerProductProposals:[],
  value: 0,
  errors: null,
  token: "",
  refreshToken: "",
  isNewUser: null,
  customers: [],
  activeCustomer : {},
  activeQuote : {},
};

export const logInUser = createAsyncThunk(
  "auth/logInUser",
  async (data, thunkAPI) => {
    try {
      const response = await LoginAPIHelper(data);
      return response;
    } catch (err) {
      return thunkAPI.rejectWithValue(err.message);
    }
  }
);

export const onBoardingUser = createAsyncThunk(
  "auth/onBoardingUser",
  async (data, thunkAPI) => {
    try {
      const response = await SignupAPIHelper(data);
      return response;
    } catch (err) {
      return thunkAPI.rejectWithValue(err.message);
    }
  }
);

export const createQuoteAndDeclaration = createAsyncThunk(
  "auth/createQuoteAndDeclaration",
  async (data, thunkAPI) => {
    try {
      const response = await CreateQuoteAndDeclarationAPIHelper(data);
      return response;
    } catch (err) {
      return thunkAPI.rejectWithValue(err.message);
    }
  }
);

export const updateCustomerQuoteProposal = createAsyncThunk(
  "auth/updateCustomerQuoteProposal",
  async (data, thunkAPI) => {
    try {
      const response = await updateQuoteProposalAPIHelper(data);
      return response;
    } catch (err) {
      return thunkAPI.rejectWithValue(err.message);
    }
  }
);

// For Single Customer Quotes List
export const fetchCustomerQuoteList = createAsyncThunk(
  "auth/fetchCustomerQuoteList",
  async (data, thunkAPI) => {
    try {
      const response = await fetchCustomerQuoteListAPIHelper(data);
      return response;
    } catch (err) {
      return thunkAPI.rejectWithValue(err.message);
    }
  }
);

// For Single Customer Single Quote 
export const fetchCustomerSingleQuote = createAsyncThunk(
  "auth/fetchCustomerSingleQuote",
  async (data, thunkAPI) => {
    try {
      const response = await fetchQuoteAPIHelper(data);
      return response;
    } catch (err) {
      return thunkAPI.rejectWithValue(err.message);
    }
  }
);

// ALL proposals 
export const fetchAllProposal = createAsyncThunk(
  "auth/fetchAllProposal",
  async (data, thunkAPI) => {
    try {
      const response = await fetchProposalAPIHelper(data);
      return response;
    } catch (err) {
      return thunkAPI.rejectWithValue(err.message);
    }
  }
);

export const logOutRecruiterProfile = createAsyncThunk(
  "auth/logOutRecruiterProfile",
  async (arg, thunkAPI) => {
    return true;
  }
);

export const resetAuthState = createAsyncThunk(
  "auth/resetAuthState",
  async () => {}
);

// customers

export const fetchCustomersList = createAsyncThunk(
  "auth/fetchCustomersList",
  async (data, thunkAPI) => {
    try {
      const response = await fetchCustomers(data);
      return response;
    } catch (err) {
      return thunkAPI.rejectWithValue(err.message);
    }
  }
);

export const fetchCustomerDeclarationList = createAsyncThunk(
  "auth/fetchCustomerDeclarationList",
  async (data, thunkAPI) => {
    try {
      const response = await fetchCustomerDeclarationListAPIHelper(data);
      return response;
    } catch (err) {
      return thunkAPI.rejectWithValue(err.message);
    }
  }
);

// end customers

// policy

export const addCustomerProductProposal = createAsyncThunk(
  "auth/addCustomerProductProposal",
  async (data, thunkAPI) => {
    try {
      const response = await addCustomerProductProposalAPIHelper(data);
      return response;
    } catch (err) {
      return thunkAPI.rejectWithValue(err.message);
    }
  }
);

// all policies
export const fetchCustomerProductProposalList = createAsyncThunk(
  "auth/fetchCustomerProductProposalList",
  async (data, thunkAPI) => {
    try {
      const response = await fetchCustomerProductProposalListAPIHelper(data);
      return response;
    } catch (err) {
      return thunkAPI.rejectWithValue(err.message);
    }
  }
);

export const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    updateAuthStatus: (state, action) => {
      state.is_authenticated = true;
    },
    updateAuthProfile: (state, action) => {
      state.auth_profile = action?.payload;
    },
    resetState: (state, action) => {
      state.token = "";
      state = [];
    },
    saveFirebaseIdToken: (state, action) => {
      state.auth_profile.email = action?.payload;
      state.is_authenticated = true;
    },
    resetAuthState: (state) => {
      state.token = "";
    },
    isNewUser: (state, action) => {
      state.isNewUser = action?.payload;
    },
    setActiveCustomer: (state, action) => {
      state.activeCustomer = action?.payload;
    },
    setActiveQuote: (state, action) => {
      state.activeQuote = action?.payload;
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(logInUser.fulfilled, (state, action) => {
        if(action?.payload?.data?.success === true){
          state.is_authenticated = action?.payload?.data?.success;
          state.auth_profile.email = action?.payload?.data?.user?.email;
          state.auth_profile.firstName = action?.payload?.data?.user?.firstName;
          state.auth_profile.lastName = action?.payload?.data?.user?.lastName;
          state.auth_profile.organisation_id = action?.payload?.data?.user?.organizationId;
          state.auth_profile.user_id = action?.payload?.data?.user?.id;
          state.token = action?.payload?.data?.token
        }
        else{
          state.is_authenticated = action?.payload?.data?.success;
        }
      })
      .addCase(logInUser.rejected, (state, action) => {
        state.auth_profile = {};
        state.is_authenticated = false;
      })
      .addCase(onBoardingUser.fulfilled, (state, action) => {
        if(action?.payload?.data?.success === true){
          state.is_authenticated = action?.payload?.data?.success;
          state.auth_profile.email = action?.payload?.data?.user.email;
          state.auth_profile.firstName = action?.payload?.data?.user?.firstName;
          state.auth_profile.lastName = action?.payload?.data?.user?.lastName;
          state.auth_profile.organisation_id = action?.payload?.data?.user?.organizationId;
          state.auth_profile.user_id = action?.payload?.data?.user?.id;
          state.token = action?.payload?.data?.token
        }
        else{
          state.is_authenticated = action?.payload?.data?.success;
        }
      })
      .addCase(onBoardingUser.rejected, (state, action) => {
        state.is_authenticated = false;
      })
      .addCase(createQuoteAndDeclaration.fulfilled, (state, action) => {
        state.activeQuote = action?.payload?.data?.quoteData;
        // console.log(action,'createQuoteAndDeclaration.fullfilled')
      })
      .addCase(createQuoteAndDeclaration.rejected, (state, action) => {
        // console.log(action,'createQuoteAndDeclaration.rejected')
      })
      .addCase(updateCustomerQuoteProposal.fulfilled, (state, action) => {
        // console.log(action,'updateCustomerQuoteProposal.fullfilled')
      })
      .addCase(updateCustomerQuoteProposal.rejected, (state, action) => {
        // console.log(action,'updateCustomerQuoteProposal.rejected')
      })
      .addCase(fetchCustomerQuoteList.fulfilled, (state, action) => {
        state.customerQuotes = action?.payload?.data?.customerQuotes;
      })
      .addCase(fetchCustomerQuoteList.rejected, (state, action) => {
        // console.log(action,'fetchCustomerQuoteList.rejected')
      })
      .addCase(fetchAllProposal.fulfilled, (state, action) => {
        state.customerProposals = action?.payload?.customerProposals;
      })
      .addCase(fetchAllProposal.rejected, (state, action) => {
      })
      .addCase(logOutRecruiterProfile.fulfilled, (state, action) => {
        state.is_authenticated = false;
        // localStorage.removeItem('sessionId');
        // localStorage.clear();
      })
      .addCase(resetAuthState.fulfilled, (state) => {
        state.token = "";
      })
      .addCase(fetchCustomersList.fulfilled, (state, action) => {
        state.customers = action?.payload?.data?.customers;
      })
      .addCase(fetchCustomersList.rejected, (state, action) => {
        state.customers =[];
      })
      .addCase(addCustomerProductProposal.fulfilled, (state, action) => {
        // state.customers = action?.payload?.customers;
      })
      .addCase(addCustomerProductProposal.rejected, (state, action) => {
        // state.customers =[];
      })
      .addCase(fetchCustomerProductProposalList.fulfilled, (state, action) => {
        state.customerProductProposals = action?.payload?.customerProductProposals;
      })
      .addCase(fetchCustomerProductProposalList.rejected, (state, action) => {
        state.customerProductProposals = [];
      })
      .addCase(fetchCustomerDeclarationList.fulfilled, (state, action) => {
        state.customerDeclarationDocs = action?.payload?.customerDeclarationDocs;
      })
      .addCase(fetchCustomerDeclarationList.rejected, (state, action) => {
        state.customerDeclarationDocs = [];
      })
      .addCase(fetchCustomerSingleQuote.fulfilled, (state, action) => {
        state.activeQuote = action?.payload?.data?.quoteData;
      })
      .addCase(fetchCustomerSingleQuote.rejected, (state, action) => {
        // state.activeQuote = [];
      });
  },
});

// Action creators are generated for each case reducer function
export const {
  updateAuthStatus,
  updateAuthProfile,
  incrementByAmount,
  resetState,
  isNewUser,
  saveFirebaseIdToken,
  setActiveCustomer,
  setActiveQuote
} = authSlice.actions;

export default authSlice.reducer;
