import React, { useEffect } from "react";

const ViewQuotePDF = () => {

  return (
    <div
      style={{
        margin: "25px",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        backgroundColor:"#323639"
      }}
    >
        <div style={{ height: "70vh", width:"100%" }}>
          <iframe
            title="PDF Viewer"
            src={"https://kokoro-insurance.s3.ap-south-1.amazonaws.com/SankethaKulal/manas_08-May-2024.pdf"}
            width="100%"
            height="100%"
          />
        </div>
    </div>
  );
};

export default ViewQuotePDF;
