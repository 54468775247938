import React from "react";
import { Card, Flex } from "antd";
import KokoroLogo from "../data/images/KokoroLogo.png";
import { useSelector } from "react-redux";

function AppHeader() {
  const agentInfo = useSelector((state) => state?.auth?.auth_profile);

  return (
    <Card
      style={{ width: "100%" }}
      styles={{ body: { padding: 0, overflow: "hidden" } }}
    >
      {/* TODO: make header more neater , use only logo that is just black. We do not need to have big text */}
      <Flex justify="space-between" alignItems="center">
        <img
          width={"100px"}
          style={{ paddingInlineStart: 10 }}
          alt="KOKORO INSURANCE BROKERS PRIVATE LIMITED"
          src={KokoroLogo}
        />
        <Flex style={{ paddingInlineEnd: 22, paddingTop: 16 }}>
          <div>
            <h4 style={{ margin: 0 }}>{agentInfo?.firstName} {agentInfo?.lastName}</h4>
            <p style={{ margin: 0, fontSize: 12 }}>{agentInfo?.email}</p>
          </div>
        </Flex>
      </Flex>
    </Card>
  );
}

export default AppHeader;
