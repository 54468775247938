import React, { createContext, useContext, useState, useEffect } from 'react';

// Create a new context for session management
const SessionContext = createContext();

// Custom hook to access the session context
export const useSession = () => useContext(SessionContext);

// Session Provider component
export const SessionProvider = ({ children }) => {
  const [sessionId, setSessionId] = useState('');

  // Generate a unique session ID when the component mounts
  useEffect(() => {
    const generateSessionId = () => {
      let id = localStorage.getItem('sessionId');
      if (!id) {
        id = Date.now().toString(36) + Math.random().toString(36).substring(2);
        localStorage.setItem('sessionId', id);
      }
      setSessionId(id);
    };

    generateSessionId();
  }, []);

  return (
    <SessionContext.Provider value={{ sessionId }}>
      {children}
    </SessionContext.Provider>
  );
};
