/**
 * 
 * 
 * Witness statements play a crucial role in the claim process, especially in insurance claims involving accidents, property damage, or other incidents where third-party accounts are necessary to corroborate details. Witness statements are written accounts provided by individuals who witnessed the incident and can provide relevant information or testimony regarding what transpired. Here is what is generally collected in witness statements:

1. **Witness Identification**:
   - Full name of the witness.
   - Contact information (phone number, email address).

2. **Witness Relationship to the Incident**:
   - Description of how the witness was present or involved in the incident (e.g., bystander, passenger in a vehicle, neighbor).

3. **Incident Details**:
   - Description of what the witness observed, including the sequence of events leading up to the incident and what happened afterward.
   - Specifics about the location, time, and date of the incident.

4. **Physical Descriptions**:
   - Details about any vehicles, individuals, or property involved in the incident (e.g., make and model of vehicles, distinguishing features of individuals).

5. **Conditions at the Time**:
   - Information about weather conditions, road conditions, visibility, or any other environmental factors that may have influenced the incident.

6. **Actions of Parties Involved**:
   - Description of actions taken by individuals involved in the incident (e.g., driving behavior, reactions to the situation).

7. **Sequence of Events**:
   - Chronological order of events as witnessed by the individual, including what occurred before, during, and after the incident.

8. **Injuries or Damage Observed**:
   - Details about any injuries sustained by individuals or damage to property witnessed by the individual.

9. **Additional Comments or Observations**:
   - Any other relevant information or observations made by the witness that could contribute to understanding the incident.

Witness statements are important because they provide additional perspectives and evidence to support or challenge claims made by parties involved in the incident. They can help insurance adjusters, investigators, or legal professionals assess liability, determine the cause of the incident, and verify the accuracy of reported events.

When collecting witness statements, it's essential to gather detailed and accurate information while ensuring the witnesses understand the importance of their statements. Witness statements are typically signed and dated by the witnesses to attest to the accuracy of the provided information.
 * */

import React from 'react';
import { Form, Input, DatePicker, Button } from 'antd';

const { TextArea } = Input;

const WitnessStatementForm = () => {
  const onFinish = (values) => {
    console.log('Form submitted:', values);
    // Handle form submission logic (e.g., API request to submit witness statement)
  };

  return (
    <Form
      name="witness-statement-form"
      onFinish={onFinish}
      labelCol={{ span: 6 }}
      wrapperCol={{ span: 12 }}
      initialValues={{ remember: true }}
    >
      {/* Witness Identification */}
      <Form.Item
        label="Full Name"
        name="fullName"
        rules={[{ required: true, message: 'Please enter your full name' }]}
      >
        <Input />
      </Form.Item>

      <Form.Item
        label="Contact Number"
        name="contactNumber"
        rules={[{ required: true, message: 'Please enter your contact number' }]}
      >
        <Input />
      </Form.Item>

      {/* Witness Relationship to Incident */}
      <Form.Item
        label="Relationship to Incident"
        name="relationship"
        rules={[{ required: true, message: 'Please describe your relationship to the incident' }]}
      >
        <Input />
      </Form.Item>

      {/* Incident Details */}
      <Form.Item
        label="Date of Incident"
        name="dateOfIncident"
        rules={[{ required: true, message: 'Please select the date of the incident' }]}
      >
        <DatePicker />
      </Form.Item>

      <Form.Item
        label="Description of Incident"
        name="incidentDescription"
        rules={[{ required: true, message: 'Please describe what you observed during the incident' }]}
      >
        <TextArea rows={4} />
      </Form.Item>

      {/* Conditions at the Time */}
      <Form.Item
        label="Conditions at the Time"
        name="conditionsAtTime"
        rules={[{ required: true, message: 'Please describe the conditions at the time of the incident' }]}
      >
        <TextArea rows={4} />
      </Form.Item>

      {/* Additional Details */}
      <Form.Item
        label="Additional Comments or Observations"
        name="additionalComments"
      >
        <TextArea rows={4} />
      </Form.Item>

      {/* Submit Button */}
      <Form.Item wrapperCol={{ offset: 6, span: 12 }}>
        <Button type="primary" htmlType="submit">
          Submit Witness Statement
        </Button>
      </Form.Item>
    </Form>
  );
};

export default WitnessStatementForm;
